import {
  isError,
  postSyncStorages,
  SyncStorageRequestType,
  URL_DOCS_GCS_EN,
  URL_DOCS_GCS_KO,
} from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import { Button, InputField, TextArea, Toast } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import i18next from "i18next"
import React, { useState } from "react"
import { Trans, useTranslation } from "react-i18next"

import SettingsHeader from "../SettingsHeader"
import { Cloud } from "./Connections"
import styles from "./Connections.module.scss"

type Props = {
  className?: string
  cloud: Cloud
  onBackButtonClick: React.MouseEventHandler<HTMLElement>
  onConnectButtonClick: React.MouseEventHandler<HTMLElement>
}

function RegisterConnection(props: Props) {
  const [privateKey, setPrivateKey] = useState<string>("")
  const [bucketName, setBucketName] = useState<string>("")
  const [isConnecting, setIsConnecting] = useState<boolean>(false)
  const { t } = useTranslation()

  const onConnectClick: React.MouseEventHandler<HTMLElement> = (e) => {
    if (!privateKey || !bucketName) {
      Toast({ message: t("toast.setting.connection.error"), type: "error" })
      captureException("PrivateKey or bucketName is invalid.", (scope) => {
        scope.setContext("info", { privateKey, bucketName })
        return scope
      })
      setIsConnecting(false)
      return
    }
    setIsConnecting(true)

    const requestSyncStorages = async () => {
      const data: SyncStorageRequestType = {
        credentials: privateKey,
        bucketName: bucketName,
        cloudType: props.cloud.type,
      }
      try {
        const _res = await postSyncStorages(data)
        if (!isError(_res) && _res === 200) Toast({ message: t("toast.setting.connection.success"), type: "success" })
      } catch (e) {
        Toast({ message: t("toast.setting.connection.error"), type: "error" })
        captureException(e)
      } finally {
        setIsConnecting(false)
      }
      props.onConnectButtonClick(e)
    }
    void requestSyncStorages()
  }

  return (
    <div className={cn(props.className)}>
      <SettingsHeader title={props.cloud.name} backButton={true} onBackButtonClick={props.onBackButtonClick} />
      <div className={styles.content}>
        <span className={styles.subTitle}>{t("modal.setting.connections.registerInformation.title")}</span>
        <p className={styles.desc}>
          <Trans i18nKey={"modal.setting.connections.registerInformation.desc"}>
            Please check the
            <a
              href={i18next.language === "ko" ? URL_DOCS_GCS_KO : URL_DOCS_GCS_EN}
              rel={"noopener noreferrer"}
              target={"_blank"}
            >
              guide
            </a>
            on how to connect {{ cloud: props.cloud.name }}.
          </Trans>
        </p>
        <div className={styles.formContainer}>
          <div className={styles.form}>
            <TextArea
              labelClassName={styles.inputLabel}
              textAreaClassName={styles.privateKeyTextArea}
              label={t("textInput.privateKey") ?? ""}
              placeholder={t("textInput.placeholder", { target: t("textInput.privateKey") }) ?? ""}
              resizer={true}
              value={privateKey}
              onChange={(value) => setPrivateKey(value)}
            />
            <InputField
              labelClassName={styles.inputLabel}
              label={t("textInput.bucketName") ?? ""}
              placeholder={t("textInput.placeholder", { target: t("textInput.bucketName") }) ?? ""}
              value={bucketName}
              onChange={(e) => setBucketName(e.target.value)}
              onClearButtonClick={() => setBucketName("")}
            />
          </div>
          <Button className={styles.connectBtn} loading={isConnecting} onClick={onConnectClick}>
            {t("button.connect")}
          </Button>
        </div>
      </div>
    </div>
  )
}
export default RegisterConnection
