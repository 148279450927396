import { generateOlMap, generateOsmLayer } from "@ovision-gis-frontend/shared"
import cn from "classnames"
import { Map as OlMap, View } from "ol"
import { defaults as defaultControls, ScaleLine } from "ol/control"
import React, { useEffect, useRef, useState } from "react"

import styles from "./AnalysisMap.module.scss"

type Props = {
  view: View
}

function AnalysisMap(props: Props) {
  const [loadingMap, setLoadingMap] = useState<boolean>(false)

  const mapContainerRef = useRef<HTMLDivElement>(null)
  const mapRef = useRef<OlMap | null>(null)
  const scaleLineRef = useRef<HTMLDivElement>(null)
  const raster = generateOsmLayer()

  useEffect(() => {
    if (mapRef.current) return
    if (!mapContainerRef.current || !mapRef || !scaleLineRef.current) return

    const scaleLine = new ScaleLine({
      className: styles.olScaleLine,
      target: scaleLineRef.current,
    })

    mapRef.current = generateOlMap(
      [raster],
      mapContainerRef.current,
      props.view,
      defaultControls({ rotate: false }).extend([scaleLine]),
    )
    mapRef.current?.on("loadstart", () => setLoadingMap(true))
    mapRef.current?.on("loadend", () => setLoadingMap(false))

    return () => {
      scaleLine.setMap(null)
      mapRef.current?.getAllLayers().map((_layer) => mapRef.current?.removeLayer(_layer))
      mapRef.current?.un("loadstart", () => setLoadingMap(true))
      mapRef.current?.un("loadend", () => setLoadingMap(false))
      mapRef.current?.setTarget(undefined)
      mapRef.current = null
    }
  }, [mapContainerRef.current])

  return (
    <div className={styles.analysisMap}>
      <div className={cn(styles.mapContainer)} ref={mapContainerRef}>
        <progress className={cn("tile-load-progress", loadingMap && "tile-loading")} max={"100"} value={"100"} />
        <div className={styles.customScaleLine}>
          <span>©Open Street Map</span>
          <div ref={scaleLineRef} />
        </div>
      </div>
    </div>
  )
}
export default AnalysisMap
