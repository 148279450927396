import CoinImg from "@ovision-gis-frontend/shared/src/asset/icons/solid/Coin.png"
import PartyPopperImg from "@ovision-gis-frontend/shared/src/asset/icons/solid/PartyPopper.png"
import { Button } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React from "react"
import { Trans, useTranslation } from "react-i18next"

import { NewPlanContent } from "./Pricing"
import styles from "./VerticalPlan.module.scss"

type Props = {
  className?: string
  content: NewPlanContent
  isLoading?: boolean
  isSettingsMenu?: boolean
  onPlanButtonClick?: (e: React.MouseEvent<HTMLElement>, planId: string) => void
}
function VerticalPlan(props: Props) {
  const { t } = useTranslation()

  return (
    <section
      className={cn(
        styles.verticalPlan,
        props.content.isWelcomePlan && styles.welcomePlan,
        props.isSettingsMenu && styles.isSettingsMenu,
        props.className,
      )}
    >
      <div className={styles.highlight} />
      <div className={styles.content}>
        {props.content.isWelcomePlan && <img className={styles.partyPopper} alt={"partyPopper"} src={PartyPopperImg} />}
        <div className={styles.creditContainer}>
          <img alt={"coin"} src={CoinImg} />
          <span className={styles.amount}>{props.content.credit}</span>
          <span className={styles.label}>{t("pricing.plan.credit.label")}</span>
        </div>
        <p className={styles.desc}>
          <Trans i18nKey={props.content.desc}>
            Available analysis area <strong>100km²</strong>
          </Trans>
        </p>
        <div className={styles.priceContainer}>
          {props.content.chip}
          <Button
            size={props.content.isWelcomePlan ? "large" : "xl"}
            type={props.content.isWelcomePlan ? "outlined" : "primary"}
            loading={props.isLoading}
            onClick={(e) => props.onPlanButtonClick?.(e, props.content.planId)}
          >
            <>
              {props.content.isWelcomePlan && <div className={styles.saleSlash} />}
              {props.content.price}
            </>
          </Button>
        </div>
      </div>
    </section>
  )
}

export default VerticalPlan
