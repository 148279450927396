import { PATH_SIGN_IN, PATH_ANALYSIS, useToken } from "@ovision-gis-frontend/shared"
import BriefcaseImg from "@ovision-gis-frontend/shared/src/asset/icons/solid/Briefcase.png"
import LightBulbImg from "@ovision-gis-frontend/shared/src/asset/icons/solid/LightBulb.png"
import MagnifyingGlassImg from "@ovision-gis-frontend/shared/src/asset/icons/solid/MagnifyingGlass.png"
import RoundPushpinImg from "@ovision-gis-frontend/shared/src/asset/icons/solid/RoundPushpin.png"
import SatelliteImg from "@ovision-gis-frontend/shared/src/asset/icons/solid/Satellite.png"
import BannerImg from "@ovision-gis-frontend/shared/src/asset/images/Banner.png"
import { Button } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import styles from "./Banner.module.scss"

type Props = {
  className?: string
}

export default function Banner(props: Props) {
  const { t } = useTranslation()
  const { token } = useToken()
  const navigate = useNavigate()

  return (
    <div className={cn(styles.banner, props.className)}>
      <img className={styles.bannerBgImg} src={BannerImg} />
      <span className={styles.title}>{t("bannerTitle")}</span>
      <span className={styles.desc}>{!token ? t("bannerDesc") : t("bannerDescAfterSignin")}</span>
      <Button
        className={styles.bannerButton}
        size={"xl"}
        type={"outlined"}
        onClick={() => navigate(!token ? PATH_SIGN_IN : PATH_ANALYSIS)}
      >
        {t("getStartedNow")}
      </Button>
      <img className={styles.lightBulbImg} src={LightBulbImg} />
      <img className={styles.briefCaseImg} src={BriefcaseImg} />
      <img className={styles.satelliteImg} src={SatelliteImg} />
      <img className={styles.roundPushpinImg} src={RoundPushpinImg} />
      <img className={styles.magnifyingGlassImg} src={MagnifyingGlassImg} />
      <div className={styles.yellowCircle} />
    </div>
  )
}
