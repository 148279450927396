import { useEffect, useRef } from "react"

export function useElementVisible<T extends HTMLElement>(subAction: () => void) {
  const ref = useRef<T>(null)
  const handleClickOutside = (evt: Event) => {
    if (ref.current && !ref.current.contains(evt.target as Node)) subAction()
  }
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true)
    }
  })

  return ref
}
