import { DOMAIN_NAME } from "@ovision-gis-frontend/shared"
import React from "react"
import { Trans, useTranslation } from "react-i18next"

import styles from "./LegalContentsEnglish.module.scss"

function TermsAndConditionsEnglish() {
  const { t } = useTranslation()

  return (
    <section className={styles.legal}>
      <p className={styles.label}>{t("legal.title")}</p>
      <p className={styles.title}>{t("termsAndConditions.title")}</p>

      <dl>
        <dd>
          <Trans i18nKey={"termsAndConditions.desc"}>
            desc...
            <a href={"https://" + DOMAIN_NAME} rel={"noopener noreferrer"} target={"_blank"}>
              domain...
            </a>
            desc... <strong>You</strong> desc... <strong>Your</strong>
            desc...
            {{ domain: DOMAIN_NAME }}
          </Trans>
        </dd>
        <div className={styles.content}>
          <Trans i18nKey={"termsAndConditions.first.content"}>
            <dt>1. DEFINITIONS</dt>
            <dd>
              <strong>1.1.</strong> desc...
            </dd>
            <dd>
              <strong>1.2.</strong> desc...
            </dd>
            <dd>
              <strong>1.3.</strong> desc...
            </dd>
            <dd>
              <strong>1.4.</strong> desc...
            </dd>
            <dd>
              <strong>1.5.</strong> desc...
            </dd>
            <dd>
              <strong>1.6.</strong> desc...
            </dd>
            <dd>
              <strong>1.7.</strong> desc...
            </dd>
            <dd>
              <strong>1.8.</strong> desc...
            </dd>
            <dd>
              <strong>1.9.</strong> desc...
            </dd>
            <dd>
              <strong>1.10.</strong> desc...
            </dd>
            <dd>
              <strong>1.11.</strong> desc...
            </dd>
            <dd>
              <strong>1.12.</strong> desc...
            </dd>
            <dd>
              <strong>1.13.</strong> desc...
            </dd>
            <dd>
              <strong>1.14.</strong> desc...
            </dd>
            <dd>
              <strong>1.15.</strong> desc...
            </dd>
            <dd>
              <strong>1.16.</strong> desc...
            </dd>
            <dd>
              <strong>1.17.</strong> desc...
            </dd>
            <dd>
              <strong>1.18.</strong> desc...
            </dd>
            <dd>
              <strong>1.19.</strong> desc...
            </dd>
            <dd>
              <strong>1.20.</strong> desc... <span>OVISISON</span>, <span>OVISION-EARTH</span>, desc...
            </dd>
            <dd>
              <strong>1.21.</strong> desc...
            </dd>
            <dd>
              <strong>1.22.</strong> desc...
            </dd>
            <dd>
              <strong>1.23.</strong> desc...
            </dd>
            <dd>
              <strong>1.24.</strong> desc...
            </dd>
            <dd>
              <strong>1.25.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"termsAndConditions.second.content"}>
            <dt>2. ACCEPTANCE OF TERMS AND CONDITIONS</dt>
            <dd>
              <strong>2.1.</strong> desc...
            </dd>
            <dd>
              <strong>2.2.</strong> desc...
            </dd>
            <dd>
              <strong>2.3.</strong> desc...
            </dd>
            <dd>
              <strong>2.4.</strong> desc...
            </dd>
            <dd>
              <strong>2.5.</strong> desc...
            </dd>
            <dd>
              <strong>2.6.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"termsAndConditions.third.content"}>
            <dt>3. REGISTRATION</dt>
            <dd>
              <strong>3.1.</strong> desc...
            </dd>
            <dd>
              <strong>3.2.</strong> desc...
            </dd>
            <dd>
              <strong>3.3.</strong> desc...
            </dd>
            <dd>
              <strong>3.4.</strong> desc...
            </dd>
            <dd>
              <strong>3.5.</strong> desc...
            </dd>
            <dd>
              <strong>3.6.</strong> desc...
            </dd>
            <dd>
              <strong>3.7.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"termsAndConditions.fourth.content"}>
            <dt>4. THE SERVICES</dt>
            <dd>
              <strong>4.1.</strong> desc...
            </dd>
            <dd>
              <strong>4.2.</strong> desc...
            </dd>
            <dd>
              <strong>4.3.</strong> desc...
            </dd>
            <dd>
              <strong>4.4.</strong> desc...
            </dd>
            <dd>
              <strong>4.5.</strong> desc...
            </dd>
            <dd>
              <strong>4.6.</strong> desc...
            </dd>
            <dd>
              <strong>4.7.</strong> desc...
            </dd>
            <dd>
              <strong>4.8.</strong> desc...
            </dd>
            <dd>
              <strong>4.9.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"termsAndConditions.fifth.content"}>
            <dt>5. USER(S) RESPONSIBILITIES</dt>
            <dd>
              <strong>5.1.</strong> desc...
            </dd>
            <dd>
              <strong>5.2.</strong> desc...
            </dd>
            <dd>
              <strong>5.3.</strong> desc...
            </dd>
            <dd>
              <strong>5.4.</strong> desc...
            </dd>
            <dd>
              <strong>5.5.</strong> desc...
            </dd>
            <dd>
              <strong>5.6.</strong> desc...
            </dd>
            <dd>
              <strong>5.7.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"termsAndConditions.sixth.content"}>
            <dt>6. THIRD-PARTY CONTENT</dt>
            <dd>
              <strong>6.1.</strong> desc...
            </dd>
            <dd>
              <strong>6.2.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"termsAndConditions.seventh.content"}>
            <dt>7. CREDITS</dt>
            <dd>
              <strong>7.1.</strong> desc...
            </dd>
            <dd>
              <strong>7.2.</strong> desc...
            </dd>
            <dd>
              <strong>7.3.</strong> desc...
            </dd>
            <dd>
              <strong>7.4.</strong> desc...
            </dd>
            <dd>
              <strong>7.5.</strong> desc...
            </dd>
            <dd>
              <strong>7.6.</strong> desc...
            </dd>
            <dd>
              <strong>7.7.</strong> desc...
            </dd>
            <dd>
              <strong>7.8.</strong> desc...
            </dd>
            <dd>
              <strong>7.9.</strong> desc...
            </dd>
            <dd>
              <strong>7.10.</strong> desc...
            </dd>
            <dd>
              <strong>7.11.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"termsAndConditions.eighth.content"}>
            <dt>8. TEMPORARY SUSPENSION</dt>
            <dd>
              <strong>8.1.</strong> desc...
            </dd>
            <dd>
              <strong>8.2.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"termsAndConditions.ninth.content"}>
            <dt>9. TERM AND TERMINATION</dt>
            <dd>
              <strong>9.1.</strong> desc...
            </dd>
            <dd>
              <strong>9.2.</strong> desc...
            </dd>
            <dd>
              <strong>9.3.</strong> desc...
            </dd>
            <dd>
              <strong>9.4.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"termsAndConditions.tenth.content"}>
            <dt>10. USE RESTRICTIONS</dt>
            <dd>
              <strong>10.1.</strong> desc...
            </dd>
            <dd>
              <strong>10.2.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"termsAndConditions.eleventh.content"}>
            <dt>11. INTELLECTUAL PROPERTY RIGHTS AND LICENSE</dt>
            <dd>
              <strong>11.1.</strong> desc...
            </dd>
            <dd>
              <strong>11.2.</strong> desc...
            </dd>
            <dd>
              <strong>11.3.</strong> desc...
            </dd>
            <dd>
              <strong>11.4.</strong> desc...
            </dd>
            <dd>
              <strong>11.5.</strong> desc...
            </dd>
            <dd>
              <strong>11.6.</strong> desc...
            </dd>
            <dd>
              <strong>11.7.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"termsAndConditions.twelfth.content"}>
            <dt>12. INDEMNIFICATION</dt>
            <dd>
              <strong>12.1.</strong> desc...
            </dd>
            <dd>
              <strong>12.2.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"termsAndConditions.thirteenth.content"}>
            <dt>13. WARRANTIES; LIMITATIONS OF LIABILITY</dt>
            <dd>
              <strong>13.1.</strong> desc...
            </dd>
            <dd>
              <strong>13.2.</strong> desc...
            </dd>
            <dd>
              <strong>13.3.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"termsAndConditions.fourteenth.content"}>
            <dt>14. CONFIDENTIALITY</dt>
            <dd>
              <strong>14.1.</strong> desc...
            </dd>
            <dd>
              <strong>14.2.</strong> desc...
            </dd>
            <dd>
              <strong>14.3.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"termsAndConditions.fifteenth.content"}>
            <dt>15. GOVERNING LAW</dt>
            <dd>
              <strong>15.1.</strong> desc...
            </dd>
            <dd>
              <strong>15.2.</strong> desc...
            </dd>
            <dd>
              <strong>15.3.</strong> desc...
            </dd>
            <dd>
              <strong>15.2.</strong> desc...
            </dd>
            <dd>
              <strong>15.3.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"termsAndConditions.sixteenth.content"}>
            <dt>16. NOTICES</dt>
            <dd>
              <strong>16.1.</strong> desc...
            </dd>
            <dd>
              <strong>16.2.</strong> desc... <span>email</span> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"termsAndConditions.seventeenth.firstHalf.content"}>
            <dt>17. MISCELLANEOUS</dt>
            <dd>
              <strong>17.1.</strong> desc...
            </dd>
            <dd>
              <strong>17.2.</strong> desc...
            </dd>
            <dd>
              <strong>17.3.</strong> desc...
            </dd>
            <dd>
              <strong>17.4.</strong> desc...
            </dd>
            <dd>
              <strong>17.5.</strong> desc...
            </dd>
            <dd>
              <strong>17.6.</strong> desc...
            </dd>
          </Trans>
          <Trans i18nKey={"termsAndConditions.seventeenth.secondHalf.content"}>
            <dd>
              <strong>17.7.</strong> desc...
            </dd>
            <dd>
              <strong>17.8.</strong> desc...
            </dd>
            <dd>
              <strong>17.9.</strong> desc...
            </dd>
            <dd>
              <strong>17.10.</strong> desc...
            </dd>
            <dd>
              <strong>17.11.</strong> desc...
            </dd>
            <dd>
              <strong>17.12.</strong> desc...
            </dd>
            <dd>
              <strong>17.13.</strong> desc...
            </dd>
          </Trans>
        </div>
      </dl>
    </section>
  )
}
export default TermsAndConditionsEnglish
