import { Button } from "@SIAnalytics/ovision-design-system"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useRouteError } from "react-router-dom"

import { PATH_BACK, PATH_HOME } from "../../../index"
import fourZeroFourEffectImg from "../../asset/images/404Effect.png"
import fourZeroFourGroupImg from "../../asset/images/404Group.png"
import BlueMoonImg from "../../asset/images/BlueMoon.png"
import BlurredSatelliteImg from "../../asset/images/BlurredSatellite.png"
import ErrorPageRocketImg from "../../asset/images/ErrorPageRocket.png"
import RingPlanetImg from "../../asset/images/RingPlanet.png"
import WhiteQuestionMarkImg from "../../asset/images/WhiteQuestionMark.png"
import styles from "./ErrorPage.module.scss"

function ErrorPage() {
  const error = useRouteError()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const onReturnBtnClick = () => {
    if (window.history.state && window.history.state.idx > 0) navigate(PATH_BACK)
    else navigate(PATH_HOME, { replace: true })
  }

  return (
    <div className={styles.errorPage}>
      <span className={styles.title}>{t("errorPage.title")}</span>
      <div className={styles.imgWrapper}>
        <img className={styles.errorBaseImg} src={fourZeroFourGroupImg} />
        <img className={styles.errorEffectImg} src={fourZeroFourEffectImg} />
      </div>
      <span className={styles.desc}>{t("errorPage.desc")}</span>
      <Button className={styles.backBtn} type={"primary"} size={"xl"} onClick={onReturnBtnClick}>
        {t("button.goBack")}
      </Button>

      <div className={styles.moonRocketWrapper}>
        <img className={styles.blueMoonImg} src={BlueMoonImg} />
        <img className={styles.rocketImg} src={ErrorPageRocketImg} />
      </div>
      {/*<img className={styles.whiteQuestionMarkImg} src={WhiteQuestionMarkImg} />
      <img className={styles.whiteQuestionMarkImg} src={WhiteQuestionMarkImg} />*/}
    </div>
  )
}
export default ErrorPage
