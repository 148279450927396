export type MapBoxGeocodingDto = { type: "FeatureCollection"; query: string[]; features: Feature[] }

export type Feature = {
  type: "Feature"
  text: string
  placeName: string
  center: [number, number]
}

export type SearchPredictionType = {
  placeName: string
  center: number[]
  subText: string
}

export const predictionsFromDto = (dto: MapBoxGeocodingDto): SearchPredictionType[] => {
  return dto.features.map((feature) => {
    return {
      placeName: feature.text,
      center: feature.center,
      subText: feature.placeName,
    }
  })
}
