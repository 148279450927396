import {
  getDateLabel,
  getFormattedGsd,
  getTimeLabel,
  InferenceJobInfo,
  ProjectType,
  getAnalysisStatusLabel,
} from "@ovision-gis-frontend/shared"
import { Chip } from "@SIAnalytics/ovision-design-system"
import React from "react"
import { Trans, useTranslation } from "react-i18next"

import styles from "./AnalysisDetail.module.scss"

type Props = {
  data: InferenceJobInfo
  projectType?: ProjectType
}

function AnalysisDetail(props: Props) {
  const { t } = useTranslation()

  const renderStatus = () => {
    switch (getAnalysisStatusLabel(props.data.status)) {
      case "WAITING":
        return <Chip size={"small"} color={"grey"} label={t("analysis.detail.status.waiting.label") ?? ""} />
      case "PROCESSING":
        return <Chip size={"small"} color={"green"} label={t("analysis.detail.status.processing.label") ?? ""} />
      case "POSTPROCESSING":
        return <Chip size={"small"} color={"green"} label={t("analysis.detail.status.postprocessing.label") ?? ""} />
      case "ERROR":
        return <Chip className={styles.error} size={"small"} label={t("analysis.detail.status.error.label") ?? ""} />
      case "COMPLETED":
        return <Chip size={"small"} color={"blue"} label={t("analysis.detail.status.completed.label") ?? ""} />
      default:
        return <></>
    }
  }

  return (
    <section className={styles.analysisDetail}>
      <div className={styles.detail}>
        <span className={styles.label}>{t("analysis.detail.imagingDate.label")}</span>
        <span className={styles.data}>{getDateLabel(props.data.imagingTime.at(-1), "TIL_SEC") || "-"}</span>
      </div>
      <div className={styles.detail}>
        <span className={styles.label}>{t("analysis.detail.createdDate.label")}</span>
        <span className={styles.data}>{getDateLabel(props.data.createdTime, "TIL_SEC") || "-"}</span>
      </div>
      {props.projectType === "SINGLE" && (
        <>
          <div className={styles.detail}>
            <span className={styles.label}>{t("analysis.detail.region.label")}</span>
            <span className={styles.data}>{props.data.regionName || "-"}</span>
          </div>
          <div className={styles.detail}>
            <span className={styles.label}>{t("analysis.detail.satellite.label")}</span>
            <span className={styles.data}>{props.data.satellite.at(-1) || "-"}</span>
          </div>
          <div className={styles.detail}>
            <span className={styles.label}>{t("analysis.detail.gsd.label")}</span>
            <span className={styles.data}>{getFormattedGsd(props.data.gsdX.at(-1), props.data.gsdY.at(-1)) || "-"}</span>
          </div>
        </>
      )}
      <div className={styles.detail}>
        <span className={styles.label}>{t("analysis.detail.credit.label")}</span>
        <span className={styles.data}>{props.data.creditAmount}</span>
      </div>
      <div className={styles.detail}>
        <span className={styles.label}>{t("analysis.detail.status.label")}</span>
        <span className={styles.data}>{renderStatus()}</span>
      </div>
      {getAnalysisStatusLabel(props.data.status) === ("PROCESSING" || "POSTPROCESSING") &&
        Math.ceil(props.data.eta / 60) !== 0 && (
          <div className={styles.detail}>
            <span className={styles.label}>{t("analysis.detail.estimatedTime.label")}</span>
            <span className={styles.data}>
              <Trans i18nKey={"analysis.detail.estimatedTime.value"}>
                <span className={styles.highlight}>4</span>
                <span className={styles.sub}> min left</span> {{ time: Math.ceil(props.data.eta / 60) }}
              </Trans>
            </span>
          </div>
        )}
      {getAnalysisStatusLabel(props.data.status) === "COMPLETED" && (
        <div className={styles.detail}>
          <span className={styles.label}>{t("analysis.detail.duration.label")}</span>
          <span className={styles.data}>{getTimeLabel(props.data.totalDuration) || "-"}</span>
        </div>
      )}
    </section>
  )
}
export default AnalysisDetail
