import {
  deleteProject,
  EmptyStatus,
  FilterOutlined,
  getProjects,
  initProject,
  initProjects,
  isError,
  isInitialProject,
  Loading,
  PATH_CREATE_PROJECT,
  PATH_PROJECT_INFO,
  Project,
  Projects,
  ProjectsQueryType,
  ProjectStatusType,
  ProjectType,
  PutProjectRequestType,
  putProjects,
  useInterval,
  usePageVisibility,
  Search,
  getAiPacks,
  AiPackInfos,
  initAiPackInfos,
  AiPackType,
  ConfirmModalContent,
  ConfirmModal,
} from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import {
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  Button,
  Dropdown,
  IconButton,
  Toast,
} from "@SIAnalytics/ovision-design-system"
import { RangePickerValue } from "@SIAnalytics/ovision-design-system/build/src/component/data-entry/picker/range-picker/RangePicker"
import cn from "classnames"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import Panel from "../service-layout/Panel"
import PanelPagination from "../service-layout/PanelPagination"
import PanelTip from "../service-layout/PanelTip"
import PanelTitle from "../service-layout/PanelTitle"
import styles from "./Analysis.module.scss"
import AnalysisFilterPopover, { ProjectTypeFilter, ServiceListType } from "./AnalysisFilter"
import AnalysisMap from "./AnalysisMap"
import { useProjectOutletContext } from "./ProjectContext"
import ProjectItem, { ProjectContextMenu } from "./ProjectItem"

type OrderColumnType = "CREATED_TIME" | "AI_PACK_NAME" | "CREDIT_AMOUNT" | "PROJECT_NAME" | "PROJECT_TYPE"
type ProjectConfirmModalContent = ConfirmModalContent & { type: ProjectContextMenu }

function Analysis() {
  const { selectedProject, setSelectedProject, setIsProjectEditing, view } = useProjectOutletContext()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [projects, setProjects] = useState<Projects>(initProjects)
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [orderColumn, setOrderColumn] = useState<OrderColumnType>("CREATED_TIME")
  const [orderDirection, setOrderDirection] = useState<"DESC" | "ASC">("DESC")
  const [isPanelExpanded, setIsPanelExpanded] = useState<boolean>(true)
  const [selectedProjectByContextMenu, setSelectedProjectByContextMenu] = useState<Project>(initProject)
  const [projectContextMenu, setProjectContextMenu] = useState<ProjectContextMenu>("")
  const [projectType, setProjectType] = useState<ProjectTypeFilter>("ALL")
  const [keyword, setKeyword] = useState<string>("")
  const [selectedAIPacks, setSelectedAIPacks] = useState<AiPackType[]>([])
  const [createdPeriod, setCreatedPeriod] = useState<RangePickerValue>([null, null])
  const [aiPackInfos, setAiPackInfos] = useState<AiPackInfos>(initAiPackInfos)

  const [isFilterPopoverVisible, setIsFilterPopoverVisible] = useState<boolean>(false)
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false)

  const navigate = useNavigate()
  const isPageVisible = usePageVisibility()
  const { t } = useTranslation()

  const serviceList: ServiceListType = [
    {
      name: t("service.imageImprovement.title"),
      aiPacks: [{ name: t("aiPack.superEnhancement.title"), type: "SuperEnhancement" }],
    },
    {
      name: t("service.objectDetection.title"),
      aiPacks: [
        { name: t("aiPack.aircraftDetection.title"), type: "AircraftDetection" },
        { name: t("aiPack.vesselDetection.title"), type: "VesselDetection" },
        { name: t("aiPack.vehicleDetection.title"), type: "VehicleDetection" },
      ],
    },
    {
      name: t("service.infrastructure.title"),
      aiPacks: [
        // { name: t("aiPack.landCoverSegmentation.title"), type: "LandCoverSegmentation" },
        { name: t("aiPack.buildingSegmentation.title"), type: "BuildingSegmentation" },
        { name: t("aiPack.roadSegmentation.title"), type: "RoadSegmentation" },
      ],
    },
  ]
  const confirmModalList: ProjectConfirmModalContent[] = useMemo(
    () => [
      {
        type: "PAUSE",
        title: t("modal.confirm.analysis.projectPause.title"),
        desc: t("modal.confirm.analysis.projectPause.desc") ?? "",
        confirmText: t("button.pause"),
        isPrimaryConfirm: true,
        onConfirmButtonClick: () => void onProjectChangeClick(),
      },
      {
        type: "RESTART",
        title: t("modal.confirm.analysis.projectRestart.title"),
        desc: t("modal.confirm.analysis.projectRestart.desc") ?? "",
        confirmText: t("button.restart"),
        isPrimaryConfirm: true,
        onConfirmButtonClick: () => void onProjectChangeClick(),
      },
      {
        type: "CLOSE",
        title: t("modal.confirm.analysis.projectClose.title"),
        desc: t("modal.confirm.analysis.projectClose.desc") ?? "",
        confirmText: t("button.close"),
        isPrimaryConfirm: true,
        onConfirmButtonClick: () => void onProjectChangeClick(),
      },
      {
        type: "DELETE",
        title: t("modal.confirm.analysis.projectDelete.title"),
        desc: t("modal.confirm.analysis.projectDelete.desc") ?? "",
        confirmText: t("button.delete"),
        onConfirmButtonClick: () => void onProjectDeleteClick(),
      },
    ],
    [t, selectedProjectByContextMenu, projectContextMenu],
  )

  useInterval(
    () => {
      void setProjectsAsync()
    },
    isPageVisible ? 5000 : null,
    false,
  )

  useEffect(() => {
    const setAiPacksAsync = async () => {
      try {
        const aiPacks = await getAiPacks()
        if (!isError(aiPacks)) setAiPackInfos(aiPacks)
      } catch (e) {
        captureException(e)
      }
    }
    void setAiPacksAsync()
  }, [])

  useEffect(() => {
    void setProjectsAsync()
  }, [pageIndex, orderColumn, orderDirection, projectType, keyword, createdPeriod, selectedAIPacks])

  const setProjectsAsync = async () => {
    const types: ProjectType = projectType === "ALL" ? "" : projectType
    const aiPackIdList = getAIPackIdList(selectedAIPacks)
    const query: ProjectsQueryType = {
      pageIndex,
      orderColumn,
      orderDirection,
      types,
      keyword,
      startTime: createdPeriod[0]?.toISOString(),
      endTime: createdPeriod[1]?.toISOString(),
      aiPackIdList: aiPackIdList.length === 0 ? undefined : aiPackIdList,
    }

    try {
      const _projects = await getProjects(query)
      if (!isError(_projects)) setProjects(_projects)
    } catch (e) {
      captureException(e)
    } finally {
      setIsLoading(false)
    }
  }

  const onProjectClick = (project: Project) => {
    setSelectedProject(project)
    navigate(PATH_PROJECT_INFO)
  }
  const onContextMenuClick = (project: Project, menu: ProjectContextMenu) => {
    setProjectContextMenu(menu)
    setSelectedProjectByContextMenu(project)
    if (menu === "EDIT") {
      setSelectedProject(project)
      setIsProjectEditing(true)
      navigate(PATH_PROJECT_INFO)
    } else {
      setIsConfirmModalVisible(true)
    }
  }
  const getConfirmModalContent = () => {
    const _init: ProjectConfirmModalContent = { type: "", title: "", desc: "", confirmText: "" }
    return confirmModalList.find((_content) => _content.type === projectContextMenu) ?? _init
  }

  const onProjectChangeClick = async () => {
    setIsConfirmModalVisible(false)
    if (isInitialProject(selectedProjectByContextMenu) || !projectContextMenu || projectContextMenu === "DELETE") return

    let _status: ProjectStatusType = ""
    let _successMsg = ""
    let _errorMsg = ""
    if (projectContextMenu === "PAUSE") {
      _status = "PAUSED"
      _successMsg = t("toast.analysis.projectPaused.success")
      _errorMsg = t("toast.analysis.projectPaused.error")
    } else if (projectContextMenu === "RESTART") {
      _status = "PROCESSING"
      _successMsg = t("toast.analysis.projectRestarted.success")
      _errorMsg = t("toast.analysis.projectRestarted.error")
    } else if (projectContextMenu === "CLOSE") {
      _status = "CLOSED"
      _successMsg = t("toast.analysis.projectClosed.success")
      _errorMsg = t("toast.analysis.projectClosed.error")
    }
    if (!_status) return
    const data: PutProjectRequestType = {
      id: selectedProjectByContextMenu.id,
      name: selectedProjectByContextMenu.name,
      status: _status,
    }

    try {
      const _res = await putProjects(data)
      if (!isError(_res) && _res === 200) Toast({ message: _successMsg, type: "success" })
    } catch (e) {
      Toast({ message: _errorMsg, type: "error" })
      captureException(e)
    } finally {
      setProjectContextMenu("")
      setSelectedProjectByContextMenu(initProject)
    }
  }
  const onProjectDeleteClick = async () => {
    setIsConfirmModalVisible(false)
    if (isInitialProject(selectedProjectByContextMenu)) return

    try {
      const _delete = await deleteProject(selectedProjectByContextMenu.id)
      if (!isError(_delete) && _delete === 200)
        Toast({ message: t("toast.analysis.projectDeleted.success"), type: "success" })
    } catch (e) {
      Toast({ message: t("toast.analysis.projectDeleted.error"), type: "error" })
      captureException(e)
    } finally {
      setProjectContextMenu("")
      setSelectedProjectByContextMenu(initProject)
      setSelectedProject(initProject)
    }
  }

  const getFormattedSortLabel = () => {
    if (orderColumn === "CREATED_TIME") return t("sortBy.createdDate.label")
    else if (orderColumn === "AI_PACK_NAME") return t("sortBy.aiPack.label")
    else if (orderColumn === "CREDIT_AMOUNT") return t("sortBy.credit.label")
    else if (orderColumn === "PROJECT_NAME") return t("sortBy.projectName.label")
    else if (orderColumn === "PROJECT_TYPE") return t("sortBy.projectType.label")
    return ""
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true)
    const { value } = e.currentTarget
    setKeyword(value)
  }

  const clearSearch = () => {
    setIsLoading(true)
    setKeyword("")
  }

  const getAIPackIdList = (aiPackTypes: string[]): string[] => {
    return aiPackTypes
      .map((type) => aiPackInfos.aiPackInfoList.find((aiPack) => aiPack.type === type)?.id)
      .filter((id): id is string => id !== undefined)
  }

  const handleChangeFilter = (
    selectedAIPacks: AiPackType[],
    createdPeriod: RangePickerValue,
    projectType: ProjectTypeFilter,
  ) => {
    setIsLoading(true)
    setSelectedAIPacks(selectedAIPacks)
    setCreatedPeriod(createdPeriod)
    setProjectType(projectType)
  }

  const handleRestFilter = () => {
    setIsLoading(true)
    setProjectType("ALL")
    setSelectedAIPacks([])
    setCreatedPeriod([null, null])
  }

  const isInitFilter = () => {
    return projectType === "ALL" && selectedAIPacks.length === 0 && createdPeriod[0] === null && createdPeriod[1] === null
  }

  const projectsMemo = useMemo(() => projects, [projects])

  return (
    <div className={styles.analysisContainer}>
      <div className={cn(styles.analysis, !isPanelExpanded && styles.closed)}>
        <Panel className={styles.panel}>
          <div className={styles.headerPanel}>
            <PanelTitle label={t("analysis.title")} />
            <Button size={"small"} onClick={() => navigate(PATH_CREATE_PROJECT)}>
              {t("button.createProject")}
            </Button>
          </div>

          <Search
            className={styles.search}
            placeholder={`${t("searchBy.label", { target: t("sortBy.projectName.label") })}`}
            onChange={handleSearch}
            onClearButtonClick={clearSearch}
          />
          <div className={styles.panelToolBar}>
            <div className={styles.sort}>
              <span>{t("sortBy.label")}</span>
              <Dropdown
                size={"xs"}
                type={"single"}
                itemList={[
                  { text: t("sortBy.aiPack.label"), value: t("sortBy.aiPack.label") },
                  { text: t("sortBy.createdDate.label"), value: t("sortBy.createdDate.label") },
                  { text: t("sortBy.credit.label"), value: t("sortBy.credit.label") },
                  { text: t("sortBy.projectName.label"), value: t("sortBy.projectName.label") },
                  { text: t("sortBy.projectType.label"), value: t("sortBy.projectType.label") },
                ]}
                value={getFormattedSortLabel()}
                onChange={(value) => {
                  if (value === t("sortBy.createdDate.label")) setOrderColumn("CREATED_TIME")
                  else if (value === t("sortBy.aiPack.label")) setOrderColumn("AI_PACK_NAME")
                  else if (value === t("sortBy.credit.label")) setOrderColumn("CREDIT_AMOUNT")
                  else if (value === t("sortBy.projectName.label")) setOrderColumn("PROJECT_NAME")
                  else if (value === t("sortBy.projectType.label")) setOrderColumn("PROJECT_TYPE")
                }}
              />
              <IconButton
                size={"xs"}
                type={"square"}
                icon={orderDirection === "ASC" ? <ArrowUpwardOutlined /> : <ArrowDownwardOutlined />}
                onClick={() => (orderDirection === "DESC" ? setOrderDirection("ASC") : setOrderDirection("DESC"))}
              />
            </div>
            <AnalysisFilterPopover
              createdPeriod={createdPeriod}
              isVisible={isFilterPopoverVisible}
              projectType={projectType}
              selectedAIPacks={selectedAIPacks}
              serviceList={serviceList}
              setIsVisible={setIsFilterPopoverVisible}
              onFilterChange={handleChangeFilter}
              onReset={handleRestFilter}
            >
              <IconButton
                wrapperClassName={cn(styles.filter, !isInitFilter() && styles.active)}
                size={"xs"}
                type={"square"}
                icon={<FilterOutlined />}
                onClick={() => setIsFilterPopoverVisible((prev) => !prev)}
              />
            </AnalysisFilterPopover>
          </div>

          <div className={styles.projectList}>
            {projects.totalCount === 0 ? (
              isLoading ? (
                <div className={styles.loadingContainer}>
                  <Loading className={styles.loading} size={"small"} />
                </div>
              ) : isInitFilter() && keyword === "" ? (
                <EmptyStatus title={t("analysis.empty.title") ?? ""} desc={t("analysis.empty.desc") ?? ""} />
              ) : (
                <EmptyStatus title={t("analysis.noResults.title") ?? ""} desc={t("analysis.noResults.desc") ?? ""} />
              )
            ) : (
              projectsMemo.projects.map((project) => (
                <ProjectItem
                  key={project.id}
                  active={project.id === selectedProject.id}
                  data={project}
                  onCloseClick={(project) => onContextMenuClick(project, "CLOSE")}
                  onDeleteClick={(project) => onContextMenuClick(project, "DELETE")}
                  onEditClick={(project) => onContextMenuClick(project, "EDIT")}
                  onPauseClick={(project) => onContextMenuClick(project, "PAUSE")}
                  onProjectClick={onProjectClick}
                  onRestartClick={(project) => onContextMenuClick(project, "RESTART")}
                />
              ))
            )}
          </div>
        </Panel>
        <PanelPagination
          currentDataCount={projectsMemo.projects.length}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          totalCount={projectsMemo.totalCount}
        />
        <PanelTip isPanelExpanded={isPanelExpanded} onClick={() => setIsPanelExpanded((prev) => !prev)} />

        {isConfirmModalVisible && (
          <ConfirmModal {...getConfirmModalContent()} onCloseButtonClick={() => setIsConfirmModalVisible(false)} />
        )}
      </div>
      <AnalysisMap view={view} />
    </div>
  )
}
export default Analysis
