import { DOMAIN_NAME } from "@ovision-gis-frontend/shared"
import React from "react"
import { Trans, useTranslation } from "react-i18next"

import styles from "./LegalContentsEnglish.module.scss"

type Props = {}
function EndUserLicenseAgreement(props: Props) {
  const { t } = useTranslation()

  return (
    <section className={styles.legal}>
      <p className={styles.label}>{t("legal.title")}</p>
      <p className={styles.title}>{t("eula.title")}</p>

      <dl>
        <dd>
          <Trans i18nKey={"eula.desc"}>
            desc...
            <a href={"https://" + DOMAIN_NAME} rel={"noopener noreferrer"} target={"_blank"}>
              domain...
            </a>
            desc...
            {{ domain: DOMAIN_NAME }}
          </Trans>
        </dd>
        <div className={styles.content}>
          <Trans i18nKey={"eula.first.content"}>
            <dt>1. Definitions</dt>
            <dd>
              <strong>1.1.</strong> desc...
            </dd>
            <dd>
              <strong>1.2.</strong> desc...
            </dd>
            <dd>
              <strong>1.3.</strong> desc...
            </dd>
            <dd>
              <strong>1.4.</strong> desc...
            </dd>
            <dd>
              <strong>1.5.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"eula.second.content"}>
            <dt>2. License</dt>
            <dd>
              <strong>2.1.</strong> desc...
            </dd>
            <dd>
              <strong>2.2.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"eula.third.content"}>
            <dt>3. Export Control</dt>
            <dd>
              <strong>3.1.</strong> desc...
            </dd>
            <dd>
              <strong>3.2.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"eula.fourth.content"}>
            <dt>4. Intellectual Property Rights</dt>
            <dd>
              <strong>4.1.</strong> desc...
            </dd>
            <dd>
              <strong>4.2.</strong> desc...
            </dd>
            <dd>
              <strong>4.3.</strong> desc...
            </dd>
            <dd>
              <strong>4.4.</strong> desc...
            </dd>
            <dd>
              <strong>4.5.</strong> desc...
            </dd>
            <dd>
              <strong>4.6.</strong> desc...
            </dd>
            <dd>
              <strong>4.7.</strong> desc...
            </dd>
            <dd>
              <strong>4.8.</strong> desc...
            </dd>
            <dd>
              <strong>4.9.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"eula.fifth.content"}>
            <dt>5. Warranty Liability</dt>
            <dd>
              <strong>5.1.</strong> desc...
            </dd>
            <dd>
              <strong>5.2.</strong> desc...
            </dd>
            <dd>
              <strong>5.3.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"eula.sixth.content"}>
            <dt>6. Miscellaneous</dt>
            <dd>
              <strong>6.1.</strong> desc...
            </dd>
            <dd>
              <strong>6.2.</strong> desc...
            </dd>
            <dd>
              <strong>6.3.</strong> desc...
            </dd>
            <dd>
              <strong>6.4.</strong> desc...
            </dd>
          </Trans>
        </div>
      </dl>
    </section>
  )
}

export default EndUserLicenseAgreement
