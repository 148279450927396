import React from "react"

import { IconBase, IconProps } from "../../IconBase"

export function CloudOutlined(props: IconProps) {
  return (
    <IconBase viewBox={"0 0 20 20"}>
      <path
        id={"Vector"}
        d={
          "M10 5.00016C12.1833 5.00016 14.0667 6.55016 14.4917 8.69183L14.7417 9.94183L16.0167 10.0335C17.3167 10.1168 18.3333 11.2085 18.3333 12.5002C18.3333 13.8752 17.2083 15.0002 15.8333 15.0002H5C3.15833 15.0002 1.66667 13.5085 1.66667 11.6668C1.66667 9.9585 2.94167 8.5335 4.63333 8.3585L5.525 8.26683L5.94167 7.47516C6.73333 5.95016 8.28333 5.00016 10 5.00016ZM10 3.3335C7.59167 3.3335 5.5 4.70016 4.45833 6.70016C1.95 6.96683 0 9.09183 0 11.6668C0 14.4252 2.24167 16.6668 5 16.6668H15.8333C18.1333 16.6668 20 14.8002 20 12.5002C20 10.3002 18.2917 8.51683 16.125 8.36683C15.5583 5.49183 13.0333 3.3335 10 3.3335Z"
        }
      />
    </IconBase>
  )
}
