import {
  DrawRectangleOutlined,
  getScene,
  initScene,
  isError,
  MyLocationOutlined,
  ProviderType,
  Provider,
  Scene,
  AiPackType,
} from "@ovision-gis-frontend/shared"
import {
  AREA_AOI_LIMIT,
  generateOlMap,
  generateOsmLayer,
  generateTileLayer,
  generateVectorLayerForAoi,
  generateVectorSource,
  generateVectorSourceFromWkb,
  generateWmtsSource,
  LAYER_TILE_CREATE_PROJECT_MY_IMAGES,
  LAYER_TILE_CREATE_PROJECT_MY_IMAGES_Z_INDEX,
  LAYER_TILE_CREATE_PROJECT_PROVIDER,
  LAYER_TILE_CREATE_PROJECT_PROVIDER_Z_INDEX,
  LAYER_VECTOR_AOI,
  removeLayers,
  setAoiStyle,
  getTileLayer,
  isInitialScene,
} from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import { HorizontalButtonGroup, Toast, VerticalButtonGroup } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import { Map as OlMap, View } from "ol"
import { defaults as defaultControls, ScaleLine } from "ol/control"
import { Polygon } from "ol/geom"
import { Draw } from "ol/interaction"
import { createBox } from "ol/interaction/Draw"
import { fromLonLat } from "ol/proj"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { SourceTabType, WaybackLayerType } from "./CreateProject"
import styles from "./CreateProjectMap.module.scss"
import SearchMap from "./SearchMap"

type Props = {
  aiPack: AiPackType
  center: number[]
  setCenter: React.Dispatch<React.SetStateAction<number[]>>
  source: SourceTabType
  provider: ProviderType
  setProvider: React.Dispatch<React.SetStateAction<ProviderType>>
  selectedLocalImage: Scene
  setSelectedLocalImage: React.Dispatch<React.SetStateAction<Scene>>
  providerUrl: Provider
  setProviderUrl: React.Dispatch<React.SetStateAction<Provider>>
  setWaybackLayers: React.Dispatch<React.SetStateAction<WaybackLayerType[]>>
  waybackLayer: WaybackLayerType
  aoiArea: React.MutableRefObject<number>
  aoiPolygon: Polygon | null
  setAoiPolygon: React.Dispatch<React.SetStateAction<Polygon | null>>
  view: View
}

function CreateProjectMap(props: Props) {
  const mapRef = useRef<OlMap | null>(null)
  const mapContainerRef = useRef<HTMLDivElement>(null)
  const scaleLineRef = useRef<HTMLDivElement>(null)
  const [loadingMap, setLoadingMap] = useState<boolean>(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (mapRef.current) return
    if (!mapContainerRef.current || !mapRef || !scaleLineRef.current) return
    const scaleLine = new ScaleLine({
      className: styles.olScaleLine,
      target: scaleLineRef.current,
    })

    mapRef.current = generateOlMap(
      [generateOsmLayer()],
      mapContainerRef.current,
      props.view,
      defaultControls({ rotate: false }).extend([scaleLine]),
    )
    mapRef.current?.on("loadstart", () => setLoadingMap(true))
    mapRef.current?.on("loadend", () => setLoadingMap(false))

    return () => {
      scaleLine.setMap(null)
      mapRef.current?.getAllLayers().map((_layer) => mapRef.current?.removeLayer(_layer))
      mapRef.current?.un("loadstart", () => setLoadingMap(true))
      mapRef.current?.un("loadend", () => setLoadingMap(false))
      mapRef.current?.setTarget(undefined)
      mapRef.current = null
    }
  }, [mapContainerRef.current])

  const setWmtsTileLayer = () => {
    if (props.providerUrl.mapbox === "" && props.providerUrl.wayback === "") {
      const setProviderUrlAsync = async () => {
        try {
          const _providerUrl = await getScene()
          if (!isError(_providerUrl)) {
            props.setProviderUrl(_providerUrl)
            addWmtsTileLayer(_providerUrl)
          }
        } catch (e) {
          captureException(e)
        }
      }
      void setProviderUrlAsync()
    } else {
      addWmtsTileLayer(props.providerUrl)
    }
  }

  const addWmtsTileLayer = (url: Provider) => {
    if (!mapRef.current || !props.provider) return
    let _url
    let _urlCheck
    let _config
    let _label = ""
    if (props.provider === "MAPBOX") {
      _url = url.mapbox
      _urlCheck = url.mapbox
      _config = { layer: "clb5za3o3000d15lb7bt7nayt", matrixSet: "GoogleMapsCompatible", crossOrigin: "anonymous" }
      _label = t("analysis.source.mapbox.label")
    } else if (props.provider === "WAYBACK") {
      _url = url.wayback + "1.0.0/WMTSCapabilities.xml"
      _urlCheck = url.wayback
      _config = { layer: props.waybackLayer?.identifier || "WAYBACK", matrixSet: "default028mm", crossOrigin: "anonymous" }
      _label = t("analysis.source.wayback.label")
    }
    if (!_urlCheck || !_url || !_config) {
      Toast({ message: t("toast.createProject.providerFetched.error", { provider: _label }), type: "error" })
      return
    }

    const _wmts = generateWmtsSource(props.provider, _url, _config)
    if (_wmts) {
      _wmts.then((ret) => {
        if (ret) {
          const { source, layers } = ret
          const _tile = generateTileLayer(
            source,
            0,
            undefined,
            LAYER_TILE_CREATE_PROJECT_PROVIDER,
            LAYER_TILE_CREATE_PROJECT_PROVIDER_Z_INDEX,
          )
          mapRef.current?.addLayer(_tile)
          if (props.provider === "WAYBACK") {
            const _layers = layers.map((layer): WaybackLayerType => {
              return {
                title: "Title" in layer ? layer["Title"]!.toString().split("Wayback ")[1]?.slice(0, -1) : "",
                identifier: "Identifier" in layer ? layer["Identifier"]!.toString() : "",
              }
            })
            const filtered = _layers.filter((_layer) => _layer.title !== "" && _layer.identifier !== "")
            props.setWaybackLayers(filtered)
          }
        } else {
          Toast({ message: t("toast.createProject.providerGenerated.error", { provider: _label }), type: "error" })
        }
      })
    } else {
      Toast({ message: t("toast.createProject.providerFetched.error", { provider: _label }), type: "error" })
    }
  }

  useEffect(() => {
    if (!mapRef.current || props.center.length === 0) return
    mapRef.current.getView().setCenter(fromLonLat(props.center))
    mapRef.current.getView().setZoom(14)
  }, [props.center])

  useEffect(() => {
    if (!mapRef.current) return
    if (
      props.source === "PROVIDERS" &&
      props.provider === "WAYBACK" &&
      props.waybackLayer.title !== "" &&
      props.waybackLayer.identifier !== ""
    )
      setWmtsTileLayer()

    return () => {
      if (!mapRef.current) return
      removeLayers(mapRef.current, LAYER_TILE_CREATE_PROJECT_PROVIDER, true)
    }
  }, [props.waybackLayer])

  useEffect(() => {
    if (!props.source || !props.provider || (props.source !== "PROVIDERS" && props.provider)) {
      if (!mapRef.current) return
      props.setProvider("")
      props.aoiArea.current = 0
      props.setAoiPolygon(null)
      removeLayers(mapRef.current, LAYER_VECTOR_AOI)
      return
    }

    if (props.source === "CLOUD") {
      props.setProvider("")
      props.setSelectedLocalImage(initScene)
    } else if (props.source === "PROVIDERS") {
      props.setSelectedLocalImage(initScene)
      if (props.provider) setWmtsTileLayer()
      else props.setProvider("")
    } else {
      props.setProvider("")
    }

    return () => {
      if (!mapRef.current) return
      removeLayers(mapRef.current, LAYER_TILE_CREATE_PROJECT_PROVIDER, true)
    }
  }, [props.source, props.provider])

  useEffect(() => {
    const addSceneLayer = async () => {
      if (!mapRef.current) return
      removeLayers(mapRef.current, LAYER_TILE_CREATE_PROJECT_MY_IMAGES, true)

      if (props.source === "CLOUD" || isInitialScene(props.selectedLocalImage)) return

      const _wkb = props.selectedLocalImage.boundary
      if (!_wkb) return
      const _source = generateVectorSourceFromWkb(_wkb)
      const _targetExtent = _source.getExtent()

      try {
        const sceneUrl = props.selectedLocalImage.wmsUrl || props.selectedLocalImage.sceneUrl
        if (sceneUrl) {
          const _tile = await getTileLayer(
            sceneUrl,
            _targetExtent,
            LAYER_TILE_CREATE_PROJECT_MY_IMAGES,
            LAYER_TILE_CREATE_PROJECT_MY_IMAGES_Z_INDEX,
          )
          mapRef.current?.addLayer(_tile)
        }
        mapRef.current?.getView().fit(_targetExtent)
      } catch (e) {
        captureException(e)
      }
    }

    void addSceneLayer()
    return () => {
      if (!mapRef.current) return
      props.aoiArea.current = 0
      props.setAoiPolygon(null)
      removeLayers(mapRef.current, LAYER_VECTOR_AOI)
      removeLayers(mapRef.current, LAYER_TILE_CREATE_PROJECT_MY_IMAGES, true)
    }
  }, [props.selectedLocalImage])

  useEffect(() => {
    if (!props.aoiPolygon || props.aiPack === "SuperEnhancement") {
      if (!mapRef.current) return
      props.setAoiPolygon(null)
      removeLayers(mapRef.current, LAYER_VECTOR_AOI)
      return
    }
    if (!props.aiPack || props.source === "MY_IMAGES" || props.source === "CLOUD") return // @NOTE: MY_IMAGES, CLOUD는 AOI 제한 없음

    const limit = (parseInt(t("aiPack.aoi.limit")) || AREA_AOI_LIMIT) / 1e6
    if (props.aoiArea.current > limit)
      Toast({ message: t("toast.createProject.aoiLimit.error", { limit: limit }), type: "error" })
  }, [props.aoiPolygon, props.aoiArea.current, props.aiPack, t])

  const drawAoi = () => {
    if (!mapRef.current) return
    const _source = generateVectorSource()

    const draw = new Draw({
      source: _source,
      type: "Circle",
      geometryFunction: createBox(),
      style: setAoiStyle(4, true),
      freehand: true,
    })
    const keyboardEventListener = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        draw.abortDrawing()
        mapRef.current?.removeInteraction(draw)
        document.removeEventListener("keydown", keyboardEventListener)
      }
    }
    document.addEventListener("keydown", keyboardEventListener)

    draw.on("drawend", (evt) => {
      mapRef.current?.removeInteraction(draw)
      const _geometry = evt.feature.getGeometry()
      if (_geometry) {
        props.setAoiPolygon(_geometry as Polygon)
        const _vector = generateVectorLayerForAoi(_source)
        mapRef.current?.addLayer(_vector)
      }
    })
    mapRef.current.addInteraction(draw)
  }

  const onAddAoiButtonClick = () => {
    if (!mapRef.current) return
    if (findDrawInteraction(mapRef.current)) return
    if (props.source === "PROVIDERS") props.aoiArea.current = 0
    props.setAoiPolygon(null)
    removeLayers(mapRef.current, LAYER_VECTOR_AOI)
    drawAoi()
  }

  const onMyLocationButtonClick = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { longitude, latitude } }) => {
          props.setCenter([longitude, latitude])
        },
        (error) => captureException(error),
      )
    }
  }

  return (
    <div className={styles.createMapContainer}>
      <div className={styles.createMap}>
        <div className={styles.mapContainer} ref={mapContainerRef}>
          <SearchMap setCenter={props.setCenter} />
          <div className={styles.center}>
            {props.aiPack !== "SuperEnhancement" && (
              <HorizontalButtonGroup
                groupClassName={styles.drawAoiBtn}
                size={"large"}
                options={[
                  {
                    label: t("button.drawAAoi"),
                    icon: <DrawRectangleOutlined />,
                    value: t("button.drawAAoi"),
                    onClick: onAddAoiButtonClick,
                  },
                ]}
              />
            )}
          </div>
          <VerticalButtonGroup
            groupClassName={"mapBtn"}
            size={"large"}
            options={[
              {
                label: t("button.myLocation") ?? "",
                icon: <MyLocationOutlined />,
                value: t("button.myLocation") ?? "",
                onClick: onMyLocationButtonClick,
              },
            ]}
          />
          <progress className={cn("tile-load-progress", loadingMap && "tile-loading")} max={"100"} value={"100"} />
          <div className={styles.customScaleLine}>
            <span>©Open Street Map</span>
            <div ref={scaleLineRef} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateProjectMap

function findDrawInteraction(map: OlMap) {
  return map
    .getInteractions()
    .getArray()
    .find((value) => value instanceof Draw)
}
