import { getSyncStorages, isError, Modal, SyncStorage, useInterval, usePageVisibility } from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import { Button } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import MyConnections from "../settings/connections/MyConnections"
import styles from "./SelectBucketModal.module.scss"

export type SelectBucketType = "SOURCE" | "EXPORT"
type Props = {
  className?: string
  type: SelectBucketType
  onTableRowClick: (record: SyncStorage) => void
  onNewConnectButtonClick?: React.MouseEventHandler<HTMLElement>
  closeModal: () => void
}

function SelectBucketModal(props: Props) {
  const [dataSource, setDataSource] = useState<SyncStorage[]>([])
  const [isSyncStorageFetching, setIsSyncStorageFetching] = useState<boolean>(true)
  const isPageVisible = usePageVisibility()
  const { t } = useTranslation()

  useInterval(
    () => {
      void setSyncStoragesAsync()
    },
    isPageVisible ? 5000 : null,
    true,
  )

  const setSyncStoragesAsync = async () => {
    try {
      const _syncStorages = await getSyncStorages()
      if (!isError(_syncStorages)) setDataSource(_syncStorages.syncStorages)
    } catch (error) {
      captureException(error)
    } finally {
      setIsSyncStorageFetching(false)
    }
  }

  return (
    <Modal
      className={cn(styles.selectBucketModal, props.className)}
      title={{
        label: props.type === "SOURCE" ? t("createProject.source.label") : t("createProject.autoExportResults.label"),
        right: (
          <Button size={"small"} onClick={props.onNewConnectButtonClick}>
            {t("button.newConnect")}
          </Button>
        ),
      }}
      content={
        <MyConnections
          tableClassName={styles.myConnectionsTable}
          dataSource={dataSource}
          isSelectBucketModal={true}
          spinning={isSyncStorageFetching}
          onTableRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                props.onTableRowClick(record)
                props.closeModal()
              },
            }
          }}
        />
      }
      onCloseButtonClick={props.closeModal}
    />
  )
}

export default SelectBucketModal
