import React from "react"

import { IconBase, IconProps } from "../../IconBase"

export function BackwardOutlined(props: IconProps) {
  return (
    <IconBase viewBox={"0 0 24 24"} {...props}>
      <path
        id="Vector"
        d="M17.835 3.8701L16.055 2.1001L6.16504 12.0001L16.065 21.9001L17.835 20.1301L9.70504 12.0001L17.835 3.8701Z"
      />
    </IconBase>
  )
}
