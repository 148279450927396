import {
  contactNumberReg,
  emailReg,
  isError,
  postSyncStoragesInquiry,
  SyncStorageInquiryRequestType,
  MyAccount,
  Modal,
} from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import { Button, Checkbox, InputField, TextArea, Toast } from "@SIAnalytics/ovision-design-system"
import { InputFieldBasicStateType } from "@SIAnalytics/ovision-design-system/build/src/component/data-entry/text-input/input-field/InputField"
import cn from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./CreationInquiryModal.module.scss"

type Props = {
  className?: string
  user: MyAccount
  closeModal: () => void
}

function CreationInquiryModal(props: Props) {
  const [firstName, setFirstName] = useState<string>(props.user.account.profile.givenName ?? "")
  const [isFirstNameValid, setIsFirstNameValid] = useState<InputFieldBasicStateType>("default")
  const [lastName, setLastName] = useState<string>(props.user.account.profile.familyName ?? "")
  const [isLastNameValid, setIsLastNameValid] = useState<InputFieldBasicStateType>("default")
  const [nameOfOrganization, setNameOfOrganization] = useState<string>("")
  const [isNameOfOrganizationValid, setIsNameOfOrganizationValid] = useState<InputFieldBasicStateType>("default")
  const [contactNumber, setContactNumber] = useState<string>("")
  const [isContactNumberValid, setIsContactNumberValid] = useState<InputFieldBasicStateType>("default")
  const [email, setEmail] = useState<string>(props.user.account.profile.email ?? "")
  const [isEmailValid, setIsEmailValid] = useState<InputFieldBasicStateType>("default")
  const [questionsAndComments, setQuestionsAndComments] = useState<string>("")
  const [contactByPhone, setContactByPhone] = useState<boolean>(false)
  const [isContactByPhoneValid, setIsContactByPhoneValid] = useState<InputFieldBasicStateType>("default")
  const [contactByEmail, setContactByEmail] = useState<boolean>(false)
  const [isContactByEmailValid, setIsContactByEmailValid] = useState<InputFieldBasicStateType>("default")
  const [isRequesting, setIsRequesting] = useState<boolean>(false)
  const { t } = useTranslation()

  const onFirstNameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setIsFirstNameValid("default")
    setFirstName(e.target.value)
  }
  const onLastNameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setIsLastNameValid("default")
    setLastName(e.target.value)
  }
  const onNameOfOrganizationChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setIsNameOfOrganizationValid("default")
    setNameOfOrganization(e.target.value)
  }
  const onContactNumberChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setIsContactNumberValid("default")
    setContactNumber(e.target.value.replace(/[^0-9]/g, "").replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`))
  }
  const onEmailChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setIsEmailValid("default")
    setEmail(e.target.value)
  }
  const onContactByPhoneClick: React.MouseEventHandler<HTMLInputElement> = (e) => {
    setIsContactByPhoneValid("default")
    setIsContactByEmailValid("default")
    setContactByPhone(!contactByPhone)
  }
  const onContactByEmailClick: React.MouseEventHandler<HTMLInputElement> = (e) => {
    setIsContactByPhoneValid("default")
    setIsContactByEmailValid("default")
    setContactByEmail(!contactByEmail)
  }

  const onInquiryButtonClick: React.MouseEventHandler<HTMLElement> = (e) => {
    let firstNameCheck = false
    let lastNameCheck = false
    let organizationCheck = false
    let numberCheck = false
    let emailCheck = false

    if (firstName) {
      setIsFirstNameValid("default")
      firstNameCheck = true
    } else {
      setIsFirstNameValid("error")
    }

    if (lastName) {
      setIsLastNameValid("default")
      lastNameCheck = true
    } else {
      setIsLastNameValid("error")
    }

    if (nameOfOrganization) {
      setIsNameOfOrganizationValid("default")
      organizationCheck = true
    } else {
      setIsNameOfOrganizationValid("error")
    }

    if (contactNumberReg.test(contactNumber)) {
      setIsContactNumberValid("default")
      numberCheck = true
    } else {
      setIsContactNumberValid("error")
    }

    if (emailReg.test(email)) {
      setIsEmailValid("default")
      emailCheck = true
    } else {
      setIsEmailValid("error")
    }

    if (!contactByPhone && !contactByEmail) {
      setIsContactByPhoneValid("error")
      setIsContactByEmailValid("error")
    }

    if (
      !firstNameCheck ||
      !lastNameCheck ||
      !organizationCheck ||
      !numberCheck ||
      !emailCheck ||
      (!contactByPhone && !contactByEmail)
    )
      return

    const requestSyncStoragesInquiryAsync = async () => {
      setIsRequesting(true)

      let _contactMethod
      if (contactByPhone && contactByEmail) _contactMethod = "PHONE,EMAIL"
      else if (contactByPhone) _contactMethod = "PHONE"
      else _contactMethod = "EMAIL"
      const data: SyncStorageInquiryRequestType = {
        firstName,
        lastName,
        groupName: nameOfOrganization,
        contactNumber,
        businessEmail: email,
        preferredContactMethod: _contactMethod,
        comments: questionsAndComments,
      }

      try {
        const _inquiry = await postSyncStoragesInquiry(data)
        if (!isError(_inquiry) && _inquiry === 200) Toast({ message: t("toast.inquiry.success"), type: "success" })
      } catch (e) {
        Toast({ message: t("toast.inquiry.error"), type: "error" })
        captureException(e)
      } finally {
        setIsRequesting(false)
      }
      props.closeModal()
    }
    void requestSyncStoragesInquiryAsync()
  }

  return (
    <Modal
      className={cn(styles.creationInquiryModal, props.className)}
      title={t("modal.creationInquiry.title")}
      content={
        <>
          <p>{t("modal.creationInquiry.desc")}</p>
          <form>
            <div className={styles.horizontal}>
              <InputField
                labelClassName={styles.required}
                helperText={isFirstNameValid === "error" ? t("form.required") ?? "" : ""}
                label={t("textInput.firstName") ?? ""}
                placeholder={t("textInput.placeholder", { target: t("textInput.firstName") }) ?? ""}
                state={isFirstNameValid}
                value={firstName}
                onChange={onFirstNameChange}
                onClearButtonClick={() => setFirstName("")}
              />
              <InputField
                labelClassName={styles.required}
                helperText={isLastNameValid === "error" ? t("form.required") ?? "" : ""}
                label={t("textInput.lastName") ?? ""}
                placeholder={t("textInput.placeholder", { target: t("textInput.lastName") }) ?? ""}
                state={isLastNameValid}
                value={lastName}
                onChange={onLastNameChange}
                onClearButtonClick={() => setLastName("")}
              />
            </div>
            <InputField
              labelClassName={styles.required}
              helperText={isNameOfOrganizationValid === "error" ? t("form.required") ?? "" : ""}
              label={t("textInput.nameOfOrganization") ?? ""}
              placeholder={t("textInput.placeholder", { target: t("textInput.nameOfOrganization") }) ?? ""}
              state={isNameOfOrganizationValid}
              value={nameOfOrganization}
              onChange={onNameOfOrganizationChange}
              onClearButtonClick={() => setNameOfOrganization("")}
            />
            <InputField
              labelClassName={styles.required}
              helperText={isContactNumberValid === "error" ? t("form.required") ?? "" : ""}
              label={t("textInput.contactNumber") ?? ""}
              placeholder={"000-0000-0000"}
              state={isContactNumberValid}
              value={contactNumber}
              onChange={onContactNumberChange}
              onClearButtonClick={() => setContactNumber("")}
            />
            <InputField
              labelClassName={styles.required}
              helperText={isEmailValid === "error" ? t("form.required") ?? "" : ""}
              label={t("textInput.email.label") ?? ""}
              placeholder={t("textInput.placeholder", { target: t("textInput.email.label") }) ?? ""}
              state={isEmailValid}
              value={email}
              onChange={onEmailChange}
              onClearButtonClick={() => setEmail("")}
            />
            <div className={styles.checkboxGroup}>
              <span className={styles.required}>{t("modal.creationInquiry.preferredContactMethod")}</span>
              <Checkbox className={styles.checkbox} active={contactByPhone} onClick={onContactByPhoneClick}>
                {t("modal.creationInquiry.phoneCall")}
              </Checkbox>
              <Checkbox className={styles.checkbox} active={contactByEmail} onClick={onContactByEmailClick}>
                {t("modal.creationInquiry.email")}
              </Checkbox>
              {(isContactByPhoneValid === "error" || isContactByEmailValid === "error") && (
                <span className={styles.checkRequired}>{t("form.required")}</span>
              )}
            </div>
            <TextArea
              textAreaClassName={styles.input}
              label={t("textArea.comments") ?? ""}
              placeholder={t("textArea.placeholder.comments") ?? ""}
              value={questionsAndComments}
              onChange={(value) => setQuestionsAndComments(value)}
            />
          </form>
        </>
      }
      footer={{
        right: (
          <>
            <Button size={"small"} type={"outlined"} onClick={props.closeModal}>
              {t("button.cancel")}
            </Button>
            <Button size={"small"} loading={isRequesting} onClick={onInquiryButtonClick}>
              {t("button.inquiry")}
            </Button>
          </>
        ),
      }}
      onCloseButtonClick={props.closeModal}
    />
  )
}

export default CreationInquiryModal
