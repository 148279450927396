import { initOrder, isInitialOrder, OE_ORDER, OE_PLAN_ID, PATH_PRICING } from "@ovision-gis-frontend/shared"
import OvisionEarthLogoImg from "@ovision-gis-frontend/shared/src/asset/logo/OvisionEarthLogo.png"
import { Toast } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Navigate, useLocation } from "react-router-dom"

import { changeIdToCardinal } from "../../../common/util"
import styles from "./Payment.module.scss"
import PaymentWidget from "./PaymentWidget"

type Props = {}
function Payment(props: Props) {
  const location = useLocation()
  const { t } = useTranslation()
  const [orderOption, setOrderOption] = useState<string>("")

  let planId = "-1"
  let order = initOrder()
  if (location.state) {
    planId = OE_PLAN_ID in location.state ? location.state[OE_PLAN_ID] : "-1"
    order = OE_ORDER in location.state ? location.state[OE_ORDER] : initOrder()
  }
  if (planId === "-1" || isInitialOrder(order)) {
    Toast({ message: t("toast.order.invalid.error"), type: "error" })
    return <Navigate replace={true} to={PATH_PRICING} />
  } else {
    if (!orderOption) setOrderOption(changeIdToCardinal(planId))
  }

  return (
    <div className={cn(styles.payment)}>
      <img className={styles.logo} alt={""} src={OvisionEarthLogoImg} />
      <div className={styles.section}>
        <p className={styles.title}>{t("payment.info.title")}</p>
        <div className={styles.horizontal}>
          <span className={styles.subTitle}>{t("payment.info.orderCredit.label")}</span>
          <span className={styles.subTitle}>{t(`pricing.plan.option.${orderOption}.credit`)}</span>
        </div>
        <div className={cn(styles.horizontal, styles.subTitleContainer)}>
          <span className={styles.desc}>{t("payment.info.paidCredit.label")}</span>
          <span className={styles.desc}>{t(`pricing.plan.option.${orderOption}.paid.credit`)}</span>
        </div>
        <div className={styles.horizontal}>
          <span className={styles.desc}>{t("payment.info.bonusCredit.label")}</span>
          <span className={styles.desc}>{"+ " + t(`pricing.plan.option.${orderOption}.bonus.credit`)}</span>
        </div>
        <div className={styles.divider} />
        <div className={styles.horizontal}>
          <span className={styles.totalPrice}>{t("payment.info.totalPrice.label")}</span>
          <span className={cn(styles.totalPrice, styles.primary)}>{t(`pricing.plan.option.${orderOption}.price`)}</span>
        </div>
      </div>

      <div className={styles.section}>
        <p className={styles.title}>{t("payment.method.title")}</p>
        <PaymentWidget className={styles.paymentWidget} order={order} />
      </div>
    </div>
  )
}

export default Payment
