import { DeepNullable, Nullable } from "../../common/common"

export type MemberRequestType = {
  lastName: string
  firstName: string
  countryCode: string
  email: string
  password: string
  jobTitle: string
  organizationName: string
  promotionCode: string | null
}

export type MyAccount = {
  account: {
    id: string
    profile: {
      email: string
      familyName: string
      givenName: string
      locale: string
      jobTitle: string | null
      organizationName: string | null
      picture: string
    }
  }
  creditInfo: {
    accountId: string
    remainCredit: number
    usedCredit: number
  }
}

export type MyAccountDto = {
  [key in keyof MyAccount]: DeepNullable<Nullable<MyAccount[key]>>
}

export const myAccountFromDto = (dto: MyAccountDto): MyAccount => {
  return {
    account: {
      id: dto.account?.id ?? "",
      profile: {
        email: dto.account?.profile?.email ?? "",
        familyName: dto.account?.profile?.familyName ?? "",
        givenName: dto.account?.profile?.givenName ?? "",
        locale: dto.account?.profile?.givenName ?? "",
        jobTitle: dto.account?.profile?.jobTitle ?? null,
        organizationName: dto.account?.profile?.organizationName ?? null,
        picture: dto.account?.profile?.picture ?? "",
      },
    },
    creditInfo: {
      accountId: dto.creditInfo?.accountId ?? "",
      remainCredit: dto.creditInfo?.remainCredit ?? 0,
      usedCredit: dto.creditInfo?.usedCredit ?? 0,
    },
  }
}

export const initMyAccount = (): MyAccount => {
  return {
    account: {
      id: "",
      profile: {
        email: "",
        familyName: "",
        givenName: "",
        locale: "",
        jobTitle: "",
        organizationName: "",
        picture: "",
      },
    },
    creditInfo: {
      accountId: "",
      remainCredit: 0,
      usedCredit: 0,
    },
  }
}

export const isInitialMyAccount = (myAccount: MyAccount) => {
  return myAccount.account.id === ""
}
