import { AiPackType, getAiPack, useElementVisible } from "@ovision-gis-frontend/shared"
import cn from "classnames"
import React from "react"

import styles from "./HoverInfoPopover.module.scss"

type Props = {
  className?: string
  isVisible: boolean
  aiPack: AiPackType
  trigger?: React.ReactNode
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
}
function HoverInfoPopover(props: Props) {
  const hidePopover = () => props.setIsVisible(false)
  const popoverRef = useElementVisible<HTMLDivElement>(hidePopover)
  const aiPack = getAiPack(props.aiPack)

  return (
    <div ref={popoverRef}>
      {props.trigger}
      {props.isVisible && (
        <div className={cn(styles.infoHoverPopover, props.className)} key={props.aiPack}>
          <p className={styles.title}>{aiPack.label}</p>
          <p>{aiPack.catchphrase}</p>
          <img key={props.aiPack} alt={aiPack.label} src={aiPack.heroImage} />
        </div>
      )}
    </div>
  )
}

export default HoverInfoPopover
