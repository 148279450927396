import React from "react"

import { IconBase, IconProps } from "../../IconBase"

export function DrawRectangleOutlined(props: IconProps) {
  return (
    <IconBase viewBox={"0 0 19 18"} {...props}>
      <path d="M17.75 5.25L17.75 0.75L13.25 0.75L13.25 2.25L5.75 2.25L5.75 0.75L1.25 0.75L1.25 5.25L2.75 5.25L2.75 12.75L1.25 12.75L1.25 17.25L5.75 17.25L5.75 15.75L13.25 15.75L13.25 17.25L17.75 17.25L17.75 12.75L16.25 12.75L16.25 5.25L17.75 5.25ZM2.75 2.25L4.25 2.25L4.25 3.75L2.75 3.75L2.75 2.25ZM4.25 15.75L2.75 15.75L2.75 14.25L4.25 14.25L4.25 15.75ZM13.25 14.25L5.75 14.25L5.75 12.75L4.25 12.75L4.25 5.25L5.75 5.25L5.75 3.75L13.25 3.75L13.25 5.25L14.75 5.25L14.75 12.75L13.25 12.75L13.25 14.25ZM16.25 15.75L14.75 15.75L14.75 14.25L16.25 14.25L16.25 15.75ZM14.75 3.75L14.75 2.25L16.25 2.25L16.25 3.75L14.75 3.75Z" />
    </IconBase>
  )
}
