import { ProjectType } from "@ovision-gis-frontend/shared"
import { AiPackType } from "@ovision-gis-frontend/shared"
import { Checkbox, RangePicker, RadioGroup } from "@SIAnalytics/ovision-design-system"
import { RangePickerValue } from "@SIAnalytics/ovision-design-system/build/src/component/data-entry/picker/range-picker/RangePicker"
import React from "react"
import { useTranslation } from "react-i18next"

import FilterPopover, { Divider } from "../../common/FilterPopover"
import styles from "./AnalysisFilter.module.scss"

export type ProjectTypeFilter = Extract<ProjectType, "REALTIME" | "SINGLE"> | "ALL"

export type ServiceListType = {
  name: string
  aiPacks: {
    name: string
    type: AiPackType
  }[]
}[]

type Props = {
  isVisible: boolean
  projectType: ProjectTypeFilter
  children?: React.ReactNode
  placement?: "left" | "right"
  selectedAIPacks: AiPackType[]
  createdPeriod: RangePickerValue
  serviceList: ServiceListType
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  onReset?: React.MouseEventHandler<HTMLElement>
  onFilterChange?: (aiPackTypes: AiPackType[], createdPeriod: RangePickerValue, projectType: ProjectTypeFilter) => void
}

function AnalysisFilterPopover(props: Props) {
  const { t } = useTranslation()

  const selectService = (serviceName: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const service = props.serviceList.find((service) => service.name === serviceName)
    if (!service || !props.onFilterChange) return
    const aiPackNames = service.aiPacks.map((aiPack) => aiPack.type)
    if (e.target.checked) {
      props.onFilterChange(
        props.selectedAIPacks.concat(aiPackNames.filter((name) => !props.selectedAIPacks.includes(name))),
        props.createdPeriod,
        props.projectType,
      )
    } else {
      props.onFilterChange(
        props.selectedAIPacks.filter((aiPack) => !aiPackNames.includes(aiPack)),
        props.createdPeriod,
        props.projectType,
      )
    }
  }

  const selectAIPack = (aiPack: AiPackType) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!props.onFilterChange) return
    if (e.target.checked) {
      props.onFilterChange(props.selectedAIPacks.concat([aiPack]), props.createdPeriod, props.projectType)
    } else {
      props.onFilterChange(
        props.selectedAIPacks.filter((selectedAIPack) => selectedAIPack !== aiPack),
        props.createdPeriod,
        props.projectType,
      )
    }
  }

  const handleChangeDate = (value: RangePickerValue) => {
    value[0]?.setHours(0, 0, 0, 0)
    value[1]?.setHours(23, 59, 59, 999)
    props.onFilterChange?.(props.selectedAIPacks, value, props.projectType)
  }

  const disabledDate = (currentDate: moment.Moment) => {
    const today = new Date()
    today.setHours(23, 59, 59, 999)
    if (currentDate && currentDate.toDate() > today) return true
    else return false
  }

  return (
    <FilterPopover
      className={styles.analysisFilter}
      isVisible={props.isVisible}
      setIsVisible={props.setIsVisible}
      trigger={props.children}
      onReset={props.onReset}
    >
      <div className={styles.periodFilter}>
        <p className={styles.fieldLabel}>{t("filter.createdPeriod")}</p>
        <RangePicker
          combine={true}
          disabledDate={disabledDate}
          format={"yyyy-MM-DD"}
          limited={true}
          limitedPlaceholder={t("rangePicker.endDate") ?? undefined}
          placeholder={t("rangePicker.startDate") ?? undefined}
          value={props.createdPeriod}
          onChange={handleChangeDate}
        />
      </div>
      <Divider />
      <form>
        <div className={styles.formItem}>
          <p className={styles.fieldLabel}>{t("service.title")}</p>
          {props.serviceList.map((service) => {
            return (
              <fieldset className={styles.service} key={service.name}>
                <Checkbox
                  active={service.aiPacks.every((aiPack) => props.selectedAIPacks.includes(aiPack.type))}
                  indeterminate={service.aiPacks.some((aiPack) => props.selectedAIPacks.includes(aiPack.type))}
                  onChange={selectService(service.name)}
                >
                  {service.name}
                </Checkbox>
                {service.aiPacks.map((aiPack) => {
                  return (
                    <Checkbox
                      className={styles.aiPack}
                      key={aiPack.type}
                      active={props.selectedAIPacks.includes(aiPack.type)}
                      onChange={selectAIPack(aiPack.type)}
                    >
                      {aiPack.name}
                    </Checkbox>
                  )
                })}
              </fieldset>
            )
          })}
        </div>
        <Divider />
        <div className={styles.formItem}>
          <p className={styles.fieldLabel}>{t("filter.projectType")}</p>
          <RadioGroup
            type={"vertical"}
            options={
              [
                { label: t("filter.all"), value: "ALL" },
                { label: t("filter.bucketAnalysis"), value: "REALTIME" },
                { label: t("filter.singleAnalysis"), value: "SINGLE" },
              ] as { label: string; value: ProjectTypeFilter }[]
            }
            value={props.projectType}
            onChange={(value) =>
              props.onFilterChange?.(props.selectedAIPacks, props.createdPeriod, value as ProjectTypeFilter)
            }
          />
        </div>
      </form>
    </FilterPopover>
  )
}

export default AnalysisFilterPopover
