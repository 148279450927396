import cn from "classnames"
import React from "react"

import styles from "./AiPackParagraph.module.scss"

type Props = {
  className?: string
  icon: string | React.ReactNode
  title: string
  desc: string
  img?: string
  imgAlt?: string
  selectable?: boolean
  active?: boolean
  onClick?: React.MouseEventHandler<HTMLElement>
}

export default function AiPackParagraph(props: Props) {
  return (
    <article
      className={cn(
        styles.aiPackParagraph,
        props.selectable && styles.selectable,
        props.active && styles.active,
        props.className,
      )}
      onClick={props.onClick}
    >
      <span className={styles.title}>
        {typeof props.icon === "string" ? <img alt={"icon"} src={props.icon} /> : props.icon}
        <p>{props.title}</p>
      </span>
      <p className={styles.desc}>{props.desc}</p>
      {props.img && <img className={styles.img} alt={props.imgAlt} src={props.img} />}
    </article>
  )
}
