import { AnalysisStatusType, GsdRange } from "@ovision-gis-frontend/shared"
import { Checkbox, CheckboxGroup, RangePicker, Slider } from "@SIAnalytics/ovision-design-system"
import { CheckboxValueType } from "@SIAnalytics/ovision-design-system/build/src/component/data-entry/checkbox/Checkbox"
import { RangePickerValue } from "@SIAnalytics/ovision-design-system/build/src/component/data-entry/picker/range-picker/RangePicker"
import React from "react"
import { useTranslation } from "react-i18next"

import FilterPopover, { Divider } from "../../../common/FilterPopover"
import styles from "./AnalysisImageFilter.module.scss"

type Props = {
  isVisible: boolean
  children?: React.ReactNode
  createdPeriod: RangePickerValue
  imagingPeriod: RangePickerValue
  statuses: StatusFilterType[]
  gsdRange: GsdRange
  gsdBoundary: GsdRange
  selectedSatellites: string[]
  satellites: string[]
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  onReset?: React.MouseEventHandler<HTMLElement>
  onFilterChange?: (
    createdPeriod: RangePickerValue,
    imagingPeriod: RangePickerValue,
    statuses: StatusFilterType[],
    gsdRange: GsdRange,
    satellites: string[],
  ) => void
}

export type StatusFilterType = "WAITING" | "PROCESSING" | "POSTPROCESSING" | "COMPLETED" | "ERROR"

function AnalysisImageFilter(props: Props) {
  const { t } = useTranslation()

  const handleChangeCreatedDate = (value: RangePickerValue) => {
    value[0]?.setHours(0, 0, 0, 0)
    value[1]?.setHours(23, 59, 59, 999)
    props.onFilterChange?.(value, props.imagingPeriod, props.statuses, props.gsdRange, props.selectedSatellites)
  }

  const handleChangePeriodDate = (value: RangePickerValue) => {
    value[0]?.setHours(0, 0, 0, 0)
    value[1]?.setHours(23, 59, 59, 999)
    props.onFilterChange?.(props.createdPeriod, value, props.statuses, props.gsdRange, props.selectedSatellites)
  }

  const disabledDate = (currentDate: moment.Moment) => {
    const today = new Date()
    today.setHours(23, 59, 59, 999)
    if (currentDate && currentDate.toDate() > today) return true
    else return false
  }

  const changeStatus = (statuses: CheckboxValueType[]) => {
    if (!statuses.every((status) => isStatusFilterOption(status))) return

    props.onFilterChange?.(
      props.createdPeriod,
      props.imagingPeriod,
      statuses as StatusFilterType[],
      props.gsdRange,
      props.selectedSatellites,
    )
  }

  const changeGsdFilter = (value: GsdRange) => {
    props.onFilterChange?.(props.createdPeriod, props.imagingPeriod, props.statuses, value, props.selectedSatellites)
  }

  const selectSatellite = (satellites: CheckboxValueType[]) => {
    if (!satellites.every((satellite) => typeof satellite === "string")) return
    props.onFilterChange?.(props.createdPeriod, props.imagingPeriod, props.statuses, props.gsdRange, satellites as string[])
  }

  return (
    <FilterPopover
      className={styles.imageFilter}
      isVisible={props.isVisible}
      placement={"right"}
      setIsVisible={props.setIsVisible}
      trigger={props.children}
      onReset={props.onReset}
    >
      <div className={styles.periodFilter}>
        <div>
          <p className={styles.fieldLabel}>{t("filter.createdPeriod")}</p>
          <RangePicker
            combine={true}
            disabledDate={disabledDate}
            format={"yyyy-MM-DD"}
            limited={true}
            limitedPlaceholder={t("rangePicker.endDate") ?? undefined}
            placeholder={t("rangePicker.startDate") ?? undefined}
            value={props.createdPeriod}
            onChange={handleChangeCreatedDate}
          />
        </div>
        <div>
          <p className={styles.fieldLabel}>{t("filter.imagingPeriod.label")}</p>
          <RangePicker
            combine={true}
            disabledDate={disabledDate}
            format={"yyyy-MM-DD"}
            limited={true}
            limitedPlaceholder={t("rangePicker.endDate") ?? undefined}
            placeholder={t("rangePicker.startDate") ?? undefined}
            value={props.imagingPeriod}
            onChange={handleChangePeriodDate}
          />
        </div>
      </div>
      <Divider />
      <div>
        <p className={styles.fieldLabel}>{t("sortBy.resolution.label")}</p>
        <Slider
          type={"range"}
          mark={{
            [props.gsdBoundary[0] ?? 0]: props.gsdBoundary[0] ?? "?" + "m",
            [props.gsdBoundary[1] ?? 0]: props.gsdBoundary[1] ?? "?" + "m",
          }}
          max={props.gsdBoundary[1] ?? undefined}
          min={props.gsdBoundary[0] ?? undefined}
          step={0.1}
          value={props.gsdRange[0] !== null && props.gsdRange[1] !== null ? (props.gsdRange as [number, number]) : undefined}
          onChange={changeGsdFilter}
        />
      </div>
      <Divider />
      <form>
        <fieldset>
          <legend className={styles.fieldLabel}>{t("sortBy.satellite.label")}</legend>
          <CheckboxGroup
            type={"vertical"}
            options={props.satellites.map((value) => ({ label: value === "" ? t("unknown") : value, value }))}
            value={props.selectedSatellites}
            onChange={selectSatellite}
          ></CheckboxGroup>
        </fieldset>
      </form>
      <Divider />
      <form>
        <fieldset className={styles.statusField}>
          <legend className={styles.fieldLabel}>{t("sortBy.status.label")}</legend>
          <CheckboxGroup
            type={"vertical"}
            options={[
              { label: t("analysis.detail.status.waiting.label"), value: "WAITING" },
              { label: t("analysis.detail.status.processing.label"), value: "PROCESSING" },
              { label: t("analysis.detail.status.postprocessing.label"), value: "POSTPROCESSING" },
              { label: t("analysis.detail.status.completed.label"), value: "COMPLETED" },
              { label: t("analysis.detail.status.error.label"), value: "ERROR" },
            ]}
            value={props.statuses}
            onChange={changeStatus}
          ></CheckboxGroup>
        </fieldset>
      </form>
    </FilterPopover>
  )
}

export default AnalysisImageFilter

export const statusFilterLabelToAnalysisStatus = (status: StatusFilterType): AnalysisStatusType[] => {
  switch (status) {
    case "WAITING":
      return ["IDLE", "SCENE_DOWNLOADING", "SCENE_DOWNLOADED", "SCHEDULE_WAITING"]
    case "PROCESSING":
      return ["PROCESSING"]
    case "POSTPROCESSING":
      return ["POSTPROCESSING"]
    case "COMPLETED":
      return ["COMPLETED"]
    case "ERROR":
      return ["ERROR", "SCENE_DOWNLOAD_ERROR"]
  }
}

const isStatusFilterOption = (status: unknown): status is StatusFilterType => {
  if (typeof status !== "string") return false
  const types: StatusFilterType[] = ["WAITING", "PROCESSING", "POSTPROCESSING", "COMPLETED", "ERROR"]
  return types.includes(status as StatusFilterType)
}
