import {
  OE_ORDER,
  OE_PLAN_ID,
  OE_REFERRER,
  PATH_PRICING,
  PATH_PRICING_PAYMENT,
  PATH_SIGN_IN,
  PlanType,
  postOrders,
  SEARCH_REDIRECT,
  useToken,
} from "@ovision-gis-frontend/shared"
import RadarGlobeAutomobileImg from "@ovision-gis-frontend/shared/src/asset/images/RadarGlobeAutomobile.png"
import { captureException } from "@sentry/react"
import { Chip, Toast } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"

import SettingsHeader from "../settings/SettingsHeader"
import AiPackTable from "./AiPackTable"
import styles from "./Pricing.module.scss"
import VerticalPlan from "./VerticalPlan"

export type CardType = "WELCOME" | "CREDIT" | "INQUIRY"
export type PlanContent = {
  // @NOTE: Deprecated
  isWelcomePlan?: boolean
  planType: PlanType
  title: string
  price: string
  unit: string
  coinFeature?: string
  aiPackFeature: string
  areaFeature: string
  orderBtnLabel: string
}
export type NewPlanContent = {
  planId: string
  isWelcomePlan?: boolean
  credit: string
  desc: string
  price: string
  chip?: React.ReactNode
}

type Props = {
  className?: string
  isSettingsMenu?: boolean
  onBackButtonClick?: React.MouseEventHandler<HTMLElement>
}

function Pricing(props: Props) {
  const [selectedPlanId, setSelectedPlanId] = useState<string>("")
  const [isOrderRequesting, setIsOrderRequesting] = useState<boolean>(false)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { token } = useToken()
  const location = useLocation()

  useEffect(() => {
    document.getElementById("pricing")?.scrollIntoView({ behavior: "auto" })
  }, [location])

  const welcomePlan: NewPlanContent = {
    planId: t("plan.option.zero.id"),
    isWelcomePlan: true,
    credit: t("pricing.plan.welcomeGift.credit"),
    desc: "pricing.plan.welcomeGift.desc",
    price: t("pricing.plan.welcomeGift.price"),
    chip: <Chip color={"blue"} label={t("pricing.plan.welcomeGift.chip.label") ?? ""} />,
  }
  const newPlan: NewPlanContent[] = [
    {
      planId: t("plan.option.one.id"),
      credit: t("pricing.plan.option.one.credit"),
      desc: "pricing.plan.option.one.desc",
      price: t("pricing.plan.option.one.price"),
    },
    {
      planId: t("plan.option.two.id"),
      credit: t("pricing.plan.option.two.credit"),
      desc: "pricing.plan.option.two.desc",
      price: t("pricing.plan.option.two.price"),
      chip: <Chip color={"green"} label={t("pricing.plan.option.two.chip.label") ?? ""} />,
    },
    {
      planId: t("plan.option.three.id"),
      credit: t("pricing.plan.option.three.credit"),
      desc: "pricing.plan.option.three.desc",
      price: t("pricing.plan.option.three.price"),
      chip: <Chip color={"green"} label={t("pricing.plan.option.three.chip.label") ?? ""} />,
    },
    {
      planId: t("plan.option.four.id"),
      credit: t("pricing.plan.option.four.credit"),
      desc: "pricing.plan.option.four.desc",
      price: t("pricing.plan.option.four.price"),
      chip: <Chip color={"yellow"} label={t("pricing.plan.option.four.chip.label") ?? ""} />,
    },
  ]

  const handleWelcomePlanButtonClick = () => {
    navigate(PATH_SIGN_IN)
  }
  const handlePlanButtonClick = (e: React.MouseEvent<HTMLElement>, planId: string) => {
    setSelectedPlanId(planId)
    if (!token) {
      navigate(PATH_SIGN_IN + SEARCH_REDIRECT + PATH_PRICING)
      return
    } else {
      const postOrderAsync = async () => {
        setIsOrderRequesting(true)
        try {
          const _order = await postOrders({ planId })
          navigate(PATH_PRICING_PAYMENT, {
            state: { [OE_PLAN_ID]: planId, [OE_ORDER]: _order, [OE_REFERRER]: location.pathname },
          })
        } catch (e) {
          Toast({ message: t("toast.order.error"), type: "error" })
          captureException(e)
        } finally {
          setIsOrderRequesting(false)
        }
      }
      void postOrderAsync()
    }
  }

  return (
    <div className={cn(styles.pricing, props.isSettingsMenu && styles.isSettingsMenu, props.className)} id={"pricing"}>
      {props.isSettingsMenu && (
        <SettingsHeader
          className={styles.header}
          title={t("pricing.title")}
          backButton={true}
          onBackButtonClick={props.onBackButtonClick}
        />
      )}
      <div className={styles.headCopy}>
        <span className={styles.title}>{t("pricing.headCopy.title")}</span>
        <span className={styles.desc}>
          {!token ? t("pricing.headCopy.desc.beforeSignIn") : t("pricing.headCopy.desc.afterSignIn")}
        </span>
      </div>
      <div className={styles.planList}>
        {!token && <VerticalPlan content={welcomePlan} onPlanButtonClick={handleWelcomePlanButtonClick} />}
        {newPlan.map((planContent) => (
          <VerticalPlan
            key={planContent.planId}
            content={planContent}
            isLoading={planContent.planId === selectedPlanId && isOrderRequesting}
            isSettingsMenu={props.isSettingsMenu}
            onPlanButtonClick={handlePlanButtonClick}
          />
        ))}
      </div>
      <AiPackTable className={styles.aiPackTable} />
      <div className={styles.aiPackTableCaptionWrapper}>
        <span className={styles.aiPackTableCaption}>{t("pricing.unit.label") + " : " + t("pricing.unit.value")}</span>
      </div>
      {!props.isSettingsMenu && (
        <div className={styles.globeWrapper}>
          <img className={styles.globeImg} alt={"globe"} src={RadarGlobeAutomobileImg} />
        </div>
      )}
    </div>
  )
}
export default Pricing
