import { Button } from "@SIAnalytics/ovision-design-system"
import React from "react"
import { useTranslation } from "react-i18next"

import EmailOnboardingLayout from "./EmailOnboardingLayout"
import styles from "./EmailOnboardingLayout.module.scss"

type Props = {
  email: string
  isResendRequesting: boolean
  onResendClick: React.MouseEventHandler<HTMLElement>
}

function VerifyEmailExpired(props: Props) {
  const { t } = useTranslation()

  return (
    <EmailOnboardingLayout title={t("verifyEmail.expired.title")} desc={t("verifyEmail.expired.desc")} email={props.email}>
      <Button className={styles.resendBtn} size={"large"} loading={props.isResendRequesting} onClick={props.onResendClick}>
        {t("button.resend")}
      </Button>
    </EmailOnboardingLayout>
  )
}

export default VerifyEmailExpired
