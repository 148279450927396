import {
  Avatar,
  CreditCardOutlined,
  OpenInNewOutlined,
  PATH_CREDIT,
  PATH_GEO_AI_PACK,
  PATH_HOME,
  PATH_PRICING,
  PATH_SIGN_IN,
  StarBorderOutlined,
  URL_CONTACTS,
  URL_DOCS_EN,
  URL_DOCS_KO,
  useToken,
  MyAccount,
} from "@ovision-gis-frontend/shared"
import OvisionLogoImg from "@ovision-gis-frontend/shared/src/asset/logo/OvisionLogo.png"
import { Button, TextButton } from "@SIAnalytics/ovision-design-system"
import i18next from "i18next"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { NavLink, useNavigate } from "react-router-dom"

import ProfilePopover from "../../user/ProfilePopover"
import { geoAiPackTitleId } from "../GeoAiPack"
import styles from "./GnbHeader.module.scss"

type Props = {
  user: MyAccount
}

function GnbHeader(props: Props) {
  const [isProfilePopoverVisible, setIsProfilePopoverVisible] = useState<boolean>(false)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { token } = useToken()

  const changeLanguage = () => {
    if (i18next.language === "en") void i18next.changeLanguage("ko")
    else void i18next.changeLanguage("en")
  }

  return (
    <header>
      <div className={styles.gnbMenuContainer}>
        <div className={styles.left}>
          <NavLink className={styles.logoContainer} to={PATH_HOME}>
            <img className={styles.logo} alt={""} src={OvisionLogoImg} />
            <span className={styles.logoMainText}>{"OVISION"}</span>
            <span className={styles.logoSubText}>{"Earth"}</span>
          </NavLink>
          <nav>
            <NavLink to={PATH_GEO_AI_PACK + geoAiPackTitleId.imageImprovement}>{t("geoAiPack.title")}</NavLink>
            <NavLink to={PATH_PRICING}>{t("pricing.title")}</NavLink>
            <a href={i18next.language === "ko" ? URL_DOCS_KO : URL_DOCS_EN} rel={"noopener noreferrer"} target={"_blank"}>
              {t("docs.title")}
              <OpenInNewOutlined />
            </a>
            <a href={URL_CONTACTS} rel={"noopener noreferrer"} target={"_blank"}>
              {t("contactUs.title")}
              <OpenInNewOutlined />
            </a>
          </nav>
        </div>
        <div className={styles.right}>
          <TextButton className={styles.langBtn} icon={<StarBorderOutlined />} onClick={changeLanguage}>
            {t("button.i18nextLanguage")}
          </TextButton>
          {!token ? (
            <Button className={styles.getStartedBtn} onClick={() => navigate(PATH_SIGN_IN)}>
              {t("button.signIn")}
            </Button>
          ) : (
            <div className={styles.afterLogin}>
              <div className={styles.divider} />
              <TextButton className={styles.creditBtn} type={"secondary"} onClick={() => navigate(PATH_CREDIT)}>
                <>
                  <CreditCardOutlined />
                  <span className={styles.credit}>{props.user.creditInfo.remainCredit}</span>
                </>
              </TextButton>
              <button
                className={styles.profileContainer}
                onClick={() => setIsProfilePopoverVisible(!isProfilePopoverVisible)}
              >
                <ProfilePopover
                  className={styles.profileMenu}
                  popoverClassName={styles.profilePopover}
                  isVisible={isProfilePopoverVisible}
                  setIsVisible={setIsProfilePopoverVisible}
                  user={props.user}
                >
                  <Avatar
                    className={styles.profile}
                    alt={"profile"}
                    firstName={props.user.account.profile.givenName}
                    profileImage={props.user.account.profile.picture}
                  />
                </ProfilePopover>
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  )
}
export default GnbHeader
