import cn from "classnames"
import React from "react"

import styles from "./Panel.module.scss"

type Props = {
  className?: string
  children?: React.ReactNode
}

function Panel(props: Props) {
  return <section className={cn(styles.panel, props.className)}>{props.children}</section>
}
export default Panel
