import React, { useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"

import SideNavigationBar from "./SideNavigationBar"
import styles from "./User.module.scss"

function User() {
  const location = useLocation()

  useEffect(() => {
    document.getElementById("user")?.scrollIntoView({ behavior: "auto" })
  }, [location])

  return (
    <div className={styles.protectedLayout} id={"user"}>
      <SideNavigationBar />
      <Outlet />
    </div>
  )
}
export default User
