import { patchSceneStatus, postSceneUpload, uploadBySignedUrl } from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import { Button, Toast } from "@SIAnalytics/ovision-design-system"
import React, { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { useTranslation } from "react-i18next"

const defs = require("./crs-defs.json")
Object.keys(defs).forEach((key) => {
  defs["urn:ogc:def:crs:" + key.replace(":", "::")] = defs[key]
})

type Props = {
  onUploadStarted?: () => void
}

export default function ImageUpload(props: Props) {
  const { t } = useTranslation()

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file, index) => {
        try {
          if (index === 0) props.onUploadStarted?.()
          void uploadToBucket(file)
        } catch (e) {
          Toast({ message: t("toast.imageUpload.error"), type: "error" })
          captureException(e)
        }
      })
    },
    [t],
  )

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noDrag: true,
    noClick: true,
    noKeyboard: true,
    accept: { "image/tiff": [".tif", ".tiff"], "application/vnd.nitf": [".ntf", ".nitf"] },
  })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Button size={"small"} onClick={open}>
        {t("button.addImage")}
      </Button>
    </div>
  )
}

const uploadToBucket = async (file: File) => {
  const { signedUrl, hashName } = await postSceneUpload(file.name, file.size.toString())
  try {
    await uploadBySignedUrl(signedUrl, file, "application/octet-stream")
    await patchSceneStatus(hashName, "COMPLETED")
  } catch (e) {
    await patchSceneStatus(hashName, "FAILED")
    throw e
  }
}
