import {
  getCurrencyLabel,
  initOrder,
  isInitialOrder,
  OE_ORDER,
  PATH_ANALYSIS,
  PATH_CREDIT,
  PATH_PRICING,
  SEARCH_TAB,
} from "@ovision-gis-frontend/shared"
import { Button, CheckCircleOutlined, Toast } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React from "react"
import { Trans, useTranslation } from "react-i18next"
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom"

import { MyCreditTab } from "../../user/my-credit/MyCredit"
import styles from "./PaymentResult.module.scss"

type Props = {}
function PaymentSuccess(props: Props) {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const myCreditTab: MyCreditTab = "RECHARGE"

  let order = initOrder()
  if (location.state) order = OE_ORDER in location.state ? location.state[OE_ORDER] : initOrder()

  if (isInitialOrder(order)) {
    Toast({ message: t("toast.order.invalid.error"), type: "error" })
    return <Navigate replace={true} to={PATH_PRICING} />
  }

  const handleUseItRightAwayButtonClick = () => {
    navigate(PATH_ANALYSIS, { replace: true })
  }

  return (
    <div className={styles.paymentResult}>
      <CheckCircleOutlined className={cn(styles.paymentIcon, styles.successIcon)} />
      <p className={styles.title}>{t("payment.success.title")}</p>
      <p className={styles.desc}>{t("payment.success.desc.first.label")}</p>
      <p className={styles.desc}>
        <Trans i18nKey={"payment.success.desc.second.label"}>
          desc...
          <Link replace={true} to={PATH_CREDIT + SEARCH_TAB + myCreditTab.toLowerCase()}>
            My Credit
          </Link>
        </Trans>
      </p>
      <div className={styles.section}>
        <div className={styles.horizontal}>
          <p className={styles.label}>{t("payment.success.credits.label")}</p>
          <p className={styles.data}>{order.creditAmount.toLocaleString()}</p>
        </div>
        <div className={styles.horizontal}>
          <p className={styles.label}>{t("payment.success.price.label")}</p>
          <p className={cn(styles.data, styles.primary)}>
            {getCurrencyLabel(order.currency) + order.amount.toLocaleString()}
          </p>
        </div>
      </div>
      <Button size={"xl"} type={"cta"} onClick={handleUseItRightAwayButtonClick}>
        {t("button.useItRightAway")}
      </Button>
    </div>
  )
}

export default PaymentSuccess
