import {
  Avatar,
  postSignOutAll,
  deleteAccount,
  LoaderData,
  PATH_HOME,
  useMember,
  useToken,
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  initMyAccount,
  type MyAccount as MyAccountType,
  ConfirmModal,
} from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import { Button, Toast } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Navigate, useLoaderData, useNavigate } from "react-router-dom"

import styles from "./MyAccount.module.scss"

export const MyAccountLoader = async () => {
  return useMember()
}

type Props = {
  className?: string
  isSettingsMenu?: boolean
  user?: MyAccountType
}

function MyAccount(props: Props) {
  const loaderData = useLoaderData() as LoaderData<typeof MyAccountLoader> | undefined
  const [user, setUser] = useState<MyAccountType>(loaderData?.user ? loaderData?.user : props.user ?? initMyAccount())
  const [isDisconnectModalVisible, setIsDisconnectModalVisible] = useState<boolean>(false)
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState<boolean>(false)
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const { token } = useToken()
  if (!token) return <Navigate replace={true} to={PATH_HOME} />

  const handleSignOutOfAllDevice = () => {
    const getLogoutAllAsync = async () => {
      try {
        await postSignOutAll()
        localStorage.removeItem(ACCESS_TOKEN_KEY)
        localStorage.removeItem(REFRESH_TOKEN_KEY)
        Toast({ message: t("toast.signOutOfAllDevice.success"), type: "success" })
        navigate(PATH_HOME, { replace: true })
      } catch (e) {
        Toast({ message: t("toast.signOutOfAllDevice.error"), type: "error" })
        captureException(e)
      }
    }
    void getLogoutAllAsync()
  }
  const handleDeleteAccount = () => {
    const getMemberDeactivateAsync = async () => {
      try {
        await deleteAccount()
        localStorage.removeItem(ACCESS_TOKEN_KEY)
        localStorage.removeItem(REFRESH_TOKEN_KEY)
        Toast({ message: t("toast.deleteAccount.success"), type: "success" })
        navigate(PATH_HOME, { replace: true })
      } catch (e) {
        Toast({ message: t("toast.deleteAccount.error"), type: "error" })
        captureException(e)
      }
    }
    void getMemberDeactivateAsync()
  }

  return (
    <section className={cn(styles.myAccount, props.className)}>
      <div className={styles.titleContainer}>
        <p className={cn(styles.title, props.isSettingsMenu && styles.isSettingsMenu)}>{t("myAccount.title")}</p>
        <p className={styles.desc}>{t("myAccount.welcome.label", { firstName: user.account.profile.givenName })}</p>
      </div>
      <div className={styles.profileContainer}>
        <Avatar
          className={styles.profile}
          size={"xl"}
          alt={"profile"}
          firstName={user.account.profile.givenName}
          profileImage={user.account.profile.picture}
        />
        <div className={styles.accountContainer}>
          <div className={styles.content}>
            <p className={styles.label}>{t("myAccount.email.label")}</p>
            <p className={styles.data}>{user.account.profile.email || "-"}</p>
          </div>
          <div className={styles.horizontal}>
            <div className={styles.content}>
              <p className={styles.label}>{t("myAccount.firstName.label")}</p>
              <p className={styles.data}>{user.account.profile.givenName || "-"}</p>
            </div>
            <div className={styles.content}>
              <p className={styles.label}>{t("myAccount.lastName.label")}</p>
              <p className={styles.data}>{user.account.profile.familyName || "-"}</p>
            </div>
          </div>
          <div className={styles.horizontal}>
            <div className={styles.content}>
              <p className={styles.label}>{t("myAccount.jobTitle.label")}</p>
              <p className={styles.data}>{user.account.profile.jobTitle || "-"}</p>
            </div>
            <div className={styles.content}>
              <p className={styles.label}>{t("myAccount.nameOfOrganization.label")}</p>
              <p className={styles.data}>{user.account.profile.organizationName || "-"}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.menuContainer}>
        <div className={styles.labelContainer}>
          <p className={styles.title}>{t("myAccount.signOutOfAllDevice.title")}</p>
          <p className={styles.desc}>{t("myAccount.signOutOfAllDevice.desc")}</p>
        </div>
        <Button type={"outlined"} onClick={() => setIsLogoutModalVisible(!isLogoutModalVisible)}>
          {t("button.signOut")}
        </Button>
        {isLogoutModalVisible && (
          <ConfirmModal
            title={t("modal.confirm.signOutOfAllDevice.title")}
            confirmText={t("button.signOut")}
            onCloseButtonClick={() => setIsLogoutModalVisible(false)}
            onConfirmButtonClick={handleSignOutOfAllDevice}
          />
        )}
      </div>
      <div className={styles.menuContainer}>
        <div className={styles.labelContainer}>
          <p className={styles.title}>{t("myAccount.deleteMyAccount.label")}</p>
          <p className={styles.desc}>{t("myAccount.deleteMyAccount.desc")}</p>
        </div>
        <Button
          className={styles.deleteBtn}
          type={"outlined"}
          onClick={() => setIsDisconnectModalVisible(!isDisconnectModalVisible)}
        >
          {t("button.delete")}
        </Button>
      </div>

      {isDisconnectModalVisible && (
        <ConfirmModal
          title={t("modal.confirm.deleteAccount.title")}
          confirmText={t("button.delete")}
          onCloseButtonClick={() => setIsDisconnectModalVisible(false)}
          onConfirmButtonClick={handleDeleteAccount}
        />
      )}
    </section>
  )
}
export default MyAccount
