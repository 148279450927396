import React from "react"

import { IconBase, IconProps } from "../../IconBase"

export function ReportOutlined(props: IconProps) {
  return (
    <IconBase viewBox={"0 0 91 91"} {...props}>
      <path d="M59.6429 11.375H31.3571L11.375 31.3571V59.6429L31.3571 79.625H59.6429L79.625 59.6429V31.3571L59.6429 11.375ZM72.0417 56.4958L56.4958 72.0417H34.5042L18.9583 56.4958V34.5042L34.5042 18.9583H56.4958L72.0417 34.5042V56.4958Z" />
      <path d="M45.5 64.4583C47.5941 64.4583 49.2917 62.7607 49.2917 60.6667C49.2917 58.5726 47.5941 56.875 45.5 56.875C43.4059 56.875 41.7083 58.5726 41.7083 60.6667C41.7083 62.7607 43.4059 64.4583 45.5 64.4583Z" />
      <path d="M41.7083 26.5417H49.2917V53.0833H41.7083V26.5417Z" />
    </IconBase>
  )
}
