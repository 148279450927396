import {
  initOrder,
  isInitialOrder,
  OE_ORDER,
  OE_PLAN_ID,
  OE_REFERRER,
  Order,
  PATH_PRICING,
  PATH_PRICING_PAYMENT,
  postOrders,
  postOrdersCancel,
  ReportOutlined,
} from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import { Button, Toast } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Navigate, useLocation, useNavigate } from "react-router-dom"

import styles from "./PaymentResult.module.scss"
import { getTossRequestPaymentFailErrorMsg, isTossPaymentError } from "./TossUtil"

type Props = {}
function PaymentFail(props: Props) {
  const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false)
  const [isOrderRequesting, setIsOrderRequesting] = useState<boolean>(false)
  const [reason, setReason] = useState<string>("")
  const [order, setOrder] = useState<Order>(initOrder)
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const searchParams = new URLSearchParams(location.search)
  const code = searchParams.get("code")
  const message = searchParams.get("message")
  const orderId = searchParams.get("orderId")

  if (!code || !message || !orderId) {
    Toast({ message: t("toast.order.invalid.error"), type: "error" })
    return <Navigate replace={true} to={PATH_PRICING} />
  } else {
    if (!isPageLoaded) {
      const postOrdersCancelAsync = async () => {
        try {
          const _cancel = await postOrdersCancel(orderId, { data: { code, message } })
          setOrder(_cancel)
        } catch (e) {
          captureException(e)
        }
      }
      void postOrdersCancelAsync()
      if (isTossPaymentError(code)) setReason(getTossRequestPaymentFailErrorMsg(code))
      setIsPageLoaded(true)
    }
  }

  const handleTryAgainButtonClick = () => {
    if (isInitialOrder(order)) return
    const planId = `${(order.products && order.products.at(-1)?.productName.planId) ?? -1}`
    if (planId === "-1") {
      Toast({ message: t("toast.order.error"), type: "error" })
      return
    }
    const postOrderAsync = async () => {
      setIsOrderRequesting(true)
      try {
        const newOrder = await postOrders({ planId })
        navigate(PATH_PRICING_PAYMENT, {
          state: { [OE_PLAN_ID]: planId, [OE_ORDER]: newOrder, [OE_REFERRER]: location.pathname },
        })
      } catch (e) {
        Toast({ message: t("toast.order.error"), type: "error" })
        captureException(e)
      } finally {
        setIsOrderRequesting(false)
      }
    }
    void postOrderAsync()
  }

  return (
    <div className={styles.paymentResult}>
      <ReportOutlined className={cn(styles.paymentIcon, styles.failIcon)} />
      <p className={styles.title}>{t("payment.fail.title")}</p>
      <p className={styles.desc}>{t("payment.fail.desc.first.label")}</p>
      <p className={styles.desc}>{t("payment.fail.desc.second.label")}</p>
      {reason && (
        <div className={styles.section}>
          <div className={styles.vertical}>
            <p className={styles.label}>{t("payment.fail.reasonForFailure.label")}</p>
            <p className={styles.data}>{reason}</p>
          </div>
        </div>
      )}
      <Button size={"xl"} type={"cta"} loading={isOrderRequesting} onClick={handleTryAgainButtonClick}>
        {t("button.tryAgain")}
      </Button>
    </div>
  )
}

export default PaymentFail
