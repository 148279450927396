import { t } from "i18next"

import AirplaneImg from "../../asset/icons/solid/Airplane.png"
import AutomobileImg from "../../asset/icons/solid/Automobile.png"
import LandCoverImg from "../../asset/icons/solid/LandCover.png"
import MotorwayImg from "../../asset/icons/solid/Motorway.png"
import OfficeBuildingImg from "../../asset/icons/solid/OfficeBuilding.png"
import SEImg from "../../asset/icons/solid/SE.png"
import ShipImg from "../../asset/icons/solid/Ship.png"
import AircraftDetectionImg from "../../asset/images/AircraftDetection.png"
import BuildingDamageAssessmentImg from "../../asset/images/BuildingDamageAssessment.png"
import BuildingSegmentationImg from "../../asset/images/BuildingSegmentation.png"
import VehicleDetectionImg from "../../asset/images/CarDetection.png"
import FloodDamageAssessmentImg from "../../asset/images/FloodDamageAssessment.png"
import MapboxImg from "../../asset/images/Mapbox.png"
import RoadSegmentationImg from "../../asset/images/RoadSegmentation.png"
import SuperEnhancementImg from "../../asset/images/SuperEnhancement.png"
import VesselDetectionImg from "../../asset/images/VesselDetection.png"
import WaybackImg from "../../asset/images/Wayback.png"
import { AdminInferenceStatusType } from "../../hooks/data/adminInferenceHistoriesDto"
import { AiPackType } from "../../hooks/data/aiPackInfosDto"
import { AnalysisStatusType } from "../../hooks/data/inferenceJobInfosDto"
import { ProjectStatusType, ProjectType } from "../../hooks/data/projectDto"
import { ProviderType } from "../../hooks/data/providerDto"
import { ServiceType } from "../../hooks/data/serviceDto"

export const getProjectTypeLabel = (type?: ProjectType, ko: boolean = false) => {
  if (type === "REALTIME") return ko ? "버킷 모니터링 분석" : t("createProject.bucketAnalysis.label")
  else if (type === "SINGLE") return ko ? "단일 분석" : t("createProject.singleAnalysis.label")
  else return ""
}

export const getServiceLabel = (service?: ServiceType) => {
  if (service === "IMAGE_IMPROVEMENT") return t("service.imageImprovement.title")
  else if (service === "OBJECT_DETECTION") return t("service.objectDetection.title")
  else if (service === "INFRA") return t("service.infrastructure.title")
  else return ""
}

export const getAiPack = (
  type?: AiPackType,
): { label: string; icon: string; desc: string; catchphrase: string; heroImage: string } => {
  if (type === "AircraftDetection") {
    return {
      label: t("aiPack.aircraftDetection.title"),
      icon: AirplaneImg,
      desc: t("tooltip.aiPack.aircraftDetection"),
      catchphrase: t("aiPack.aircraftDetection.catchphrase"),
      heroImage: AircraftDetectionImg,
    }
  } else if (type === "VesselDetection") {
    return {
      label: t("aiPack.vesselDetection.title"),
      icon: ShipImg,
      desc: t("tooltip.aiPack.vesselDetection"),
      catchphrase: t("aiPack.vesselDetection.catchphrase"),
      heroImage: VesselDetectionImg,
    }
  } else if (type === "VehicleDetection") {
    return {
      label: t("aiPack.vehicleDetection.title"),
      icon: AutomobileImg,
      desc: t("tooltip.aiPack.vehicleDetection"),
      catchphrase: t("aiPack.vehicleDetection.catchphrase"),
      heroImage: VehicleDetectionImg,
    }
  } else if (type === "BuildingSegmentation") {
    return {
      label: t("aiPack.buildingSegmentation.title"),
      icon: OfficeBuildingImg,
      desc: t("tooltip.aiPack.buildingSegmentation"),
      catchphrase: t("aiPack.buildingSegmentation.catchphrase"),
      heroImage: BuildingSegmentationImg,
    }
  } else if (type === "RoadSegmentation") {
    return {
      label: t("aiPack.roadSegmentation.title"),
      icon: MotorwayImg,
      desc: t("tooltip.aiPack.roadSegmentation"),
      catchphrase: t("aiPack.roadSegmentation.catchphrase"),
      heroImage: RoadSegmentationImg,
    }
  } else if (type === "SuperEnhancement") {
    return {
      label: t("aiPack.superEnhancement.title"),
      icon: SEImg,
      desc: t("tooltip.aiPack.superEnhancement"),
      catchphrase: t("aiPack.superEnhancement.catchphrase"),
      heroImage: SuperEnhancementImg,
    }
  } else if (type === "LandCoverSegmentation") {
    return {
      label: t("aiPack.landCoverSegmentation.title"),
      icon: LandCoverImg,
      desc: t("tooltip.aiPack.landCoverSegmentation"),
      catchphrase: "",
      heroImage: "",
    }
  } else {
    return { label: "", icon: "", desc: "", catchphrase: "", heroImage: "" }
  }
}

export const getProjectStatus = (status?: ProjectStatusType) => {
  switch (status) {
    case "PROCESSING":
      return t("projectInfo.status.inProgress.label")
    case "PAUSED":
      return t("projectInfo.status.paused.label")
    case "CLOSED":
      return t("projectInfo.status.closed.label")
    default:
      return ""
  }
}

export const getAnalysisStatusLabel = (status?: AnalysisStatusType) => {
  switch (status) {
    case "IDLE":
    case "SCENE_DOWNLOADING":
    case "SCENE_DOWNLOADED":
    case "SCHEDULE_WAITING":
      return "WAITING"
    case "PROCESSING":
      return "PROCESSING"
    case "POSTPROCESSING":
      return "POSTPROCESSING"
    case "ERROR":
    case "SCENE_DOWNLOAD_ERROR":
      return "ERROR"
    case "COMPLETED":
      return "COMPLETED"
    default:
      return ""
  }
}
export const getAdminInferenceStatusLabel = (status?: AdminInferenceStatusType) => {
  if (status === "SKIP" || status === "CANCELED") return "CANCELED"
  else return getAnalysisStatusLabel(status)
}

export const getProvider = (type?: ProviderType) => {
  if (type === "MAPBOX")
    return { label: t("analysis.source.mapbox.label"), img: MapboxImg, desc: t("analysis.source.mapbox.desc") }
  else if (type === "WAYBACK")
    return { label: t("analysis.source.wayback.label"), img: WaybackImg, desc: t("analysis.source.wayback.desc") }
  else return { label: "", img: "", desc: "" }
}
export const getProviderFromUrl = (url: string): ProviderType => {
  if (/wayback/.test(url)) return "WAYBACK"
  else if (/mapbox/.test(url)) return "MAPBOX"
  else return ""
}

export const getValidProjectName = (value: string) => {
  const reg = /[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gim
  return value.replace(reg, "").slice(0, 50)
}
