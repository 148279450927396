import React from "react"
import { useTranslation } from "react-i18next"

import PrivacyPolicyEnglish from "./PrivacyPolicyEnglish"
import PrivacyPolicyKorean from "./PrivacyPolicyKorean"

type Props = {}
function PrivacyPolicy(props: Props) {
  const { i18n } = useTranslation()

  return i18n.language === "ko" ? <PrivacyPolicyKorean /> : <PrivacyPolicyEnglish />
}

export default PrivacyPolicy
