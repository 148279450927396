import { ProviderType } from "./providerDto"
import { CloudType } from "./syncStorageDto"

export type SourceType = "CLOUD" | "MY_IMAGES" | "PROVIDERS" | ""
export type SpecificSourceType =
  | ProviderType
  | Extract<SourceType, "MY_IMAGES">
  | Extract<CloudType, "GCP" | "AWS" | "AZURE">
  | ""

export const isImageSourceValid = (source: string): source is Extract<SourceType, "MY_IMAGES"> => {
  return source === "MY_IMAGES"
}
export const isSpecificSourceValid = (source: string | null): source is SpecificSourceType => {
  return (
    source === "MAPBOX" ||
    source === "WAYBACK" ||
    source === "MY_IMAGES" ||
    source === "GCP" ||
    source === "AWS" ||
    source === "AZURE"
  )
}
export const isSourceValid = (source: string): source is SourceType => {
  return source === "CLOUD" || source === "MY_IMAGES" || source === "PROVIDERS"
}
const convertToSourceType = (source: SpecificSourceType): SourceType => {
  if (source === "MAPBOX" || source === "WAYBACK") return "PROVIDERS"
  else if (source === "GCP" || source === "AWS" || source === "AZURE") return "CLOUD"
  return source
}

export type Source = {
  type: SourceType
  specificType: SpecificSourceType
  id: string | null
  paths?: string[] | null
}
export type SourceDTO = Omit<Source, "type" | "specificType"> & {
  type: string
}

export const sourceFromDto = (dto: SourceDTO): Source => {
  const _specificType = isSpecificSourceValid(dto.type) ? dto.type : ""
  const _type = convertToSourceType(_specificType)

  return {
    type: _type,
    specificType: _specificType,
    id: dto.id,
    paths: dto.paths,
  }
}

export const initSource = (): Source => {
  return {
    type: "",
    specificType: "",
    id: "",
    paths: [],
  }
}
