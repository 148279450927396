import {
  CurrencyType,
  Fallback,
  OE_ORDER,
  PATH_PRICING,
  PATH_PRICING_PAYMENT_FAIL,
  PATH_PRICING_PAYMENT_SUCCESS,
  postOrdersConfirm,
} from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import { Toast } from "@SIAnalytics/ovision-design-system"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Navigate, useLocation, useNavigate } from "react-router-dom"

type Props = {}
function PaymentAuthorize(props: Props) {
  const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false)
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const searchParams = new URLSearchParams(location.search)
  const paymentKey = searchParams.get("paymentKey")
  const orderId = searchParams.get("orderId")
  const amount = parseInt(searchParams.get("amount") ?? "0")

  if (!paymentKey || !orderId || !amount) {
    Toast({ message: t("toast.order.invalid.error"), type: "error" })
    return <Navigate replace={true} to={PATH_PRICING} />
  } else {
    if (!isPageLoaded) {
      const currency: CurrencyType = "USD" // @NOTE: 현재 PayPal만 지원하여 USD로 고정
      const postOrderConfirmAsync = async () => {
        try {
          const _confirm = await postOrdersConfirm(orderId, { paymentKey, amount, currency })
          navigate(PATH_PRICING_PAYMENT_SUCCESS + location.search, { replace: true, state: { [OE_ORDER]: _confirm } })
        } catch (e) {
          captureException(e)
          const _failSearchParams = new URLSearchParams()
          _failSearchParams.set("code", "PAY_PROCESS_ABORTED")
          _failSearchParams.set("message", t("toss.requestPaymentFail.payProcessAborted.error"))
          _failSearchParams.set("orderId", orderId)
          navigate(PATH_PRICING_PAYMENT_FAIL + "?" + _failSearchParams.toString(), { replace: true })
        }
      }
      void postOrderConfirmAsync()
      setIsPageLoaded(true)
    }
  }

  return <Fallback />
}

export default PaymentAuthorize
