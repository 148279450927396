export type SyncStorageStatus =
  | "PENDING"
  | "SUCCESS"
  | "NOT_FOUND"
  | "EXPIRED_CREDENTIALS"
  | "INVALID_CREDENTIALS"
  | "PERMISSION_ERROR"
  | "FAILED"
  | ""

export type CloudType = "SIA_CLOUD" | "GCP" | "AWS" | "AZURE" | ""

export const isCloudTypeValid = (cloudType: string): cloudType is CloudType => {
  return cloudType === "GCP" || cloudType === "AWS" || cloudType === "AZURE"
}

export const isSyncStorageStatusValid = (status: string | null): status is SyncStorageStatus => {
  return (
    status === "PENDING" ||
    status === "SUCCESS" ||
    status === "NOT_FOUND" ||
    status === "EXPIRED_CREDENTIALS" ||
    status === "INVALID_CREDENTIALS" ||
    status === "PERMISSION_ERROR" ||
    status === "FAILED"
  )
}

export type SyncStorageRequestType = {
  email?: string
  cloudType: Omit<CloudType, "">
  bucketName: string
  credentials: string
}
export type SyncStorageInquiryRequestType = {
  firstName: string
  lastName: string
  groupName: string
  contactNumber: string
  businessEmail: string
  preferredContactMethod: string
  comments?: string
}

export type SyncStorage = {
  id: string
  name: string
  status: SyncStorageStatus
  cloudType: CloudType
  createdTime: Date | ""
  storageCreatedTime: Date | ""
  storageUpdatedTime: Date | ""
  monitoringPathList: string[]
}

export type SyncStorages = {
  syncStorages: SyncStorage[]
  totalCount: number
}

export type SyncStoragesDto = {
  syncStorages: SyncStorageDto[]
  totalCount: number
}

export type SyncStorageDto = Pick<SyncStorage, "id" | "name"> & {
  status: string | null
  cloudType: string
  createdTime: string | null
  storageCreatedTime: string | null
  storageUpdatedTime: string | null
  monitoringPathList: string[] | null
}

export const syncStorageFromDto = (dto: SyncStorageDto): SyncStorage => {
  const _status = isSyncStorageStatusValid(dto.status) ? dto.status : ""
  const _cloudType = isCloudTypeValid(dto.cloudType) ? dto.cloudType : ""

  return {
    id: dto.id,
    name: dto.name,
    status: _status,
    cloudType: _cloudType ?? "",
    createdTime: dto.createdTime ? new Date(dto.createdTime) : "",
    storageCreatedTime: dto.storageCreatedTime ? new Date(dto.storageCreatedTime) : "",
    storageUpdatedTime: dto.storageUpdatedTime ? new Date(dto.storageUpdatedTime) : "",
    monitoringPathList: dto.monitoringPathList ?? [],
  }
}

export const syncStoragesFromDto = (dto: SyncStoragesDto): SyncStorages => {
  const _syncStorages = dto.syncStorages.map((syncStorage) => syncStorageFromDto(syncStorage))

  return {
    syncStorages: _syncStorages,
    totalCount: dto.totalCount,
  }
}

export const isInitialSyncStorage = (syncStorage: SyncStorage) => {
  return syncStorage.id === "-1"
}
export const initSyncStorage = (): SyncStorage => {
  return {
    id: "-1",
    status: "",
    cloudType: "",
    storageCreatedTime: "",
    storageUpdatedTime: "",
    monitoringPathList: [],
    name: "",
    createdTime: "",
  }
}

export const initSyncStorages = (): SyncStorages => {
  return { syncStorages: [], totalCount: 0 }
}
