import { getPredictions, isError, LocationOnOutlined, SearchPredictionType } from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import { SearchOutlined } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./SearchMap.module.scss"

type Props = {
  setCenter: (center: number[]) => void
}

export default function SearchMap(props: Props) {
  const [searchKeyword, setSearchKeyword] = useState<string>("")
  const [predictions, setPredictions] = useState<SearchPredictionType[]>([])
  const [currentIndex, setCurrentIndex] = useState<number>(-1)
  const [hoveredIndex, setHoveredIndex] = useState<number>(-1)

  const { t } = useTranslation()

  useEffect(() => {
    const setPredictionsAsync = async () => {
      try {
        const response = await getPredictions(searchKeyword)
        if (!isError(response)) {
          setPredictions(response)
          setCurrentIndex(0)
        }
      } catch (e) {
        captureException(e)
      }
    }

    setPredictionsAsync()
  }, [searchKeyword])

  const onSearchKeywordChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(evt.target.value)
  }

  const onSearchKeyboardDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.nativeEvent.isComposing || predictions.length === 0) return

    if (evt.key === "ArrowDown") {
      setCurrentIndex((prev) => (prev + 1) % predictions.length)
    } else if (evt.key === "ArrowUp") {
      currentIndex === -1
        ? setCurrentIndex(predictions.length - 1)
        : setCurrentIndex((prev) => (prev - 1 + predictions.length) % predictions.length)
    } else if (evt.key === "Escape") {
      setPredictions([])
      setCurrentIndex(-1)
      setHoveredIndex(-1)
    } else if (evt.key === "Enter") {
      props.setCenter(predictions[currentIndex].center)
      setPredictions([])
      setCurrentIndex(-1)
      setHoveredIndex(-1)
    }
  }

  const onPredictionClick = (center: number[]) => {
    props.setCenter(center)
    setPredictions([])
    setCurrentIndex(-1)
    setHoveredIndex(-1)
  }

  return (
    <div className={styles.searchContainer}>
      <div className={styles.inputContainer}>
        <SearchOutlined />
        <input
          placeholder={t("search.placeholder.label") ?? ""}
          value={searchKeyword}
          onBlur={() => setPredictions([])}
          onChange={onSearchKeywordChange}
          onKeyDown={onSearchKeyboardDown}
        />
      </div>
      {predictions.length > 0 && (
        <ul className={styles.searchResult} onMouseLeave={() => setHoveredIndex(-1)}>
          {predictions.map((prediction, index) => (
            <li
              className={cn(
                styles.listItem,
                hoveredIndex === index && styles.hovered,
                currentIndex === index && styles.selected,
              )}
              key={prediction.placeName + index}
              onMouseDown={() => onPredictionClick(prediction.center)}
              onMouseEnter={() => setHoveredIndex(index)}
            >
              <LocationOnOutlined className={styles.locationOutlined} />
              <div className={styles.title}>{prediction.placeName}</div>
              <div className={styles.subtext}>{prediction.subText}</div>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
