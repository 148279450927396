import { PATH_PRICING } from "@ovision-gis-frontend/shared"
import { CloseOutlined, IconButton } from "@SIAnalytics/ovision-design-system"
import React from "react"
import { Outlet, useNavigate } from "react-router-dom"

import styles from "./PaymentLayout.module.scss"

type Props = {}
function PaymentLayout(props: Props) {
  const navigate = useNavigate()

  return (
    <main className={styles.paymentLayout}>
      <Outlet />
      <IconButton
        wrapperClassName={styles.closeBtn}
        type={"square"}
        icon={<CloseOutlined />}
        onClick={() => navigate(PATH_PRICING, { replace: true })}
      />
    </main>
  )
}

export default PaymentLayout
