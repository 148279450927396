import { getCurrencyLabel, Refund, Modal } from "@ovision-gis-frontend/shared"
import { Button } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./RefundModal.module.scss"

type Props = {
  className?: string
  refund?: Refund
  closeModal?: React.MouseEventHandler<HTMLElement>
  onRefundButtonClick?: React.MouseEventHandler<HTMLElement>
}
function RefundModal(props: Props) {
  const { t } = useTranslation()

  return (
    <Modal
      className={cn(styles.refundModal, props.className)}
      size={"small"}
      title={t("myCredit.table.refund.label")}
      content={
        <>
          <div className={styles.paymentInfoContainer}>
            <p className={styles.subTitle}>{t("modal.refund.paymentInfo.label")}</p>
            <div className={styles.horizontal}>
              <span className={styles.label}>{t("modal.refund.creditRecharge.label")}</span>
              <span className={styles.data}>{props.refund?.orderCredit.toLocaleString()}</span>
            </div>
            <div className={styles.horizontal}>
              <span className={styles.label}>{t("modal.refund.paymentAmount.label")}</span>
              <span className={styles.data}>
                {getCurrencyLabel(props.refund?.currency) + props.refund?.orderPrice.toLocaleString()}
              </span>
            </div>
            <div className={styles.horizontal}>
              <span className={styles.label}>{t("modal.refund.refundAmount.label")}</span>
              <span className={cn(styles.data, styles.primary)}>
                {getCurrencyLabel(props.refund?.currency) + props.refund?.refundPrice.toLocaleString()}
              </span>
            </div>
            <hr className={styles.divider} />
          </div>
          <div className={styles.guide}>
            <li className={styles.strong}>{t("modal.refund.guide.strong.label")}</li>
            {t("modal.refund.guide.sub.label") !== "INIT_REFUND_SUB" && <li>{t("modal.refund.guide.sub.label")}</li>}
          </div>
        </>
      }
      footer={{
        right: (
          <>
            <Button size={"small"} type={"outlined"} onClick={props.closeModal}>
              {t("button.cancel")}
            </Button>
            <Button size={"small"} type={"primary"} onClick={props.onRefundButtonClick}>
              {t("button.refund")}
            </Button>
          </>
        ),
      }}
      onCloseButtonClick={props.closeModal}
    />
  )
}
export default RefundModal
