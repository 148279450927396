import React from "react"

export type IconProps = React.HTMLProps<HTMLSpanElement> & {}

type Props = IconProps & {
  viewBox: string
  children?: React.ReactNode
}

export function IconBase(props: Props) {
  const { viewBox, children, ...rest } = props
  return (
    <span role={"img"} {...rest}>
      <svg viewBox={viewBox} width={"1em"} height={"1em"} fill={"currentColor"} focusable={false} aria-hidden={true}>
        {children}
      </svg>
    </span>
  )
}
