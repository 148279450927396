import { PATH_PROJECT_INFO, Project, initProject, COORDINATE_DAEJEON } from "@ovision-gis-frontend/shared"
import { View } from "ol"
import React, { SetStateAction, useEffect, useMemo, useRef, useState } from "react"
import { Outlet, useLocation, useOutletContext } from "react-router-dom"

import { serviceLayoutOutletContextType, useServiceLayoutOutletContext } from "../service-layout/ServiceLayout"

export type projectOutletContextType = serviceLayoutOutletContextType & {
  selectedProject: Project
  setSelectedProject: React.Dispatch<SetStateAction<Project>>
  isProjectEditing: boolean
  setIsProjectEditing: React.Dispatch<SetStateAction<boolean>>
  view: View
}
export const useProjectOutletContext = () => {
  return useOutletContext<projectOutletContextType>()
}

function ProjectContext() {
  const { setIsSettingsVisible, isSettingsVisible, setSettingsMenu, settingsMenu } = useServiceLayoutOutletContext()
  const [isProjectEditing, setIsProjectEditing] = useState<boolean>(false)
  const [selectedProject, setSelectedProject] = useState<Project>(initProject)
  const view = useRef<View>(new View({ center: COORDINATE_DAEJEON, zoom: 0 }))
  const location = useLocation()

  useEffect(() => {
    if (location.pathname !== PATH_PROJECT_INFO) setIsProjectEditing(false)
  }, [location.pathname])

  const outletContext = useMemo(
    () => ({
      isSettingsVisible,
      setIsSettingsVisible,
      settingsMenu,
      setSettingsMenu,
      selectedProject,
      setSelectedProject,
      isProjectEditing,
      setIsProjectEditing,
      view: view.current,
    }),
    [isSettingsVisible, setIsSettingsVisible, settingsMenu, setSettingsMenu, selectedProject, isProjectEditing],
  )

  return <Outlet context={outletContext} />
}

export default ProjectContext
