import { PATH_SIGN_IN, PATH_ANALYSIS, useToken } from "@ovision-gis-frontend/shared"
import AirplaneImg from "@ovision-gis-frontend/shared/src/asset/icons/solid/Airplane.png"
import ArticulatedLorryImg from "@ovision-gis-frontend/shared/src/asset/icons/solid/ArticulatedLorry.png"
import BuildingConstructionImg from "@ovision-gis-frontend/shared/src/asset/icons/solid/BuildingConstruction.png"
import GlobeWithMeridiansImg from "@ovision-gis-frontend/shared/src/asset/icons/solid/GlobeWithMeridians.png"
import OfficeBuildingImg from "@ovision-gis-frontend/shared/src/asset/icons/solid/OfficeBuilding.png"
import ImageImprovementImg from "@ovision-gis-frontend/shared/src/asset/icons/solid/SE.png"
import ShipImg from "@ovision-gis-frontend/shared/src/asset/icons/solid/Ship.png"
import WaterWaveImg from "@ovision-gis-frontend/shared/src/asset/icons/solid/WaterWave.png"
import AircraftDetectionImg from "@ovision-gis-frontend/shared/src/asset/images/AircraftDetection.png"
import AircraftDetection2Img from "@ovision-gis-frontend/shared/src/asset/images/AircraftDetection2.png"
import BuildingDamageAssessmentImg from "@ovision-gis-frontend/shared/src/asset/images/BuildingDamageAssessment.png"
import BuildingSegmentationImg from "@ovision-gis-frontend/shared/src/asset/images/BuildingSegmentation.png"
import VehicleDetectionImg from "@ovision-gis-frontend/shared/src/asset/images/CarDetection.png"
import FloodDamageAssessmentImg from "@ovision-gis-frontend/shared/src/asset/images/FloodDamageAssessment.png"
import RadarGlobeImg from "@ovision-gis-frontend/shared/src/asset/images/GlobeWithoutRadar.png"
import RadarEffectImg from "@ovision-gis-frontend/shared/src/asset/images/RadarEffect.png"
import RadarGlobeObjectsImg from "@ovision-gis-frontend/shared/src/asset/images/RadarGlobeObjects.png"
import RoadSegmentationImg from "@ovision-gis-frontend/shared/src/asset/images/RoadSegmentation.png"
import SuperEnhancementImg from "@ovision-gis-frontend/shared/src/asset/images/SuperEnhancement.png"
import VesselDetectionImg from "@ovision-gis-frontend/shared/src/asset/images/VesselDetection.png"
import { Button } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"

import AiPackParagraph from "./AiPackParagraph"
import AiPackTitle from "./AiPackTitle"
import AiPackTitleImage from "./AiPackTitleImage"
import Banner from "./Banner"
import styles from "./GeoAiPack.module.scss"

export const geoAiPackTitleId = {
  objectDetection: "#object-detection",
  infrastructure: "#infrastructure",
  changeDetection: "#change-detection",
  imageImprovement: "#image-improvement",
}

function GeoAiPack() {
  const [selectedSegmentation, setSelectedSegmentation] = useState<"Building" | "Road">("Building")
  const [selectedChangeDetection, setSelectedChangeDetection] = useState<"BDA" | "FDA">("BDA")
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { token } = useToken()
  const location = useLocation()

  useEffect(() => {
    if (location.hash) document.getElementById(location.hash)?.scrollIntoView({ behavior: "smooth" })
    else document.getElementById("geoAiPack")?.scrollIntoView({ behavior: "auto" })
  }, [location])

  return (
    <section className={styles.geoAiPack} id={"geoAiPack"}>
      <div className={styles.visual}>
        <div className={styles.slogan}>
          <h1>{t("geoAiPack.slogan.title")}</h1>
          <p>{t("geoAiPack.slogan.desc")}</p>
          <div className={styles.btnWrapper}>
            <Button
              className={styles.primaryBtn}
              size={"xl"}
              onClick={() => navigate(!token ? PATH_SIGN_IN : PATH_ANALYSIS)}
            >
              {t("button.getStartedNow")}
            </Button>
          </div>
        </div>
        <div className={styles.radarGlobeWrapper}>
          <img className={styles.radarGlobe} alt={"image of radar globe"} src={RadarGlobeImg} />
          <img className={styles.radarEffect} alt={"image of radar"} src={RadarEffectImg} />
          <img className={styles.radarObjects} alt={"image of objects on the globe"} src={RadarGlobeObjectsImg} />
        </div>
      </div>
      <div className={styles.detectionBackground} />
      <div className={styles.segmentationBackground} />
      <article className={styles.aiPack}>
        <div className={cn(styles.superEnhancement, styles.leftArea)}>
          <AiPackTitleImage
            className={styles.leftTitleImg}
            caption={t("aiPack.superEnhancement.title")}
            img={SuperEnhancementImg}
            imgAlt={"image of building super enhancement"}
          />
        </div>
        <div className={cn(styles.superEnhancement, styles.rightArea)}>
          <AiPackTitle
            className={styles.aiPackTitle}
            id={geoAiPackTitleId.imageImprovement}
            title={t("service.imageImprovement.title")}
            index={"01"}
          />
          <AiPackParagraph
            className={styles.aiPackParagraph}
            title={t("aiPack.superEnhancement.title")}
            desc={t("aiPack.superEnhancement.desc")}
            icon={ImageImprovementImg}
            selectable={true}
          />
        </div>
        <div className={cn(styles.detection, styles.leftArea)}>
          <AiPackTitle
            className={styles.aiPackTitle}
            id={geoAiPackTitleId.objectDetection}
            title={t("service.objectDetection.title")}
            index={"02"}
          />
          <AiPackParagraph
            className={cn(styles.aiPackParagraph, styles.aircraftDetection)}
            title={t("aiPack.aircraftDetection.title")}
            desc={t("aiPack.aircraftDetection.desc")}
            icon={AirplaneImg}
            img={AircraftDetection2Img}
            imgAlt={"image of aircraft detection2"}
          />
          <AiPackParagraph
            className={cn(styles.aiPackParagraph, styles.vesselDetection)}
            title={t("aiPack.vesselDetection.title")}
            desc={t("aiPack.vesselDetection.desc")}
            icon={ShipImg}
            img={VesselDetectionImg}
            imgAlt={"image of vessel detection"}
          />
        </div>
        <div className={cn(styles.detection, styles.rightArea)}>
          <AiPackTitleImage
            className={styles.rightTitleImg}
            caption={t("aiPack.aircraftDetection.title")}
            img={AircraftDetectionImg}
            imgAlt={"image of aircraft detection"}
          />
          <AiPackParagraph
            className={cn(styles.aiPackParagraph, styles.vehicleDetection)}
            title={t("aiPack.vehicleDetection.title")}
            desc={t("aiPack.vehicleDetection.desc")}
            icon={ArticulatedLorryImg}
            img={VehicleDetectionImg}
            imgAlt={"image of vehicle detection"}
          />
        </div>
        <div className={cn(styles.segmentation, styles.leftArea)}>
          <AiPackTitleImage
            className={cn(styles.rightTitleImg, selectedSegmentation === "Road" ? styles.front : styles.back)}
            caption={t("aiPack.roadSegmentation.title")}
            img={RoadSegmentationImg}
            imgAlt={"image of road segmentation"}
          />
          <AiPackTitleImage
            className={cn(styles.leftTitleImg, selectedSegmentation === "Building" ? styles.front : styles.back)}
            caption={t("aiPack.buildingSegmentation.title")}
            img={BuildingSegmentationImg}
            imgAlt={"image of building segmentation"}
          />
        </div>
        <div className={cn(styles.segmentation, styles.rightArea)}>
          <AiPackTitle
            className={styles.aiPackTitle}
            id={geoAiPackTitleId.infrastructure}
            title={t("service.infrastructure.title")}
            index={"03"}
          />
          <AiPackParagraph
            className={styles.aiPackParagraph}
            title={t("aiPack.buildingSegmentation.title")}
            active={selectedSegmentation === "Building"}
            desc={t("aiPack.buildingSegmentation.desc")}
            icon={OfficeBuildingImg}
            selectable={true}
            onClick={() => setSelectedSegmentation("Building")}
          />
          <AiPackParagraph
            className={styles.aiPackParagraph}
            title={t("aiPack.roadSegmentation.title")}
            active={selectedSegmentation === "Road"}
            desc={t("aiPack.roadSegmentation.desc")}
            icon={GlobeWithMeridiansImg}
            selectable={true}
            onClick={() => setSelectedSegmentation("Road")}
          />
        </div>
        <div className={cn(styles.changeDetection, styles.leftArea)}>
          <AiPackTitle
            className={styles.aiPackTitle}
            id={geoAiPackTitleId.changeDetection}
            title={t("service.changeDetection.title")}
            comingSoon={true}
            index={"04"}
          />
          <AiPackParagraph
            className={styles.aiPackParagraph}
            title={t("aiPack.buildingDamageAssessment.title")}
            /*active={selectedChangeDetection === "BDA"}*/
            desc={t("aiPack.buildingDamageAssessment.desc")}
            icon={BuildingConstructionImg}
            selectable={true}
            onClick={() => setSelectedChangeDetection("BDA")}
          />
          {/*<AiPackParagraph @NOTE: FDA Dropped
            className={styles.aiPackParagraph}
            title={t("aiPack.floodDamageAssessment.title")}
            active={selectedChangeDetection === "FDA"}
            desc={t("aiPack.floodDamageAssessment.desc")}
            icon={WaterWaveImg}
            selectable={true}
            onClick={() => setSelectedChangeDetection("FDA")}
          />*/}
        </div>
        <div className={cn(styles.changeDetection, styles.rightArea)}>
          <AiPackTitleImage
            className={cn(styles.rightTitleImg, selectedChangeDetection === "BDA" ? styles.front : styles.back)}
            caption={t("aiPack.buildingDamageAssessment.title")}
            img={BuildingDamageAssessmentImg}
            imgAlt={"image of building damage assessment"}
          />
          {/*<AiPackTitleImage  @NOTE: FDA Dropped
            className={cn(styles.leftTitleImg, selectedChangeDetection === "FDA" ? styles.font : styles.back)}
            caption={t("aiPack.floodDamageAssessment.title")}
            img={FloodDamageAssessmentImg}
            imgAlt={"image of flood damage assessment"}
          />*/}
        </div>
      </article>
      <Banner className={styles.banner} />
    </section>
  )
}
export default GeoAiPack
