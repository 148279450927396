import {
  deleteInferences,
  EmptyStatus,
  FilterOutlined,
  getProjectsInferenceJobs,
  InferenceJobInfo,
  InferenceJobInfos,
  initInferenceJobInfos,
  isError,
  isInitialProject,
  Loading,
  Project,
  Search,
  useInterval,
  usePageVisibility,
  GsdRange,
  getJobGsdRange,
  getJobSatellites,
  JobsQueryType,
  ConfirmModal,
} from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import { ArrowDownwardOutlined, ArrowUpwardOutlined, Dropdown, IconButton, Toast } from "@SIAnalytics/ovision-design-system"
import { RangePickerValue } from "@SIAnalytics/ovision-design-system/build/src/component/data-entry/picker/range-picker/RangePicker"
import cn from "classnames"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "../../my-images/MyImages.module.scss"
import Panel from "../../service-layout/Panel"
import PanelPagination from "../../service-layout/PanelPagination"
import PanelTitle from "../../service-layout/PanelTitle"
import AnalysisImageFilter, { statusFilterLabelToAnalysisStatus, StatusFilterType } from "./AnalysisImageFilter"
import AnalysisItem from "./AnalysisItem"

type OrderColumnType = "CREATED_TIME" | "REGION" | "IMAGING_TIME" | "IMAGE_NAME" | "STATUS" | "RESOLUTION" | "SATELLITE"

type Props = {
  className?: string
  selectedProject: Project
  selectedProjectJob?: InferenceJobInfo
  onAnalysisJobClick?: (job: InferenceJobInfo) => void
}

// @NOTE: MyImages 재구성용
function AnalysisImages(props: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [analysisImages, setAnalysisImages] = useState<InferenceJobInfos>(initInferenceJobInfos)
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [orderColumn, setOrderColumn] = useState<OrderColumnType>("CREATED_TIME")
  const [orderDirection, setOrderDirection] = useState<"DESC" | "ASC">("DESC")
  const [selectedAnalysisJobIdForDelete, setSelectedAnalysisJobIdForDelete] = useState<string>("")
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false)
  const [keyword, setKeyword] = useState<string>("")
  const [createdPeriod, setCreatedPeriod] = useState<RangePickerValue>([null, null])
  const [imagingPeriod, setImagingPeriod] = useState<RangePickerValue>([null, null])
  const [statuses, setStatuses] = useState<StatusFilterType[]>([])
  const [gsdRange, setGsdRange] = useState<GsdRange>([null, null])
  const [gsdBoundary, setGsdBoundary] = useState<GsdRange>([null, null])
  const [satellites, setSatellites] = useState<string[]>([])
  const [selectedSatellites, setSelectedSatellites] = useState<string[]>([])
  const [isFilterPopoverVisible, setIsFilterPopoverVisible] = useState<boolean>(false)
  const isPageVisible = usePageVisibility()
  const { t } = useTranslation()

  useInterval(
    () => {
      void setProjectsInferenceJobAsync()
    },
    isPageVisible ? 5000 : null,
    false,
  )

  useInterval(
    () => {
      void setGsdBoundaryAsync()
      void setSatellitesAsync()
    },
    isPageVisible && isFilterPopoverVisible ? 5000 : null,
    false,
  )

  useEffect(() => {
    const initializeGsdRangeAsync = async () => {
      try {
        const gsdBoundary = await getJobGsdRange(props.selectedProject.id)
        if (gsdBoundary[0] !== null) gsdBoundary[0] = Math.floor(gsdBoundary[0] * 10) / 10
        if (gsdBoundary[1] !== null) gsdBoundary[1] = Math.ceil(gsdBoundary[1] * 10) / 10

        setGsdBoundary(gsdBoundary)
        setGsdRange(gsdBoundary)
      } catch (e) {
        captureException(e)
      }
    }
    void initializeGsdRangeAsync()
    void setSatellitesAsync()
  }, [])

  useEffect(() => {
    void setProjectsInferenceJobAsync()
  }, [
    props.selectedProject,
    pageIndex,
    orderColumn,
    orderDirection,
    keyword,
    createdPeriod,
    imagingPeriod,
    statuses,
    gsdRange,
    selectedSatellites,
  ])

  const setProjectsInferenceJobAsync = async () => {
    if (isInitialProject(props.selectedProject)) return
    const query: JobsQueryType = {
      pageIndex,
      orderColumn,
      orderDirection,
      keyword,
      createdStartTime: createdPeriod[0]?.toISOString(),
      createdEndTime: createdPeriod[1]?.toISOString(),
      imagingStartTime: imagingPeriod[0]?.toISOString(),
      imagingEndTime: imagingPeriod[1]?.toISOString(),
      statuses: statuses.map((status) => statusFilterLabelToAnalysisStatus(status)).flat(),
      resolutionMin: gsdRange[0] === gsdBoundary[0] ? undefined : gsdRange[0] ?? undefined,
      resolutionMax: gsdRange[1] === gsdBoundary[1] ? undefined : gsdRange[1] ?? undefined,
      satellites: selectedSatellites.length !== 0 ? selectedSatellites : undefined,
    }

    try {
      const _job = await getProjectsInferenceJobs(props.selectedProject.id, query)
      if (!isError(_job)) setAnalysisImages(_job)
    } catch (e) {
      captureException(e)
    } finally {
      setIsLoading(false)
    }
  }

  const setGsdBoundaryAsync = async () => {
    try {
      const newGsdBoundary = await getJobGsdRange(props.selectedProject.id)
      if (newGsdBoundary[0] !== null) newGsdBoundary[0] = Math.floor(newGsdBoundary[0] * 10) / 10
      if (newGsdBoundary[1] !== null) newGsdBoundary[1] = Math.ceil(newGsdBoundary[1] * 10) / 10

      if (newGsdBoundary[0] !== gsdBoundary[0] && newGsdBoundary[1] !== gsdBoundary[1]) setGsdBoundary(newGsdBoundary)
    } catch (e) {
      captureException(e)
    }
  }

  const setSatellitesAsync = async () => {
    try {
      const satellites = await getJobSatellites(props.selectedProject.id)
      setSatellites(satellites)
    } catch (e) {
      captureException(e)
    }
  }

  const getFormattedSortLabel = () => {
    if (orderColumn === "IMAGE_NAME") return t("sortBy.jobName.label")
    else if (orderColumn === "CREATED_TIME") return t("sortBy.createdDate.label")
    else if (orderColumn === "IMAGING_TIME") return t("sortBy.imagingDate.label")
    else if (orderColumn === "REGION") return t("sortBy.region.label")
    else if (orderColumn === "RESOLUTION") return t("sortBy.resolution.label")
    else if (orderColumn === "SATELLITE") return t("sortBy.satellite.label")
    else if (orderColumn === "STATUS") return t("sortBy.status.label")
    else return ""
  }

  const onAnalysisJobDeleteClick = (id: string) => {
    setIsDeleteModalVisible(false)
    if (!id) {
      Toast({ message: t("toast.analysis.jobDeleted.error"), type: "error" })
      captureException("onAnalysisJobDeleteClick:: id is invalid,", (scope) => {
        scope.setContext("id", { id })
        return scope
      })
      setSelectedAnalysisJobIdForDelete("")
      return
    }

    const deleteInferencesAsync = async () => {
      try {
        const _delete = await deleteInferences(id)
        if (!isError(_delete) && _delete === 200) Toast({ message: t("toast.analysis.jobDeleted.success"), type: "success" })
      } catch (e) {
        Toast({ message: t("toast.analysis.jobDeleted.error"), type: "error" })
        captureException(e)
      } finally {
        setSelectedAnalysisJobIdForDelete("")
      }
    }
    void deleteInferencesAsync()
  }

  const imagesMemo = useMemo(() => analysisImages, [analysisImages])

  const handleChangeFilter = (
    createdPeriod: RangePickerValue,
    imagingDate: RangePickerValue,
    statuses: StatusFilterType[],
    gsdRange: GsdRange,
    satellites: string[],
  ) => {
    setIsLoading(true)
    setCreatedPeriod(createdPeriod)
    setImagingPeriod(imagingDate)
    setStatuses(statuses)
    setGsdRange(gsdRange)
    setSelectedSatellites(satellites)
  }

  const resetFilter = () => {
    setIsLoading(true)
    setCreatedPeriod([null, null])
    setImagingPeriod([null, null])
    setStatuses([])
    setSelectedSatellites([])
    setGsdRange(gsdBoundary)
  }

  const handleKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true)
    setKeyword(e.target.value)
  }

  const clearSearch = () => {
    setIsLoading(true)
    setKeyword("")
  }

  const isInitFilter = () => {
    return (
      createdPeriod[0] === null &&
      createdPeriod[1] === null &&
      imagingPeriod[0] === null &&
      imagingPeriod[1] === null &&
      gsdRange[0] === gsdBoundary[0] &&
      gsdRange[1] === gsdBoundary[1] &&
      selectedSatellites.length === 0 &&
      statuses.length === 0
    )
  }

  return (
    <div className={cn(styles.myImages, props.className)}>
      <Panel className={cn(styles.panel, styles.images)}>
        <div className={styles.titleContainer}>
          <PanelTitle label={t("analysis.jobs.title")} />
        </div>
        <Search
          className={styles.search}
          placeholder={`${t("searchBy.label", {
            target: `${t("sortBy.jobName.label")}, ${t("sortBy.region.label")}, ${t("sortBy.satellite.label")}`,
          })}`}
          onChange={handleKeywordChange}
          onClearButtonClick={clearSearch}
        />
        <div className={styles.filter}>
          <AnalysisImageFilter
            createdPeriod={createdPeriod}
            gsdBoundary={gsdBoundary}
            gsdRange={gsdRange}
            imagingPeriod={imagingPeriod}
            isVisible={isFilterPopoverVisible}
            satellites={satellites}
            selectedSatellites={selectedSatellites}
            setIsVisible={setIsFilterPopoverVisible}
            statuses={statuses}
            onFilterChange={handleChangeFilter}
            onReset={resetFilter}
          >
            <IconButton
              wrapperClassName={cn(styles.filterIcon, !isInitFilter() && styles.filtered)}
              size={"xs"}
              type={"square"}
              icon={<FilterOutlined />}
              onClick={() => setIsFilterPopoverVisible((prev) => !prev)}
            />
          </AnalysisImageFilter>
          <div className={styles.sort}>
            <span>{t("sortBy.label")}</span>
            <Dropdown
              size={"xs"}
              type={"single"}
              itemList={[
                { text: t("sortBy.createdDate.label"), value: t("sortBy.createdDate.label") },
                { text: t("sortBy.imagingDate.label"), value: t("sortBy.imagingDate.label") },
                { text: t("sortBy.jobName.label"), value: t("sortBy.jobName.label") },
                { text: t("sortBy.region.label"), value: t("sortBy.region.label") },
                { text: t("sortBy.resolution.label"), value: t("sortBy.resolution.label") },
                { text: t("sortBy.satellite.label"), value: t("sortBy.satellite.label") },
                { text: t("sortBy.status.label"), value: t("sortBy.status.label") },
              ]}
              value={getFormattedSortLabel()}
              onChange={(value) => {
                if (value === t("sortBy.jobName.label")) setOrderColumn("IMAGE_NAME")
                else if (value === t("sortBy.createdDate.label")) setOrderColumn("CREATED_TIME")
                else if (value === t("sortBy.imagingDate.label")) setOrderColumn("IMAGING_TIME")
                else if (value === t("sortBy.region.label")) setOrderColumn("REGION")
                else if (value === t("sortBy.resolution.label")) setOrderColumn("RESOLUTION")
                else if (value === t("sortBy.satellite.label")) setOrderColumn("SATELLITE")
                else if (value === t("sortBy.status.label")) setOrderColumn("STATUS")
              }}
            />
            <IconButton
              size={"xs"}
              type={"square"}
              icon={orderDirection === "ASC" ? <ArrowUpwardOutlined /> : <ArrowDownwardOutlined />}
              onClick={() => (orderDirection === "DESC" ? setOrderDirection("ASC") : setOrderDirection("DESC"))}
            />
          </div>
        </div>
        <div className={styles.imageList}>
          {analysisImages.totalCount === 0 ? (
            isLoading ? (
              <div className={styles.loadingContainer}>
                <Loading className={styles.loading} size={"small"} />
              </div>
            ) : isInitFilter() && keyword === "" ? (
              <EmptyStatus title={t("analysis.jobs.empty.title") ?? ""} desc={t("analysis.jobs.empty.desc") ?? ""} />
            ) : (
              <EmptyStatus title={t("analysis.noResults.title") ?? ""} desc={t("analysis.noResults.desc") ?? ""} />
            )
          ) : (
            imagesMemo.inferenceJobInfoList.map((job) => (
              <AnalysisItem
                key={job.id}
                active={job.id === props.selectedProjectJob?.id}
                data={job}
                onAnalysisJobClick={props.onAnalysisJobClick}
                onDeleteClick={(id) => {
                  setSelectedAnalysisJobIdForDelete(id)
                  setIsDeleteModalVisible(true)
                }}
              />
            ))
          )}
        </div>
      </Panel>
      <PanelPagination
        className={cn(styles.panelPagination, styles.images)}
        currentDataCount={imagesMemo.inferenceJobInfoList.length}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        totalCount={imagesMemo.totalCount}
      />

      {isDeleteModalVisible && (
        <ConfirmModal
          title={t("modal.confirm.analysis.imageDelete.title")}
          confirmText={t("button.delete")}
          desc={t("modal.confirm.analysis.imageDelete.desc") ?? ""}
          onCloseButtonClick={() => setIsDeleteModalVisible(false)}
          onConfirmButtonClick={() => onAnalysisJobDeleteClick(selectedAnalysisJobIdForDelete)}
        />
      )}
    </div>
  )
}

export default AnalysisImages
