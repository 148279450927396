import { Nullable } from "../../common/common"
import { CurrencyType, isCurrencyValid } from "./orderDto"

export type CreditRechargeStatusType = "READY" | "EXPIRED" | "REFUNDED" | "EXHAUSTED" | ""
export const isCreditRechargeStatusValid = (status: string | null): status is CreditRechargeStatusType => {
  return status === "READY" || status === "EXPIRED" || status === "REFUNDED" || status === "EXHAUSTED"
}
export type CreditRechargeResultType = "PAID" | "FREE" | ""
export type CreditRechargeResult = {
  id: string
  time: Date | ""
  originCredit: number
  remainCredit: number
  expiresAt: Date | ""
  key: string
  price: number
  currency: CurrencyType
  paid: boolean | ""
  status: CreditRechargeStatusType
  refundable: boolean
  refundablePrice: number
  refundedPrice: number
  type: CreditRechargeResultType // @NOTE Front handling field
}
export type CreditRechargeResults = {
  results: CreditRechargeResult[]
  nextPages: string[]
}

type CreditRechargeResultToNullable = Omit<
  CreditRechargeResult,
  "time" | "expiresAt" | "currency" | "paid" | "status" | "type"
>
type CreditRechargeResultDto = {
  time: string | null
  expiresAt: string | null
  currency: string | null
  paid: boolean | null
  status: string | null
} & {
  [key in keyof CreditRechargeResultToNullable]: Nullable<CreditRechargeResultToNullable[key]>
}
type CreditRechargeResultsToNullable = Omit<CreditRechargeResults, "results">
export type CreditRechargeResultsDto = {
  results: CreditRechargeResultDto[] | null
} & {
  [key in keyof CreditRechargeResultsToNullable]: Nullable<CreditRechargeResultsToNullable[key]>
}

export const creditRechargeResultFromDto = (dto: CreditRechargeResultDto): CreditRechargeResult => {
  const _currency = isCurrencyValid(dto.currency) ? dto.currency : ""
  const _status = isCreditRechargeStatusValid(dto.status) ? dto.status : ""
  const _type: CreditRechargeResultType = dto.paid === true ? "PAID" : dto.paid === false ? "FREE" : ""
  return {
    id: dto.id ?? "",
    time: dto.time ? new Date(dto.time) : "",
    originCredit: dto.originCredit ?? 0,
    remainCredit: dto.remainCredit ?? 0,
    expiresAt: dto.expiresAt ? new Date(dto.expiresAt) : "",
    key: dto.key ?? "",
    price: dto.price ?? 0,
    currency: _currency,
    paid: dto.paid ?? "",
    status: _status,
    refundable: dto.refundable ?? false,
    refundablePrice: dto.refundablePrice ?? 0,
    refundedPrice: dto.refundedPrice ?? 0,
    type: _type,
  }
}
export const creditRechargeResultsFromDto = (dto: CreditRechargeResultsDto): CreditRechargeResults => {
  return {
    results: dto.results?.map((result) => creditRechargeResultFromDto(result)) ?? [],
    nextPages: dto.nextPages.map((nextPage) => nextPage ?? ""),
  }
}

export const isInitialCreditRechargeResult = (result: CreditRechargeResult) => {
  return result.id === ""
}
export const initCreditRechargeResult = (): CreditRechargeResult => {
  return {
    id: "",
    time: "",
    originCredit: 0,
    remainCredit: 0,
    expiresAt: "",
    key: "",
    price: 0,
    currency: "",
    paid: "",
    status: "",
    refundable: false,
    refundablePrice: 0,
    refundedPrice: 0,
    type: "",
  }
}
export const initCreditRechargeResults = (): CreditRechargeResults => {
  return {
    results: [initCreditRechargeResult()],
    nextPages: [],
  }
}
