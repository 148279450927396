import cn from "classnames"
import React from "react"

import styles from "./AiPackTitleImage.module.scss"

type Props = {
  className?: string
  caption: string
  img: string
  imgAlt: string
}

export default function AiPackTitleImage(props: Props) {
  return (
    <div className={cn(styles.aiPackTitleImage, props.className)}>
      <div className={styles.header}>
        <div className={styles.control}>
          <div className={styles.close} />
          <div className={styles.minimize} />
          <div className={styles.expand} />
        </div>
        <div className={styles.caption}>
          <p>{props.caption}</p>
        </div>
      </div>
      <img className={styles.img} alt={props.imgAlt} src={props.img} />
    </div>
  )
}
