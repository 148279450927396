import { EmptyFilled } from "@ovision-gis-frontend/shared"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./EmptyStatus.module.scss"

type Props = {
  title?: string
  desc?: string
}

function EmptyStatus(props: Props) {
  // TODO: Status.Empty로 컴포넌트명 변경
  const { t } = useTranslation()

  return (
    <div className={styles.emptyStatus}>
      <EmptyFilled />
      <span className={styles.title}>{props.title ?? t("status.empty.title")}</span>
      {props.desc && <span className={styles.desc}>{props.desc}</span>}
    </div>
  )
}
export default EmptyStatus
