import React from "react"

import { IconBase, IconProps } from "../../IconBase"

export function OpenInNewOutlined(props: IconProps) {
  return (
    <IconBase viewBox={"0 0 16 16"} {...props}>
      <path d="M14 8V12.6667C14 13.0333 13.8696 13.3473 13.6087 13.6087C13.3473 13.8696 13.0333 14 12.6667 14H3.33333C2.96667 14 2.65267 13.8696 2.39133 13.6087C2.13044 13.3473 2 13.0333 2 12.6667V3.33333C2 2.96667 2.13044 2.65267 2.39133 2.39133C2.65267 2.13044 2.96667 2 3.33333 2H8V3.33333H3.33333V12.6667H12.6667V8H14ZM6.46667 10.4667L5.53333 9.53333L11.7333 3.33333H9.33333V2H14V6.66667H12.6667V4.26667L6.46667 10.4667Z" />
    </IconBase>
  )
}
