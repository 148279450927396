import React from "react"
import { useTranslation } from "react-i18next"

import TermsAndConditionsEnglish from "./TermsAndConditionsEnglish"
import TermsAndConditionsKorean from "./TermsAndConditionsKorean"

type Props = {}
function TermsAndConditions(props: Props) {
  const { i18n } = useTranslation()
  return i18n.language === "ko" ? <TermsAndConditionsKorean /> : <TermsAndConditionsEnglish />
}

export default TermsAndConditions
