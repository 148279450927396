import { disabledDate, GsdRange } from "@ovision-gis-frontend/shared"
import { Checkbox, RangePicker, Slider } from "@SIAnalytics/ovision-design-system"
import { RangePickerValue } from "@SIAnalytics/ovision-design-system/build/src/component/data-entry/picker/range-picker/RangePicker"
import React from "react"
import { useTranslation } from "react-i18next"

import FilterPopover, { Divider } from "../../common/FilterPopover"
import styles from "./ImageFilter.module.scss"

type Props = {
  isVisible: boolean
  children?: React.ReactNode
  createdPeriod: RangePickerValue
  imagingPeriod: RangePickerValue
  selectedSatellites: string[]
  satellites: string[]
  gsdRange: GsdRange
  gsdBoundary: GsdRange
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  onReset?: React.MouseEventHandler<HTMLElement>
  onFilterChange?: (
    createdPeriod: RangePickerValue,
    imagingPeriod: RangePickerValue,
    gsdRange: GsdRange,
    satellites: string[],
  ) => void
}

function ImageFilter(props: Props) {
  const { t } = useTranslation()

  const handleChangeCreatedDate = (value: RangePickerValue) => {
    value[0]?.setHours(0, 0, 0, 0)
    value[1]?.setHours(23, 59, 59, 999)
    props.onFilterChange?.(value, props.imagingPeriod, props.gsdRange, props.selectedSatellites)
  }

  const handleChangePeriodDate = (value: RangePickerValue) => {
    value[0]?.setHours(0, 0, 0, 0)
    value[1]?.setHours(23, 59, 59, 999)
    props.onFilterChange?.(props.createdPeriod, value, props.gsdRange, props.selectedSatellites)
  }

  const changeGsdFilter = (value: [number, number]) => {
    props.onFilterChange?.(props.createdPeriod, props.imagingPeriod, value, props.selectedSatellites)
  }

  const selectSatellite = (satellite: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      props.onFilterChange?.(
        props.createdPeriod,
        props.imagingPeriod,
        props.gsdRange,
        props.selectedSatellites.concat(satellite),
      )
    } else {
      props.onFilterChange?.(
        props.createdPeriod,
        props.imagingPeriod,
        props.gsdRange,
        props.selectedSatellites.filter((cur) => cur != satellite),
      )
    }
  }

  return (
    <FilterPopover
      className={styles.imageFilter}
      isVisible={props.isVisible}
      setIsVisible={props.setIsVisible}
      trigger={props.children}
      onReset={props.onReset}
    >
      <div className={styles.periodFilter}>
        <div>
          <p className={styles.fieldLabel}>{t("filter.createdPeriod")}</p>
          <RangePicker
            combine={true}
            disabledDate={disabledDate}
            format={"yyyy-MM-DD"}
            limited={true}
            limitedPlaceholder={t("rangePicker.endDate") ?? undefined}
            placeholder={t("rangePicker.startDate") ?? undefined}
            value={props.createdPeriod}
            onChange={handleChangeCreatedDate}
          />
        </div>
        <div>
          <p className={styles.fieldLabel}>{t("filter.imagingPeriod.label")}</p>
          <RangePicker
            combine={true}
            disabledDate={disabledDate}
            format={"yyyy-MM-DD"}
            limited={true}
            limitedPlaceholder={t("rangePicker.endDate") ?? undefined}
            placeholder={t("rangePicker.startDate") ?? undefined}
            value={props.imagingPeriod}
            onChange={handleChangePeriodDate}
          />
        </div>
      </div>
      <Divider />
      <div>
        <p className={styles.fieldLabel}>{t("sortBy.resolution.label")}</p>
        <Slider
          type={"range"}
          mark={{
            [props.gsdBoundary[0] ?? 0]: props.gsdBoundary[0] ?? "?" + "m",
            [props.gsdBoundary[1] ?? 0]: props.gsdBoundary[1] ?? "?" + "m",
          }}
          max={props.gsdBoundary[1] ?? undefined}
          min={props.gsdBoundary[0] ?? undefined}
          step={0.1}
          value={props.gsdRange[0] !== null && props.gsdRange[1] !== null ? (props.gsdRange as [number, number]) : undefined}
          onChange={changeGsdFilter}
        />
      </div>
      <Divider />
      <form>
        <fieldset className={styles.statusField}>
          <legend className={styles.fieldLabel}>{t("sortBy.satellite.label")}</legend>
          {props.satellites.map((satellite) => (
            <Checkbox
              key={satellite}
              active={props.selectedSatellites.includes(satellite)}
              onChange={selectSatellite(satellite)}
            >
              {satellite === "" ? t("unknown") : satellite}
            </Checkbox>
          ))}
        </fieldset>
      </form>
    </FilterPopover>
  )
}

export default ImageFilter
