import {
  PATH_END_USER_LICENSE_AGREEMENT,
  PATH_GEO_AI_PACK,
  PATH_PRICING,
  PATH_PRIVACY_POLICY,
  PATH_TERMS_AND_CONDITIONS,
  URL_CONTACTS,
  URL_DOCS_EN,
  URL_DOCS_KO,
} from "@ovision-gis-frontend/shared"
import XiFacebookImg from "@ovision-gis-frontend/shared/src/asset/icons/xeicon/XiFacebook.png"
import XiGithubImg from "@ovision-gis-frontend/shared/src/asset/icons/xeicon/XiGithub.png"
import XiLinkedinImg from "@ovision-gis-frontend/shared/src/asset/icons/xeicon/XiLinkedin.png"
import XiYoutubePlay from "@ovision-gis-frontend/shared/src/asset/icons/xeicon/XiYoutubePlay.png"
import OvisionLogoImg from "@ovision-gis-frontend/shared/src/asset/logo/OvisionLogo.png"
import cn from "classnames"
import i18next from "i18next"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import styles from "./FootNavigationBar.module.scss"
import { geoAiPackTitleId } from "./GeoAiPack"

type Props = {
  className?: string
}

type SnsType = {
  img: string
  link?: string
}

type MenuType = {
  label: string
  path: string
}
type SiteMapType = {
  title: string
  path: string
  menu?: MenuType[]
}

function FootNavigationBar(props: Props) {
  const { t } = useTranslation()

  const snsList: SnsType[] = [
    {
      img: XiFacebookImg,
      link: "https://www.facebook.com/SIAnalytics",
    },
    {
      img: XiGithubImg,
      link: "https://github.com/SIAnalytics",
    },
    {
      img: XiLinkedinImg,
      link: "https://kr.linkedin.com/company/si-analytics-ai",
    },
    {
      img: XiYoutubePlay,
      link: "https://www.youtube.com/c/SIAnalytics_ai",
    },
  ]

  const siteMapList: SiteMapType[] = [
    {
      title: t("geoAiPack.title"),
      path: PATH_GEO_AI_PACK + geoAiPackTitleId.imageImprovement,
      menu: [
        { label: t("service.imageImprovement.title"), path: PATH_GEO_AI_PACK + geoAiPackTitleId.imageImprovement },
        { label: t("service.objectDetection.title"), path: PATH_GEO_AI_PACK + geoAiPackTitleId.objectDetection },
        { label: t("service.infrastructure.title"), path: PATH_GEO_AI_PACK + geoAiPackTitleId.infrastructure },
        { label: t("service.changeDetection.title"), path: PATH_GEO_AI_PACK + geoAiPackTitleId.changeDetection },
      ],
    },
    {
      title: t("pricing.title"),
      path: PATH_PRICING,
    },
    {
      title: t("docs.title"),
      path: i18next.language === "ko" ? URL_DOCS_KO : URL_DOCS_EN,
    },
    {
      title: t("contactUs.title"),
      path: URL_CONTACTS,
    },
    {
      title: t("legal.title"),
      path: PATH_TERMS_AND_CONDITIONS,
      menu: [
        { label: t("termsAndConditions.label"), path: PATH_TERMS_AND_CONDITIONS },
        { label: t("privacyPolicy.title"), path: PATH_PRIVACY_POLICY },
        { label: t("eula.abbreviation.title"), path: PATH_END_USER_LICENSE_AGREEMENT },
      ],
    },
  ]

  return (
    <footer className={cn(styles.footNavigationBar, props.className)}>
      <div className={styles.navigation}>
        <div className={styles.iconContainer}>
          <div className={styles.logoContainer}>
            <img className={styles.logo} alt={""} src={OvisionLogoImg} />
            <span className={styles.logoMainText}>{"OVISION"}</span>
            <span className={styles.logoSubText}>{"Earth"}</span>
          </div>
          <div className={styles.frameWrapper}>
            {snsList.map((sns) => (
              <a className={styles.frame} key={sns.img} href={sns.link} rel={"noopener noreferrer"} target={"_blank"}>
                {/* TODO: Change to xemicon font*/}
                <img alt={""} src={sns.img} />
              </a>
            ))}
          </div>
        </div>
        <div className={styles.siteMapGroupWrapper}>
          {siteMapList.map((siteMap) => (
            <div className={styles.siteMapWrapper} key={siteMap.title}>
              {!siteMap.path.startsWith("http") ? (
                <Link className={styles.title} to={siteMap.path}>
                  {siteMap.title}
                </Link>
              ) : (
                <a className={styles.title} href={siteMap.path} rel={"noopener noreferrer"} target={"_blank"}>
                  {siteMap.title}
                </a>
              )}
              {siteMap.menu &&
                siteMap.menu.map((menu) => (
                  <Link className={styles.menu} key={menu.label} to={menu.path}>
                    {menu.label}
                  </Link>
                ))}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.copyRight}>{`Copyright © 2024. SI Analytics Inc. All rights reserved.`}</div>
    </footer>
  )
}
export default FootNavigationBar
