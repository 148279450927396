import { Carousel } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./PanelPagination.module.scss"

type Props = {
  className?: string
  currentDataCount: number
  totalCount: number
  pageIndex: number
  setPageIndex: React.Dispatch<React.SetStateAction<number>>
}

function PanelPagination(props: Props) {
  const { t } = useTranslation()

  const isLastIndex = props.pageIndex === Math.floor((props.totalCount ? props.totalCount - 1 : 0) / 50)
  const isFirstIndex = props.pageIndex === 0

  const onBeforeBtnClick = () => {
    if (isFirstIndex) return
    props.setPageIndex((prev) => prev - 1)
  }

  const onNextBtnClick = () => {
    if (isLastIndex) return
    props.setPageIndex((prev) => prev + 1)
  }

  const renderRange = () => {
    if (isFirstIndex && (props.currentDataCount === 1 || props.currentDataCount === 0)) {
      return t("panel.pagination.label", { current: props.currentDataCount, total: props.totalCount })
    } else if (isFirstIndex) {
      return t("panel.pagination.label", { current: `1-${props.currentDataCount}`, total: props.totalCount })
    } else if (isLastIndex && props.currentDataCount === 1) {
      return t("panel.pagination.label", { current: `${props.pageIndex * 50 + 1}`, total: props.totalCount })
    } else {
      return t("panel.pagination.label", {
        current: `${props.pageIndex * 50 + 1}-${isLastIndex ? props.totalCount : (props.pageIndex + 1) * 50}`,
        total: props.totalCount,
      })
    }
  }

  return (
    <div className={cn(styles.panelPagination, props.className)}>
      <span>{renderRange()}</span>
      <Carousel
        size={"large"}
        disabled={[isFirstIndex, isLastIndex]}
        onBeforeBtnClick={onBeforeBtnClick}
        onNextBtnClick={onNextBtnClick}
      />
    </div>
  )
}
export default PanelPagination
