import cn from "classnames"
import React, { CSSProperties } from "react"
import { useTranslation } from "react-i18next"

import styles from "./Legend.module.scss"

export type LegendContent = {
  text: string
  count?: number
  style?: CSSProperties
}

type Props = {
  className?: string
  contents: LegendContent[]
}

function Legend(props: Props) {
  const { t } = useTranslation()

  return (
    <div className={cn(styles.legend, props.className)}>
      <ul>
        <li>{t("legend.label")}</li>
        <ul>
          {props.contents.map((content, index) => (
            <li key={content.text + index}>
              <span className={styles.left}>
                <span className={styles.circle} style={content.style} />
                <span className={styles.label}>{content.text}</span>
              </span>
              <span className={styles.count}>{content.count}</span>
            </li>
          ))}
        </ul>
      </ul>
    </div>
  )
}
export default Legend
