import { CreditAllResultType, CreditRechargeResultType } from "@ovision-gis-frontend/shared"
import { t } from "i18next"

export const getCreditAllTypeLabel = (type: CreditAllResultType) => {
  switch (type) {
    case "RECHARGE":
      return t("myCredit.all.type.recharge.label")
    case "USAGE":
      return t("myCredit.all.type.usage.label")
    case "EXPIRATION":
      return t("myCredit.all.type.expiration.label")
    case "REFUND":
      return t("myCredit.all.type.refund.label")
    case "COMPENSATION":
      return t("myCredit.all.type.compensation.label")
    default:
      return ""
  }
}
export const getCreditRechargeTypeLabel = (type: CreditRechargeResultType) => {
  switch (type) {
    case "PAID":
      return t("myCredit.recharge.type.paid.label")
    case "FREE":
      return t("myCredit.recharge.type.free.label")
    default:
      return ""
  }
}
