import {
  CloudType,
  ConfirmModal,
  deleteSyncStorages,
  getSyncStorages,
  getSyncStoragesProject,
  initProjects,
  isError,
  Projects,
  SyncStorage,
  useInterval,
  usePageVisibility,
} from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import { Toast } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React, { useState } from "react"
import { Trans, useTranslation } from "react-i18next"

import AmazonS3Img from "../../../asset/AmazonS3.png"
import AzureBlobStorageImg from "../../../asset/AzureBlobStorage.png"
import GoogleCloudStorageImg from "../../../asset/GoogleCloudStorage.png"
import SiaCloudImg from "../../../asset/SIACloud.png"
import SettingsHeader from "../SettingsHeader"
import CloudStorage from "./CloudStorage"
import styles from "./Connections.module.scss"
import MyConnections from "./MyConnections"
import RegisterConnection from "./RegisterConnection"

export type Cloud = {
  name: string
  type: CloudType
  description: string
  edge?: boolean
  src: string
  connectionText: string
}
const initCloud = (): Cloud => {
  return { name: "", type: "", description: "", edge: false, src: "", connectionText: "" }
}

type Panel = "Connections" | "Register"

type Props = {
  className?: string
}

function Connections(props: Props) {
  const [panel, setPanel] = useState<Panel>("Connections")
  const [selectedCloud, setSelectedCloud] = useState<Cloud>(initCloud)
  const [selectedSyncStorageId, setSelectedSyncStorageId] = useState<string>("-1")
  const [dataSource, setDataSource] = useState<SyncStorage[]>([])
  const [syncStorageProjects, setSyncStorageProjects] = useState<Projects>(initProjects)
  const [linkedProjectsLabel, setLinkedProjectsLabel] = useState<string>("")
  const [isSyncStorageFetching, setIsSyncStorageFetching] = useState<boolean>(true)
  const [isLinkedProjectNoticeModalVisible, setIsLinkedProjectNoticeModalVisible] = useState<boolean>(false)
  const [isDisconnectModalVisible, setIsDisconnectModalVisible] = useState<boolean>(false)
  const isPageVisible = usePageVisibility()
  const { t } = useTranslation()

  useInterval(
    () => {
      void setSyncStoragesAsync()
    },
    isPageVisible ? 5000 : null,
    true,
  )

  const setSyncStoragesAsync = async () => {
    try {
      const _syncStorages = await getSyncStorages()
      if (!isError(_syncStorages)) setDataSource(_syncStorages.syncStorages)
    } catch (error) {
      captureException(error)
    } finally {
      setIsSyncStorageFetching(false)
    }
  }

  const cloudList: Cloud[] = [
    {
      name: t("siaCloud.title"),
      type: "SIA_CLOUD",
      description: t("siaCloud.desc"),
      edge: true,
      src: SiaCloudImg,
      connectionText: t("button.creationInquiry"),
    },
    {
      name: t("googleCloudStorage.title"),
      type: "GCP",
      description: t("googleCloudStorage.desc"),
      edge: false,
      src: GoogleCloudStorageImg,
      connectionText: t("button.connect"),
    },
    {
      name: t("amazonS3.title"),
      type: "AWS",
      description: t("amazonS3.desc"),
      edge: false,
      src: AmazonS3Img,
      connectionText: t("button.connect"),
    },
    {
      name: t("azureBlobStorage.title"),
      type: "AZURE",
      description: t("azureBlobStorage.desc"),
      edge: false,
      src: AzureBlobStorageImg,
      connectionText: t("button.connect"),
    },
  ]

  const onDisconnectClick = (id: string) => {
    setSelectedSyncStorageId(id)

    const setSyncStoragesProjectAsync = async () => {
      try {
        const _project = await getSyncStoragesProject(id)
        if (!isError(_project)) {
          setSyncStorageProjects(_project)
          if (_project.totalCount === 0) {
            setIsDisconnectModalVisible(true)
          } else {
            setLinkedProjectsLabel(_project.projects.map((project) => "  ㆍ" + project.name).join("\n"))
            setIsLinkedProjectNoticeModalVisible(true)
          }
        }
      } catch (e) {
        captureException(e)
      }
    }
    void setSyncStoragesProjectAsync()
  }

  const onDisconnectConfirmButtonClick: React.MouseEventHandler<HTMLElement> = async (e) => {
    setIsDisconnectModalVisible(false)
    if (selectedSyncStorageId === "-1") return

    try {
      const res = await deleteSyncStorages(selectedSyncStorageId)
      if (!isError(res) && res === 200) Toast({ message: t("toast.disconnect.success"), type: "success" })
    } catch (e) {
      Toast({ message: t("toast.disconnect.error"), type: "error" })
      captureException(e)
    } finally {
      setSelectedSyncStorageId("-1")
      void setSyncStoragesAsync()
    }
  }

  const onCloudStorageButtonClick = (cloud: Cloud) => {
    if (!cloud) return

    if (cloud.type === "SIA_CLOUD") cloud.name = t("siaCloud.title")
    else if (cloud.type === "GCP") cloud.name = t("googleCloudStorage.title")
    else if (cloud.type === "AWS") cloud.name = t("amazonS3.title")
    else if (cloud.type === "AZURE") cloud.name = t("azureBlobStorage.title")

    setPanel("Register")
    setSelectedCloud(cloud)
  }

  return (
    <>
      {panel === "Connections" && (
        <div className={cn(props.className)}>
          <SettingsHeader title={t("modal.setting.connections.title")} />
          <div className={styles.content}>
            <MyConnections
              className={styles.myConnections}
              dataSource={dataSource}
              spinning={isSyncStorageFetching}
              onDisconnectClick={onDisconnectClick}
            />
            <span className={styles.subTitle}>{t("modal.setting.connections.newConnections")}</span>
            <div className={styles.cloudStoragesContainer}>
              {cloudList.map((cloud) => (
                <CloudStorage key={cloud.name} cloud={cloud} onCloudStorageButtonClick={onCloudStorageButtonClick} />
              ))}
            </div>
          </div>
          {isDisconnectModalVisible && (
            <ConfirmModal
              title={t("modal.confirm.disconnect.title")}
              confirmText={t("button.disconnect")}
              desc={t("modal.confirm.disconnect.desc") ?? ""}
              onCloseButtonClick={() => setIsDisconnectModalVisible(false)}
              onConfirmButtonClick={onDisconnectConfirmButtonClick}
            />
          )}
          {isLinkedProjectNoticeModalVisible && (
            <ConfirmModal
              title={t("modal.confirm.linkedProjectExists.title")}
              desc={
                <Trans i18nKey={"modal.confirm.linkedProjectExists.desc"}>
                  There are projects linked to this bucket. Before you can disconnect the bucket, you must delete all linked
                  projects.
                  <strong>Projects linked to this bucket</strong>
                  <p>PROJECTS</p>
                  {{ projects: linkedProjectsLabel }}
                </Trans>
              }
              confirmText={t("button.confirm")}
              isCancelButtonVisible={false}
              isPrimaryConfirm={true}
              onCloseButtonClick={() => setIsLinkedProjectNoticeModalVisible(false)}
              onConfirmButtonClick={() => setIsLinkedProjectNoticeModalVisible(false)}
            />
          )}
        </div>
      )}
      {panel === "Register" && (
        <RegisterConnection
          className={props.className}
          cloud={selectedCloud}
          onBackButtonClick={() => setPanel("Connections")}
          onConnectButtonClick={() => setPanel("Connections")}
        />
      )}
    </>
  )
}
export default Connections
