import cn from "classnames"
import React from "react"

import { Loading } from "../../../index"
import styles from "./Fallback.module.scss"

type Props = {
  className?: string
}

export default function Fallback(props: Props) {
  return (
    <div className={cn(styles.fallback, props.className)}>
      <Loading size={"small"} />
    </div>
  )
}
