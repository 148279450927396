import { MyAccount } from "@ovision-gis-frontend/shared"
import React, { useEffect } from "react"

import styles from "./GlobalNavigationBar.module.scss"
import GnbBanner from "./gnb-banner/GnbBanner"
import GnbHeader from "./gnb-header/GnbHeader"

type Props = {
  user: MyAccount
}

function GlobalNavigationBar(props: Props) {
  useEffect(() => {
    const zendesk = document.getElementById("zendeskLauncher")
    if (zendesk) zendesk.style.display = "flex"

    return () => {
      const zendesk = document.getElementById("zendeskLauncher")
      if (zendesk) zendesk.style.display = "none"
    }
  }, [])

  return (
    <div className={styles.gnb}>
      {/*<GnbBanner /> @NOTE: 프로모션 종료로 주석 처리 */}
      <GnbHeader user={props.user} />
    </div>
  )
}
export default GlobalNavigationBar
