import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./LegalContentsKorean.module.scss"

function PrivacyPolicyKorean() {
  const { t } = useTranslation()

  // TODO: i18nexus key 수정
  return (
    <section className={styles.legal}>
      <span className={styles.label}>{t("legal.title")}</span>
      <span className={styles.headingOne}>{t("privacyPolicy.title")}</span>
      <span className={styles.desc}>{t("personalInfoPolicyDesc")}</span>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("personalInfoPolicyFirst")}</span>
        <span className={styles.desc}>{t("personalInfoPolicyFirstDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("personalInfoPolicySecond")}</span>
        <span className={styles.desc}>{t("personalInfoPolicySecondDesc")}</span>
        <span className={styles.headingThree}>{t("personalInfoPolicySecondFirst")}</span>
        <table>
          <thead>
            <tr>
              <th>{t("collectionAndUsePurpose")}</th>
              <th>{t("collectionAndUseContent")}</th>
              <th>{t("retentionAndUsePeriod")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={styles.grey}>{t("snsIdSignIn")}</td>
              <td>{t("snsIdSignInContent")}</td>
              <td className={styles.underline}>{t("snsIdSignInPeriod")}</td>
            </tr>
            <tr>
              <td className={styles.grey}>{t("memberAndServiceNotice")}</td>
              <td>{t("memberAndServiceNoticeContent")}</td>
              <td className={styles.underline}>{t("memberAndServiceNoticePeriod")}</td>
            </tr>
            <tr>
              <td className={styles.grey}>{t("customerInquiryAndResponse")}</td>
              <td>{t("customerInquiryAndResponseContent")}</td>
              <td className={styles.underline}>{t("customerInquiryAndResponsePeriod")}</td>
            </tr>
          </tbody>
        </table>
        <span className={styles.headingThree}>{t("personalInfoPolicySecondSecond")}</span>
        <span className={styles.desc}>{t("personalInfoPolicySecondSecondDesc")}</span>
        <span className={styles.headingThree}>{t("personalInfoPolicySecondThird")}</span>
        <span className={styles.desc}>{t("personalInfoPolicySecondThirdDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("personalInfoPolicyThird")}</span>
        <span className={styles.headingThree}>{t("personalInfoPolicyThirdFirst")}</span>
        <span className={styles.desc}>{t("personalInfoPolicyThirdFirstDesc")}</span>
        <span className={styles.headingThree}>{t("personalInfoPolicyThirdSecond")}</span>
        <span className={styles.desc}>{t("personalInfoPolicyThirdSecondDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("personalInfoPolicyFourth")}</span>
        <span className={styles.desc}>{t("personalInfoPolicyFourthDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("personalInfoPolicyFifth")}</span>
        <span className={styles.desc}>{t("personalInfoPolicyFifthDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("personalInfoPolicySixth")}</span>
        <span className={styles.desc}>{t("personalInfoPolicySixthDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("personalInfoPolicySeventh")}</span>
        <span className={styles.desc}>{t("personalInfoPolicySeventhDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("personalInfoPolicyEighth")}</span>
        <span className={styles.desc}>{t("personalInfoPolicyEighthDesc")}</span>
      </div>
    </section>
  )
}
export default PrivacyPolicyKorean
