import { EmptyStatus, getDateLabel, Loading, SyncStorage } from "@ovision-gis-frontend/shared"
import { Table as AntdTable } from "antd"
import { ConfigProvider as AntdConfigProvider } from "antd"
import { ColumnsType } from "antd/es/table"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { getCloudImg, getCloudName, getCloudStatus } from "../../../common/cloudUtil"
import ContextMenuPopover from "../../service-layout/ContextMenuPopover"
import styles from "./MyConnections.module.scss"

type GetComponentProps<SyncStorage> = (
  data: SyncStorage,
  index?: number,
) => React.HTMLAttributes<any> | React.TdHTMLAttributes<any>

type Props = {
  className?: string
  tableClassName?: string
  isSelectBucketModal?: boolean
  dataSource: SyncStorage[]
  spinning: boolean
  onDisconnectClick?: (id: string) => void
  onTableRow?: GetComponentProps<SyncStorage>
}

function MyConnections(props: Props) {
  const { t } = useTranslation()
  const isDisabledStatusInSelectBucketModal = (record: SyncStorage) => {
    return record.status !== "SUCCESS" && props.isSelectBucketModal
  }

  const columns: ColumnsType<SyncStorage> = [
    {
      title: t("modal.setting.connections.connection"),
      dataIndex: "name",
      key: "name",
      width: "42%",
      render: (text, record) => (
        <div className={styles.connectionCell}>
          <img alt={""} src={getCloudImg(record.cloudType, isDisabledStatusInSelectBucketModal(record))} />
          <div className={styles.label}>
            <p className={styles.cloud}>{getCloudName(record.cloudType).title}</p>
            <p className={styles.bucket}>{record.name}</p>
          </div>
        </div>
      ),
    },
    {
      title: t("modal.setting.connections.connectedDate"),
      dataIndex: "createdTime",
      key: "createdTime",
      width: "26%",
      render: (text, record) => <p>{getDateLabel(record.createdTime, "TIL_SEC")}</p>,
    },
    {
      title: t("modal.setting.connections.status"),
      dataIndex: "status",
      key: "status",
      width: "26%",
      render: (text, record) => (
        <div className={styles.statusCell}>
          <p>{getCloudStatus(record.status)}</p>
          {props.onDisconnectClick && (
            <ContextMenuPopover
              className={styles.contextMenu}
              positionClassName={styles.positionClassName}
              contents={[
                {
                  text: t("popover.contextMenu.disconnect"),
                  danger: true,
                  onClick: () => props.onDisconnectClick?.(record.id),
                },
              ]}
              button={{ className: styles.contextMenuBtn, type: "square" }}
            />
          )}
        </div>
      ),
    },
  ]

  const renderEmpty = () => {
    return (
      <EmptyStatus
        title={t("modal.setting.connections.myConnections.empty.title") ?? ""}
        desc={t("modal.setting.connections.myConnections.empty.desc") ?? ""}
      />
    )
  }

  return (
    <div className={cn(styles.myConnections, props.className)}>
      <span className={styles.subTitle}>{t("modal.setting.connections.myConnections.title")}</span>
      <AntdConfigProvider renderEmpty={renderEmpty}>
        <AntdTable
          className={cn(styles.myConnectionsTable, props.tableClassName)}
          rowClassName={(record, index) =>
            isDisabledStatusInSelectBucketModal(record) ? "my-connections-table-row-disabled" : "my-connections-table-row"
          }
          columns={columns}
          dataSource={props.dataSource}
          loading={{ indicator: <Loading className={styles.loading} size={"small"} />, spinning: props.spinning }}
          pagination={false}
          rowKey={(record) => record.id}
          onRow={(record, index) =>
            (isDisabledStatusInSelectBucketModal(record) ? {} : props.onTableRow?.(record, index)) ?? {}
          }
        />
      </AntdConfigProvider>
    </div>
  )
}
export default MyConnections
