import { Nullable } from "../../common/common"

export type InferenceResult = {
  aoi: string
  sceneUriList: (string | null)[]
  sceneWmsUrlList: (string | null)[]
  sceneExtentList: string[]
  summary: object
  resultDownloadUrl: string
  resultGisUrl: string
  vectorTileUrlList: string[]
}

type InferenceResultToNullable = Omit<InferenceResult, "sceneExtentList" | "vectorTileUrlList">
export type InferenceResultDto = {
  sceneExtentList: string[] | null
  vectorTileUrlList: string[] | null
} & {
  [key in keyof InferenceResultToNullable]: Nullable<InferenceResultToNullable[key]>
}

export const inferenceResultFromDto = (dto: InferenceResultDto): InferenceResult => {
  return {
    aoi: dto.aoi ?? "",
    sceneUriList: dto.sceneUriList ?? [],
    sceneExtentList: dto.sceneExtentList ?? [],
    summary: dto.summary ?? {},
    resultDownloadUrl: dto.resultDownloadUrl ?? "",
    resultGisUrl: dto.resultGisUrl ?? "",
    sceneWmsUrlList: dto.sceneWmsUrlList ?? [],
    vectorTileUrlList: dto.vectorTileUrlList ?? [],
  }
}

export const isInitialInferenceResult = (inferenceResult: InferenceResult) => {
  return inferenceResult.resultGisUrl === ""
}
export const initInferenceResult = (): InferenceResult => {
  return {
    aoi: "",
    sceneUriList: [],
    sceneWmsUrlList: [],
    sceneExtentList: [],
    summary: {},
    resultDownloadUrl: "",
    resultGisUrl: "",
    vectorTileUrlList: [],
  }
}
