import { Toast } from "@SIAnalytics/ovision-design-system"
import React from "react"
import { useTranslation } from "react-i18next"
import { Navigate, useLocation } from "react-router-dom"

import { OE_EMAIL, PATH_SIGN_IN } from "../../.."
import MailBoxImg from "../../asset/images/MailBox.png"
import styles from "./EmailOnboardingLayout.module.scss"

type Props = { children?: React.ReactNode; title: string; desc: React.ReactNode; email: string }

function EmailOnboardingLayout(props: Props) {
  const { t } = useTranslation()
  if (!props.email) {
    Toast({ message: t("toast.verifyEmail.invalid.error"), type: "error" })
    return <Navigate replace={true} to={PATH_SIGN_IN} />
  }

  return (
    <div className={styles.verifyEmail}>
      <div className={styles.content}>
        <img className={styles.mailBox} alt={"Mail box"} src={MailBoxImg} />
        <p className={styles.title}>{props.title}</p>
        <p className={styles.desc}>{props.desc}</p>
        {props.children}
      </div>
    </div>
  )
}

export default EmailOnboardingLayout

export function useEmail() {
  const location = useLocation()

  let email = ""
  if (location.state) email = OE_EMAIL in location.state ? location.state[OE_EMAIL] : ""

  return email
}
