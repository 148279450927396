import {
  ConfirmModal,
  CreditAllResult,
  CreditRechargeResult,
  CreditUsageResult,
  getCurrencyLabel,
  getDateLabel,
  getMyCreditAll,
  getMyCreditRecharge,
  getMyCreditUsage,
  getOrdersRefund,
  initCreditRechargeResult,
  initRefund,
  isInitialCreditRechargeResult,
  PageQueryType,
  postOrdersRefund,
  Refund,
} from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import {
  Button,
  Chip,
  NavigateBeforeOutlined,
  NavigateNextOutlined,
  Toast,
  Tooltip,
} from "@SIAnalytics/ovision-design-system"
import { ConfigProvider as AntdConfigProvider, PaginationProps, Table as AntdTable, Tabs } from "antd"
import { ColumnsType } from "antd/es/table"
import cn from "classnames"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { getCreditAllTypeLabel, getCreditRechargeTypeLabel } from "../../../common/creditUtil"
import { MyCreditTab } from "./MyCredit"
import styles from "./MyCredit.module.scss"
import RefundModal from "./RefundModal"

type Props = {
  defaultActiveKey: MyCreditTab
  onRefunded?: () => void
}

export function MyCreditTable(props: Props) {
  const [allDataSource, setAllDataSource] = useState<CreditAllResult[]>([])
  const [allPageToken, setAllPageToken] = useState<string[]>([])
  const [allPage, setAllPage] = useState<string>("")
  const [rechargeDataSource, setRechargeDataSource] = useState<CreditRechargeResult[]>([])
  const [rechargePageToken, setRechargePageToken] = useState<string[]>([])
  const [rechargePage, setRechargePage] = useState<string>("")
  const [usageDataSource, setUsageDataSource] = useState<CreditUsageResult[]>([])
  const [usagePageToken, setUsagePageToken] = useState<string[]>([])
  const [usagePage, setUsagePage] = useState<string>("")
  const [selectedRechargeResult, setSelectedRechargeResult] = useState<CreditRechargeResult>(initCreditRechargeResult)
  const [refund, setRefund] = useState<Refund>(initRefund)
  const [isRefundDataRequesting, setIsRefundDataRequesting] = useState<boolean>(false)
  const [isRefundRequesting, setIsRefundRequesting] = useState<boolean>(false)
  const [isRefundModalVisible, setIsRefundModalVisible] = useState<boolean>(false)
  const [isRefundConfirmModalVisible, setIsRefundConfirmModalVisible] = useState<boolean>(false)
  const { t } = useTranslation()

  const pageSize = 50 // @NOTE: sizeChanger 작업 전까지 50으로 세팅
  const nextPageCount = 9 // @NOTE: 페이지를 10 단위로 세팅
  const scrollY = 776 // @NOTE: ODS Table height 세팅

  const getMyCreditAllAsync = async () => {
    const queryType: PageQueryType = { page: allPage, pageSize: `${pageSize}`, nextPageCount: `${nextPageCount}` }
    try {
      const _all = await getMyCreditAll(queryType)
      setAllDataSource(_all.results)
      // @NOTE: 2 pageToken부터 전달 받고 1 pageToken은 빈 값으로 통신
      if (!allPageToken.length) setAllPageToken(["", ..._all.nextPages])
      // @NOTE: nextPage[0] 값이 없거나 pageToken 배열에 해당 값이 없는 경우 pageToken에 이어서 추가
      else if (!allPageToken.includes(_all.nextPages.at(0) ?? "null"))
        setAllPageToken((prev) => [...prev, ..._all.nextPages])
    } catch (e) {
      captureException(e)
    }
  }
  const getMyCreditRechargeAsync = async () => {
    const queryType: PageQueryType = { page: rechargePage, pageSize: `${pageSize}`, nextPageCount: `${nextPageCount}` }
    try {
      const _recharge = await getMyCreditRecharge(queryType)
      setRechargeDataSource(_recharge.results)
      if (!rechargePageToken.length) setRechargePageToken(["", ..._recharge.nextPages])
      else if (!rechargePageToken.includes(_recharge.nextPages.at(0) ?? "null"))
        setRechargePageToken((prev) => [...prev, ..._recharge.nextPages])
    } catch (e) {
      captureException(e)
    }
  }
  const getMyCreditUsageAsync = async () => {
    const queryType: PageQueryType = { page: usagePage, pageSize: `${pageSize}`, nextPageCount: `${nextPageCount}` }
    try {
      const _usage = await getMyCreditUsage(queryType)
      setUsageDataSource(_usage.results)
      if (!usagePageToken.length) setUsagePageToken(["", ..._usage.nextPages])
      else if (!usagePageToken.includes(_usage.nextPages.at(0) ?? "null"))
        setUsagePageToken((prev) => [...prev, ..._usage.nextPages])
    } catch (e) {
      captureException(e)
    }
  }
  useEffect(() => {
    void getMyCreditAllAsync()
  }, [allPage])
  useEffect(() => {
    void getMyCreditRechargeAsync()
  }, [rechargePage])
  useEffect(() => {
    void getMyCreditUsageAsync()
  }, [usagePage])

  const allColumns: ColumnsType<CreditAllResult> = [
    {
      title: t("myCredit.table.date.label"),
      dataIndex: "time",
      key: "time",
      width: "20%",
      render: (text, record) => <div>{getDateLabel(record.time, "TIL_DAY")}</div>,
    },
    {
      title: t("myCredit.table.type.label"),
      dataIndex: "type",
      key: "type",
      width: "20%",
      render: (text, record) => {
        const label = getCreditAllTypeLabel(record.type)
        if (record.type === "RECHARGE") return <Chip color={"yellow"} label={label} />
        else if (record.type === "USAGE") return <Chip color={"blue"} label={label} />
        else if (record.type === "EXPIRATION") return <Chip color={"grey"} label={label} />
        else if (record.type === "REFUND") return <Chip className={styles.error} label={label} />
        else if (record.type === "COMPENSATION") return <Chip color={"green"} label={label} />
      },
    },
    {
      title: t("myCredit.table.credits.label"),
      dataIndex: "creditAmount",
      key: "creditAmount",
      width: "20%",
      render: (text, record) => (
        <div className={styles.credit}>
          {record.creditAmount > 0 ? "+" + record.creditAmount.toLocaleString() : record.creditAmount.toLocaleString()}
        </div>
      ),
    },
    {
      title: t("myCredit.table.remainingCredits.label"),
      dataIndex: "remainCredit",
      key: "remainCredit",
      width: "20%",
      render: (text, record) => <div className={styles.credit}>{record.remainCredit.toLocaleString()}</div>,
    },
    {
      title: t("myCredit.table.price.label"),
      dataIndex: "price",
      key: "price",
      width: "20%",
      render: (text, record) => (
        <div className={styles.primary}>
          {record.type === "RECHARGE" && record.price !== 0
            ? getCurrencyLabel(record.currency) + record.price.toLocaleString()
            : ""}
        </div>
      ),
    },
  ]

  const rechargeColumns: ColumnsType<CreditRechargeResult> = [
    {
      title: t("myCredit.table.date.label"),
      dataIndex: "time",
      key: "time",
      width: "18%",
      render: (text, record) => <div>{getDateLabel(record.time, "TIL_DAY")}</div>,
    },
    {
      title: t("myCredit.table.type.label"),
      dataIndex: "type",
      key: "type",
      width: "12%",
      render: (text, record) => {
        const label = getCreditRechargeTypeLabel(record.type)
        if (record.type === "PAID") return <Chip color={"green"} label={label} />
        else if (record.type === "FREE") return <Chip color={"yellow"} label={label} />
        else return <></>
      },
    },
    {
      title: t("myCredit.table.credits.label"),
      dataIndex: "originCredit",
      key: "originCredit",
      width: "20%",
      render: (text, record) => (
        <div className={cn(styles.credit, record.type === "PAID" && record.status === "REFUNDED" && styles.cancel)}>
          {record.originCredit.toLocaleString()}
        </div>
      ),
    },
    {
      title: t("myCredit.table.price.label"),
      dataIndex: "price",
      key: "price",
      width: "20%",
      render: (text, record) => (
        <div className={cn(styles.primary, record.type === "PAID" && record.status === "REFUNDED" && styles.cancel)}>
          {record.type === "PAID" && record.price !== 0
            ? getCurrencyLabel(record.currency) + record.price.toLocaleString()
            : ""}
        </div>
      ),
    },
    {
      title: t("myCredit.table.expirationDate.label"),
      dataIndex: "expiresAt",
      key: "expiresAt",
      width: "15%",
      render: (text, record) => <div>{getDateLabel(record.expiresAt, "TIL_DAY")}</div>,
    },
    {
      title: t("myCredit.table.refund.label"),
      dataIndex: "remainCredit",
      key: "remainCredit",
      width: "15%",
      render: (text, record) => {
        if (record.type === "PAID") {
          if (record.status === "REFUNDED") {
            return <div className={styles.semanticDanger}>{t("myCredit.table.refunded.label")}</div>
          } else if (record.originCredit !== record.remainCredit || !record.refundable) {
            // @NOTE: 부분 사용 또는 충전 180일 경과 (환불 불가)
            return (
              <Tooltip title={t("tooltip.myCredit.table.refund")} placement={"bottom"} point={true}>
                <Button size={"xs"} type={"outlined"} disabled={true}>
                  {t("myCredit.table.refund.label")}
                </Button>
              </Tooltip>
            )
          } else {
            // @NOTE: 환불 가능
            return (
              <Button
                size={"xs"}
                type={"outlined"}
                loading={record.id === selectedRechargeResult.id && isRefundDataRequesting}
                onClick={(e) => handleTableRefundButtonClick(record, e)}
              >
                {t("myCredit.table.refund.label")}
              </Button>
            )
          }
        } else {
          return <></>
        }
      },
    },
  ]

  const usageColumns: ColumnsType<CreditUsageResult> = [
    {
      title: t("myCredit.table.date.label"),
      dataIndex: "time",
      key: "time",
      width: "14%",
      render: (text, record) => <div>{getDateLabel(record.time, "TIL_DAY")}</div>,
    },
    {
      title: t("myCredit.table.project.label"),
      dataIndex: "projectName",
      key: "projectName",
      width: "20%",
      ellipsis: { showTitle: true },
    },
    {
      title: t("myCredit.table.aiPack.label"),
      dataIndex: "aiPackDisplayName",
      key: "aiPackDisplayName",
      width: "15%",
    },
    {
      title: t("myCredit.table.job.label"),
      dataIndex: "jobName",
      key: "jobName",
      width: "20%",
      ellipsis: { showTitle: true },
    },
    {
      title: t("myCredit.table.area.label"),
      dataIndex: "aoiSize",
      key: "aoiSize",
      width: "11%",
      render: (text, record) => (
        <div>{record.aoiSize > 0 ? record.aoiSize.toLocaleString(undefined, { maximumFractionDigits: 2 }) + "km²" : ""}</div>
      ),
    },
    {
      title: t("myCredit.table.credits.label"),
      dataIndex: "creditAmount",
      key: "creditAmount",
      width: "10%",
      render: (text, record) => (
        <div className={cn(styles.credit, styles.primary, record.status === "ERROR" && styles.cancel)}>
          {record.creditAmount.toLocaleString().replace("-", "")}
        </div>
      ),
    },
    {
      title: t("myCredit.table.status.label"),
      dataIndex: "status",
      key: "status",
      width: "10%",
      render: (text, record) => {
        if (record.status === "COMPLETED") return <div>{t("myCredit.table.success.label")}</div>
        else if (record.status === "ERROR")
          return <div className={styles.semanticDanger}>{t("myCredit.table.failed.label")}</div>
        else return <></>
      },
    },
  ]

  const handleTableRefundButtonClick = (result: CreditRechargeResult, e: React.MouseEvent<HTMLElement>) => {
    if (isInitialCreditRechargeResult(result)) {
      Toast({ message: t("toast.refund.error"), type: "error" })
      return
    }
    setSelectedRechargeResult(result)
    setIsRefundDataRequesting(true)

    const getOrdersRefundAsync = async () => {
      try {
        const _refund = await getOrdersRefund(result.key)
        setRefund(_refund)
        setIsRefundModalVisible(true)
      } catch (e) {
        Toast({ message: t("toast.refund.error"), type: "error" })
        captureException(e)
      } finally {
        setIsRefundDataRequesting(false)
      }
    }
    void getOrdersRefundAsync()
  }
  const handleRefundModalButtonClick = () => {
    setIsRefundModalVisible(false)
    setIsRefundConfirmModalVisible(true)
  }
  const handleRefundConfirmModalButtonClick = () => {
    if (isInitialCreditRechargeResult(selectedRechargeResult)) {
      Toast({ message: t("toast.refund.error"), type: "error" })
      return
    }

    setIsRefundRequesting(true)
    const postOrdersRefundAsync = async () => {
      try {
        await postOrdersRefund(selectedRechargeResult.key, { reason: t("modal.refund.reason.label") ?? "" })
        void getMyCreditAllAsync()
        void getMyCreditRechargeAsync()
        props.onRefunded?.()
        Toast({ message: t("toast.refund.success"), type: "success" })
      } catch (e) {
        Toast({ message: t("toast.refund.error"), type: "error" })
        captureException(e)
      } finally {
        setIsRefundRequesting(false)
        setIsRefundConfirmModalVisible(false)
      }
    }
    void postOrdersRefundAsync()
  }

  const renderEmpty = () => {
    return t("myCredit.tab.empty.label")
  }
  const itemRender: PaginationProps["itemRender"] = (_, type, originalElement) => {
    if (type === "prev") return <NavigateBeforeOutlined />
    if (type === "next") return <NavigateNextOutlined />
    return originalElement
  }
  const handleAllPageChange: PaginationProps["onChange"] = (page) => {
    const _page = page > 0 ? page - 1 : 0
    setAllPage(allPageToken.at(_page) ?? "")
  }
  const handleRechargePageChange: PaginationProps["onChange"] = (page) => {
    const _page = page > 0 ? page - 1 : 0
    setRechargePage(rechargePageToken.at(_page) ?? "")
  }
  const handleUsagePageChange: PaginationProps["onChange"] = (page) => {
    const _page = page > 0 ? page - 1 : 0
    setUsagePage(usagePageToken.at(_page) ?? "")
  }

  return (
    <>
      <AntdConfigProvider renderEmpty={renderEmpty}>
        {/* @NOTE antd Tabs <v4.23.0 */}
        <Tabs className={styles.tab} defaultActiveKey={props.defaultActiveKey}>
          <Tabs.TabPane key={"ALL"} tab={t("myCredit.tab.all.title")}>
            <AntdTable
              pagination={{
                size: "small",
                position: ["bottomCenter"],
                pageSize: pageSize,
                showSizeChanger: false, // @TODO pageSize 우선순위 하
                total: allPageToken.length * pageSize,
                itemRender: itemRender,
                onChange: handleAllPageChange,
              }}
              columns={allColumns}
              dataSource={allDataSource}
              rowKey={(record) => record.id}
              scroll={{ y: scrollY }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key={"RECHARGE"} tab={t("myCredit.tab.recharge.title")}>
            <AntdTable
              pagination={{
                size: "small",
                position: ["bottomCenter"],
                pageSize: pageSize,
                showSizeChanger: false, // @TODO pageSize 우선순위 하
                total: rechargePageToken.length * pageSize,
                itemRender: itemRender,
                onChange: handleRechargePageChange,
              }}
              columns={rechargeColumns}
              dataSource={rechargeDataSource}
              rowKey={(record) => record.id}
              scroll={{ y: scrollY }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key={"USAGE"} tab={t("myCredit.tab.usage.title")}>
            <AntdTable
              pagination={{
                size: "small",
                position: ["bottomCenter"],
                pageSize: pageSize,
                showSizeChanger: false, // @TODO pageSize 우선순위 하
                total: usagePageToken.length * pageSize,
                itemRender: itemRender,
                onChange: handleUsagePageChange,
              }}
              columns={usageColumns}
              dataSource={usageDataSource}
              rowKey={(record) => record.id}
              scroll={{ y: scrollY }}
            />
          </Tabs.TabPane>
        </Tabs>
      </AntdConfigProvider>

      {isRefundModalVisible && (
        <RefundModal
          refund={refund}
          closeModal={() => setIsRefundModalVisible(false)}
          onRefundButtonClick={handleRefundModalButtonClick}
        />
      )}
      {isRefundConfirmModalVisible && (
        <ConfirmModal
          title={t("modal.confirm.refund.title")}
          confirmText={t("button.refund")}
          isConfirmButtonLoading={isRefundRequesting}
          isPrimaryConfirm={true}
          onCloseButtonClick={() => setIsRefundConfirmModalVisible(false)}
          onConfirmButtonClick={handleRefundConfirmModalButtonClick}
        />
      )}
    </>
  )
}
