import { getMyAccount, initMyAccount, MyAccount } from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import { TextButton } from "@SIAnalytics/ovision-design-system"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./CloudStorage.module.scss"
import { Cloud } from "./Connections"
import CreationInquiryModal from "./CreationInquiryModal"

type Props = {
  cloud: Cloud
  onCloudStorageButtonClick: (cloud: Cloud) => void
}

function CloudStorage(props: Props) {
  const { name: cloudName, type, description, edge, src, connectionText } = props.cloud
  const [user, setUser] = useState<MyAccount>(initMyAccount)
  const [isCreationInquiryModalVisible, setIsCreationInquiryModalVisible] = useState<boolean>(false)
  const { t } = useTranslation()

  useEffect(() => {
    const setUserAsync = async () => {
      try {
        const user = await getMyAccount()
        setUser(user)
      } catch (e) {
        captureException(e)
      }
    }
    void setUserAsync()
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.storageContainer}>
        <div className={styles.imageContainer}>
          <img alt={cloudName} src={src} />
        </div>
        <span className={styles.storageName}>{cloudName}</span>
        <span className={styles.storageDescription}>{description}</span>
        <TextButton
          className={styles.storageConnection}
          disabled={type === "SIA_CLOUD" || type === "AWS" || type === "AZURE"}
          onClick={() => {
            if (type === "SIA_CLOUD") setIsCreationInquiryModalVisible(true)
            else props.onCloudStorageButtonClick(props.cloud)
          }}
        >
          {connectionText}
        </TextButton>
        {edge && (
          <div className={styles.edge}>
            <span>{t("pro.label")}</span>
          </div>
        )}
      </div>
      {/* 커넥션 팝오버 */}
      {/* {isConnectionConfirmPopoverOpened && (
        <>
          <div className={styles.connectionConfirmPopoverContainer}>
            <div className={styles.header}>
              <div className={styles.errorIconContainer}>
                <ErrorOutlined className={styles.errorIcon} />
              </div>
              <div className={styles.textContainer}>
                <span>{t("popover.newConnections.desc")}</span>
              </div>
            </div>
            <div className={styles.buttonGroup}>
              <Button type={"outlined"} size={"xs"} onClick={() => setIsConnectionConfirmPopoverOpened(false)}>
                {t("no")}
              </Button>
              <Button size={"xs"} onClick={() => props.onConnectionConfirmClick(props.name)}>
                {t("yes")}
              </Button>
            </div>
          </div>
        </>
      )} */}
      {isCreationInquiryModalVisible && (
        <CreationInquiryModal user={user} closeModal={() => setIsCreationInquiryModalVisible(false)} />
      )}
    </div>
  )
}
export default CloudStorage
