import {
  postSignOut,
  PATH_HOME,
  ProfileMenu,
  useElementVisible,
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  MyAccount,
} from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import { Toast } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import styles from "./ProfilePopover.module.scss"

type Props = {
  className?: string
  popoverClassName?: string
  isVisible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  user: MyAccount
  children?: React.ReactNode
}

function ProfilePopover(props: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const hidePopover = () => props.setIsVisible(false)
  const popoverRef = useElementVisible<HTMLDivElement>(hidePopover)

  const onLogoutBtnClick = () => {
    const logoutAsync = async () => {
      try {
        await postSignOut()
        localStorage.removeItem(ACCESS_TOKEN_KEY)
        localStorage.removeItem(REFRESH_TOKEN_KEY)
        navigate(PATH_HOME, { replace: true })
      } catch (e) {
        Toast({ message: t("toast.signOut.error"), type: "error" })
        captureException(e)
      }
    }
    void logoutAsync()
    hidePopover()
  }

  return (
    <div className={cn(styles.profilePopoverContainer, props.popoverClassName)} ref={popoverRef}>
      {props.children}
      {props.isVisible && (
        <ProfileMenu
          className={cn(styles.profileMenu, props.className)}
          enableNavigate={true}
          user={props.user}
          onLogoutBtnClick={onLogoutBtnClick}
        />
      )}
    </div>
  )
}
export default ProfilePopover
