import { Nullable } from "../../common/common"
import { AiPackType, isAiPackValid } from "./aiPackInfosDto"

export type CreditUsageResultType = "COMPLETED" | "ERROR" | ""
export const isCreditUsageResultTypeValid = (result: string | null): result is CreditUsageResultType => {
  return result === "COMPLETED" || result === "ERROR"
}

export type CreditUsageResult = {
  id: string
  time: Date | ""
  projectName: string
  jobName: string
  aiPackName: string
  aiPackDisplayName: string
  status: CreditUsageResultType
  aoiSize: number
  creditAmount: number
  compensation: boolean | ""
  aiPackType: AiPackType // @NOTE Front handling field
}
export type CreditUsageResults = {
  results: CreditUsageResult[]
  nextPages: string[]
}

type CreditUsageResultToNullable = Omit<CreditUsageResult, "time" | "status" | "compensation" | "aiPackType">
type CreditUsageResultDto = {
  time: string | null
  status: string | null
  compensation: boolean | null
} & {
  [key in keyof CreditUsageResultToNullable]: Nullable<CreditUsageResultToNullable[key]>
}
type CreditUsageResultsToNullable = Omit<CreditUsageResults, "results">
export type CreditUsageResultsDto = {
  results: CreditUsageResultDto[] | null
} & {
  [key in keyof CreditUsageResultsToNullable]: Nullable<CreditUsageResultsToNullable[key]>
}

export const creditUsageResultFromDto = (dto: CreditUsageResultDto): CreditUsageResult => {
  const _status = isCreditUsageResultTypeValid(dto.status) ? dto.status : ""
  const _aiPackName = dto.aiPackName?.replaceAll(" ", "") ?? ""
  const _aiPackType = isAiPackValid(_aiPackName) ? _aiPackName : ""
  return {
    id: dto.id ?? "",
    time: dto.time ? new Date(dto.time) : "",
    projectName: dto.projectName ?? "",
    jobName: dto.jobName ?? "",
    aiPackName: dto.aiPackName ?? "",
    aiPackDisplayName: dto.aiPackDisplayName ?? "",
    status: _status,
    aoiSize: dto.aoiSize ?? 0,
    creditAmount: dto.creditAmount ?? 0,
    compensation: dto.compensation ?? "",
    aiPackType: _aiPackType,
  }
}
export const creditUsageResultsFromDto = (dto: CreditUsageResultsDto): CreditUsageResults => {
  return {
    results: dto.results?.map((result) => creditUsageResultFromDto(result)) ?? [],
    nextPages: dto.nextPages.map((nextPage) => nextPage ?? ""),
  }
}

export const initCreditUsageResult = (): CreditUsageResult => {
  return {
    id: "",
    time: "",
    projectName: "",
    jobName: "",
    aiPackName: "",
    aiPackDisplayName: "",
    status: "",
    aoiSize: 0,
    creditAmount: 0,
    compensation: "",
    aiPackType: "",
  }
}
export const initCreditUsageResults = (): CreditUsageResults => {
  return {
    results: [initCreditUsageResult()],
    nextPages: [],
  }
}
