import { PhotoFrameOutlined } from "@ovision-gis-frontend/shared"
import { Button, CloseOutlined, FolderFilled, IconButton, PlusOutlined, Tooltip } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./FileItem.module.scss"

type ContentType = {
  name: string
  contentType: "Folder" | "Image"
  onDeleteClick?: React.MouseEventHandler<HTMLElement>
}
type FileType = {
  id: string
  contents: ContentType[]
  desc?: string
}
type Props = {
  className?: string
  files: FileType[]
  onAddLocationButtonClick?: React.MouseEventHandler<HTMLElement>
}

function FileItem(props: Props) {
  const { t } = useTranslation()

  return (
    <div className={cn(styles.fileItems, props.className)}>
      <div className={styles.files}>
        {props.files?.map((file, index) => (
          <div className={styles.fileItem} key={file.id + index}>
            <span className={styles.desc}>{file.desc}</span>
            {file.contents.map((content, index) => (
              <div className={styles.content} key={content.name + index}>
                <div className={styles.left}>
                  {content.contentType === "Folder" ? <FolderFilled /> : <PhotoFrameOutlined />}
                  <Tooltip size={"large"} title={content.name} placement={"bottom"} point={true}>
                    <span className={styles.label}>{content.name}</span>
                  </Tooltip>
                </div>
                {content.onDeleteClick && (
                  <IconButton size={"xs"} type={"square"} icon={<CloseOutlined />} onClick={content.onDeleteClick} />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
      {props.onAddLocationButtonClick && (
        <Button
          className={styles.addButton}
          size={"large"}
          type={"secondary"}
          icon={<PlusOutlined />}
          onClick={props.onAddLocationButtonClick}
        >
          {t("button.addLocation")}
        </Button>
      )}
    </div>
  )
}
export default FileItem
