import {
  deleteScenes,
  EmptyStatus,
  FilterOutlined,
  getProjects,
  getScenes,
  initProjects,
  initScene,
  initScenes,
  isError,
  isInitialScene,
  Loading,
  PATH_MY_IMAGES,
  Projects,
  ProjectsQueryType,
  Scene,
  Scenes,
  Search,
  useInterval,
  usePageVisibility,
  ScenesQueryType,
  GsdRange,
  getGsdRange,
  getSatellites,
  ConfirmModal,
} from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import {
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  CloseOutlined,
  Dropdown,
  IconButton,
  Toast,
} from "@SIAnalytics/ovision-design-system"
import { RangePickerValue } from "@SIAnalytics/ovision-design-system/build/src/component/data-entry/picker/range-picker/RangePicker"
import cn from "classnames"
import React, { useState, useMemo, useEffect } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"

import Panel from "../service-layout/Panel"
import PanelPagination from "../service-layout/PanelPagination"
import PanelTitle from "../service-layout/PanelTitle"
import ImageFilter from "./ImageFilter"
import ImageItem from "./ImageItem"
import ImageUpload from "./ImageUpload"
import styles from "./MyImages.module.scss"
import { useMyImagesMapOutletContext } from "./MyImagesMap"

type OrderColumnType = "CREATED_TIME" | "IMAGING_TIME" | "FILE_NAME" | "REGION_NAME" | "GSD" | "SATELLITE_NAME"

type Props = {
  className?: string
  activeImage?: Scene
  isPanel?: boolean
  myImagesPanelVisible?: boolean
  onImageClick?: (scene: Scene) => void
  onCloseButtonClick?: React.MouseEventHandler<HTMLElement>
}

function MyImages({ myImagesPanelVisible = true, ...props }: Props) {
  const { activeImage, setActiveImage } = useMyImagesMapOutletContext()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [images, setImages] = useState<Scenes>(initScenes)
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [orderColumn, setOrderColumn] = useState<OrderColumnType>("CREATED_TIME")
  const [orderDirection, setOrderDirection] = useState<"DESC" | "ASC">("DESC")
  const [selectedImageForDelete, setSelectedImageForDelete] = useState<Scene>(initScene)
  const [linkedProjects, setLinkedProjects] = useState<Projects>(initProjects)
  const [linkedProjectsLabel, setLinkedProjectsLabel] = useState<string>("")
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false)
  const [isLinkedProjectsNoticeModalVisible, setIsLinkedProjectsNoticeModalVisible] = useState<boolean>(false)
  const [keyword, setKeyword] = useState<string>("")
  const [createdPeriod, setCreatedPeriod] = useState<RangePickerValue>([null, null])
  const [imagingPeriod, setImagingPeriod] = useState<RangePickerValue>([null, null])
  const [gsdRange, setGsdRange] = useState<GsdRange>([null, null])
  const [gsdBoundary, setGsdBoundary] = useState<GsdRange>([null, null])
  const [selectedSatellites, setSelectedSatellites] = useState<string[]>([])
  const [satellites, setSatellites] = useState<string[]>([])
  const [isFilterPopoverVisible, setIsFilterPopoverVisible] = useState<boolean>(false)
  const location = useLocation()
  const isPageVisible = usePageVisibility()
  const { t } = useTranslation()

  useInterval(
    () => {
      void setImagesAsync()
    },
    isPageVisible && myImagesPanelVisible ? 5000 : null,
    false,
  )

  useInterval(
    () => {
      void setGsdBoundaryAsync()
      void setSatellitesAsync()
    },
    isPageVisible && isFilterPopoverVisible ? 5000 : null,
    false,
  )

  useEffect(() => {
    const initializeGsdRangeAsync = async () => {
      try {
        const gsdBoundary = await getGsdRange()
        if (gsdBoundary[0] !== null) gsdBoundary[0] = Math.floor(gsdBoundary[0] * 10) / 10
        if (gsdBoundary[1] !== null) gsdBoundary[1] = Math.ceil(gsdBoundary[1] * 10) / 10

        setGsdBoundary(gsdBoundary)
        setGsdRange(gsdBoundary)
      } catch (e) {
        captureException(e)
      }
    }
    void initializeGsdRangeAsync()
    void setSatellitesAsync()
  }, [])

  const setSatellitesAsync = async () => {
    try {
      const satellites = await getSatellites()
      setSatellites(satellites)
    } catch (e) {
      captureException(e)
    }
  }

  useEffect(() => {
    void setImagesAsync()
  }, [pageIndex, orderColumn, orderDirection, createdPeriod, imagingPeriod, selectedSatellites, gsdRange, keyword])

  const setImagesAsync = async () => {
    const query: ScenesQueryType = {
      pageIndex,
      orderColumn,
      orderDirection,
      keyword: keyword,
      createdStartTime: createdPeriod[0]?.toISOString(),
      createdEndTime: createdPeriod[1]?.toISOString(),
      imagingStartTime: imagingPeriod[0]?.toISOString(),
      imagingEndTime: imagingPeriod[1]?.toISOString(),
      satelliteList: selectedSatellites.length !== 0 ? selectedSatellites : undefined,
      minGsd: gsdRange[0] === gsdBoundary[0] ? undefined : gsdRange[0] ?? undefined,
      maxGsd: gsdRange[1] === gsdBoundary[1] ? undefined : gsdRange[1] ?? undefined,
    }

    try {
      const _images = await getScenes(query)
      if (!isError(_images)) setImages(_images)
    } catch (e) {
      captureException(e)
    } finally {
      setIsLoading(false)
    }
  }

  const setGsdBoundaryAsync = async () => {
    try {
      const newGsdBoundary = await getGsdRange()
      if (newGsdBoundary[0] !== null) newGsdBoundary[0] = Math.floor(newGsdBoundary[0] * 10) / 10
      if (newGsdBoundary[1] !== null) newGsdBoundary[1] = Math.ceil(newGsdBoundary[1] * 10) / 10

      if (newGsdBoundary[0] !== gsdBoundary[0] && newGsdBoundary[1] !== gsdBoundary[1]) setGsdBoundary(newGsdBoundary)
    } catch (e) {
      captureException(e)
    }
  }

  const onImageClick = (image: Scene) => {
    if (location.pathname === PATH_MY_IMAGES) setActiveImage(image)
    props.onImageClick?.(image)
  }

  const onCloseButtonClick: React.MouseEventHandler<HTMLElement> = (e) => {
    props.onCloseButtonClick?.(e)
  }

  const getFormattedSortLabel = () => {
    if (orderColumn === "FILE_NAME") return t("sortBy.imageName.label")
    else if (orderColumn === "CREATED_TIME") return t("sortBy.createdDate.label")
    else if (orderColumn === "IMAGING_TIME") return t("sortBy.imagingDate.label")
    else if (orderColumn === "REGION_NAME") return t("sortBy.region.label")
    else if (orderColumn === "GSD") return t("sortBy.resolution.label")
    else if (orderColumn === "SATELLITE_NAME") return t("sortBy.satellite.label")
    else return ""
  }

  const onImageDeleteClick = (scene: Scene) => {
    setSelectedImageForDelete(scene)

    const setImagesProjectsAsync = async () => {
      const data: ProjectsQueryType = { sceneId: scene.id }
      try {
        const _project = await getProjects(data)
        if (!isError(_project)) {
          setLinkedProjects(_project)
          if (_project.totalCount === 0) {
            setIsDeleteModalVisible(true)
          } else {
            setLinkedProjectsLabel(_project.projects.map((project) => "  ㆍ" + project.name).join("\n"))
            setIsLinkedProjectsNoticeModalVisible(true)
          }
        }
      } catch (e) {
        captureException(e)
      }
    }
    void setImagesProjectsAsync()
  }
  const onDeleteConfirmButtonClick: React.MouseEventHandler<HTMLElement> = (e) => {
    setIsDeleteModalVisible(false)
    if (isInitialScene(selectedImageForDelete)) {
      Toast({ message: t("toast.myImages.delete.error"), type: "error" })
      captureException("Selected image is invalid,", (scope) => {
        scope.setContext("selectedImageForDelete", { scene: selectedImageForDelete })
        return scope
      })
      return
    }

    const deleteImagesAsync = async () => {
      try {
        const res = await deleteScenes(selectedImageForDelete.id)
        if (!isError(res) && res === 200) Toast({ message: t("toast.myImages.delete.success"), type: "success" })
      } catch (e) {
        Toast({ message: t("toast.myImages.delete.error"), type: "error" })
        captureException(e)
      } finally {
        setSelectedImageForDelete(initScene)
        void setImagesAsync()
      }
    }
    void deleteImagesAsync()
  }

  const imagesMemo = useMemo(() => images, [images])

  const searchImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true)
    const { value } = e.currentTarget
    setKeyword(value)
  }

  const handleChangeFilter = (
    createdPeriod: RangePickerValue,
    imagingDate: RangePickerValue,
    gsdRange: GsdRange,
    satellites: string[],
  ) => {
    setIsLoading(true)
    setCreatedPeriod(createdPeriod)
    setImagingPeriod(imagingDate)
    setGsdRange(gsdRange)
    setSelectedSatellites(satellites)
  }

  const resetFilter = () => {
    setIsLoading(true)
    setCreatedPeriod([null, null])
    setImagingPeriod([null, null])
    setGsdRange(gsdBoundary)
    setSelectedSatellites([])
  }

  const clearSearch = () => {
    setIsLoading(true)
    setKeyword("")
  }

  const isInitFilter = () => {
    return (
      createdPeriod[0] === null &&
      createdPeriod[1] === null &&
      imagingPeriod[0] === null &&
      imagingPeriod[1] === null &&
      gsdRange[0] === gsdBoundary[0] &&
      gsdRange[1] === gsdBoundary[1] &&
      selectedSatellites.length === 0
    )
  }

  return (
    <div className={cn(styles.myImages, props.className)}>
      <Panel className={styles.panel}>
        <div className={styles.titleContainer}>
          <PanelTitle label={t("myImages.title")} />
          {location.pathname === PATH_MY_IMAGES ? (
            <ImageUpload onUploadStarted={() => void setImagesAsync()} />
          ) : (
            <IconButton
              wrapperClassName={styles.iconBtn}
              size={"small"}
              type={"square"}
              icon={<CloseOutlined />}
              onClick={onCloseButtonClick}
            />
          )}
        </div>
        <Search
          className={styles.search}
          placeholder={`${t("searchBy.label", {
            target: `${t("sortBy.imageName.label")}, ${t("sortBy.region.label")}, ${t("sortBy.satellite.label")}`,
          })}`}
          onChange={searchImages}
          onClearButtonClick={clearSearch}
        />
        <div className={styles.filter}>
          <div className={styles.sort}>
            <span>{t("sortBy.label")}</span>
            <Dropdown
              size={"xs"}
              type={"single"}
              itemList={[
                { text: t("sortBy.createdDate.label"), value: t("sortBy.createdDate.label") },
                { text: t("sortBy.imagingDate.label"), value: t("sortBy.imagingDate.label") },
                { text: t("sortBy.imageName.label"), value: t("sortBy.imageName.label") },
                { text: t("sortBy.region.label"), value: t("sortBy.region.label") },
                { text: t("sortBy.resolution.label"), value: t("sortBy.resolution.label") },
                { text: t("sortBy.satellite.label"), value: t("sortBy.satellite.label") },
              ]}
              value={getFormattedSortLabel()}
              onChange={(value) => {
                if (value === t("sortBy.imageName.label")) setOrderColumn("FILE_NAME")
                else if (value === t("sortBy.createdDate.label")) setOrderColumn("CREATED_TIME")
                else if (value === t("sortBy.imagingDate.label")) setOrderColumn("IMAGING_TIME")
                else if (value === t("sortBy.region.label")) setOrderColumn("REGION_NAME")
                else if (value === t("sortBy.resolution.label")) setOrderColumn("GSD")
                else if (value === t("sortBy.satellite.label")) setOrderColumn("SATELLITE_NAME")
              }}
            />
            <IconButton
              size={"xs"}
              type={"square"}
              icon={orderDirection === "ASC" ? <ArrowUpwardOutlined /> : <ArrowDownwardOutlined />}
              onClick={() => (orderDirection === "DESC" ? setOrderDirection("ASC") : setOrderDirection("DESC"))}
            />
          </div>
          <ImageFilter
            createdPeriod={createdPeriod}
            gsdBoundary={gsdBoundary}
            gsdRange={gsdRange}
            imagingPeriod={imagingPeriod}
            isVisible={isFilterPopoverVisible}
            satellites={satellites}
            selectedSatellites={selectedSatellites}
            setIsVisible={setIsFilterPopoverVisible}
            onFilterChange={handleChangeFilter}
            onReset={resetFilter}
          >
            <IconButton
              wrapperClassName={cn(styles.filterIcon, !isInitFilter() && styles.filtered)}
              size={"xs"}
              type={"square"}
              icon={<FilterOutlined />}
              onClick={() => setIsFilterPopoverVisible((prev) => !prev)}
            />
          </ImageFilter>
        </div>
        <div className={styles.imageList}>
          {imagesMemo.totalCount === 0 ? (
            isLoading ? (
              <div className={styles.loadingContainer}>
                <Loading className={styles.loading} size={"small"} />
              </div>
            ) : isInitFilter() && keyword === "" ? (
              <EmptyStatus title={t("myImages.empty.title") ?? ""} desc={t("myImages.empty.desc") ?? ""} />
            ) : (
              <EmptyStatus title={t("analysis.noResults.title") ?? ""} desc={t("analysis.noResults.desc") ?? ""} />
            )
          ) : (
            imagesMemo.sceneInfoList.map((scene) => (
              <ImageItem
                key={scene.id}
                active={scene.id === (activeImage?.id || props.activeImage?.id)}
                data={scene}
                disabled={props.isPanel && scene.status !== "COMPLETED"}
                onDeleteClick={(scene) => onImageDeleteClick(scene)}
                onImageClick={onImageClick}
              />
            ))
          )}
        </div>
      </Panel>
      <PanelPagination
        currentDataCount={imagesMemo.sceneInfoList.length}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        totalCount={imagesMemo.totalCount}
      />

      {isDeleteModalVisible && (
        <ConfirmModal
          title={t("modal.confirm.myImages.delete.title")}
          confirmText={t("button.delete")}
          desc={t("modal.confirm.myImages.delete.desc") ?? ""}
          onCloseButtonClick={() => setIsDeleteModalVisible(false)}
          onConfirmButtonClick={onDeleteConfirmButtonClick}
        />
      )}
      {isLinkedProjectsNoticeModalVisible && (
        <ConfirmModal
          title={t("modal.confirm.myImages.linkedProjectExists.title")}
          desc={
            <Trans i18nKey={"modal.confirm.myImages.linkedProjectExists.desc"}>
              There are projects used this image. Before you can delete the image, you must delete all used projects.
              <strong>Projects used this image</strong>
              <p>PROJECTS</p>
              {{ projects: linkedProjectsLabel }}
            </Trans>
          }
          confirmText={t("button.confirm")}
          isCancelButtonVisible={false}
          isPrimaryConfirm={true}
          onCloseButtonClick={() => setIsLinkedProjectsNoticeModalVisible(false)}
          onConfirmButtonClick={() => setIsLinkedProjectsNoticeModalVisible(false)}
        />
      )}
    </div>
  )
}
export default MyImages
