import { t } from "i18next"

export type TossError = {
  code: string
}
export const isTossError = (error: unknown): error is TossError => {
  return (
    typeof error === "object" &&
    error !== null &&
    "code" in error &&
    typeof (error as Record<string, unknown>).code === "string"
  )
}

// @NOTE https://docs.tosspayments.com/reference/error-codes#%EA%B2%B0%EC%A0%9C%EC%9C%84%EC%A0%AF-sdk-%EC%97%90%EB%9F%AC
type TossWidgetErrorType =
  | "INVALID_AMOUNT_VALUE"
  | "INVALID_CURRENCY"
  | "INVALID_SELECTOR"
  | "NEED_AGREEMENT_WITH_REQUIRED_TERMS"
  | "NEED_CARD_PAYMENT_DETAIL"
  | "NEED_REFUND_ACCOUNT_DETAIL"
  | "EXCEED_DEPOSIT_AMOUNT_LIMIT"
export const isTossWidgetErrorValid = (error: string): error is TossWidgetErrorType => {
  return (
    error === "INVALID_AMOUNT_VALUE" ||
    error === "INVALID_CURRENCY" ||
    error === "INVALID_SELECTOR" ||
    error === "NEED_AGREEMENT_WITH_REQUIRED_TERMS" ||
    error === "NEED_CARD_PAYMENT_DETAIL" ||
    error === "NEED_REFUND_ACCOUNT_DETAIL" ||
    error === "EXCEED_DEPOSIT_AMOUNT_LIMIT"
  )
}
export const getTossWidgetErrorMsg = (code: TossWidgetErrorType) => {
  switch (code) {
    case "INVALID_AMOUNT_VALUE":
      return t("toast.toss.widget.invalidAmountValue.error")
    case "INVALID_CURRENCY":
      return t("toast.toss.widget.invalidCurrency.error")
    case "INVALID_SELECTOR":
      return t("toast.toss.widget.invalidSelector.error")
    case "NEED_AGREEMENT_WITH_REQUIRED_TERMS":
      return t("toast.toss.widget.needAgreementWithRequiredTerms.error")
    case "NEED_CARD_PAYMENT_DETAIL":
      return t("toast.toss.widget.needCardPaymentDetail.error")
    case "NEED_REFUND_ACCOUNT_DETAIL":
      return t("toast.toss.widget.needRefundAccountDetail.error")
    case "EXCEED_DEPOSIT_AMOUNT_LIMIT":
      return t("toast.toss.widget.exceedDepositAmountLimit.error")
    default:
      return ""
  }
}

const tossSdkFailUrlErrors = <const>["PAY_PROCESS_CANCELED", "PAY_PROCESS_ABORTED", "REJECT_CARD_COMPANY"]
const tossCorePaymentSuccessErrors = <const>[
  "ALREADY_PROCESSED_PAYMENT",
  "PROVIDER_ERROR",
  "EXCEED_MAX_CARD_INSTALLMENT_PLAN",
  "INVALID_REQUEST",
  "NOT_ALLOWED_POINT_USE",
  "INVALID_API_KEY",
  "INVALID_REJECT_CARD",
  "BELOW_MINIMUM_AMOUNT",
  "INVALID_CARD_EXPIRATION",
  "INVALID_STOPPED_CARD",
  "EXCEED_MAX_DAILY_PAYMENT_COUNT",
  "NOT_SUPPORTED_INSTALLMENT_PLAN_CARD_OR_MERCHANT",
  "INVALID_CARD_INSTALLMENT_PLAN",
  "NOT_SUPPORTED_MONTHLY_INSTALLMENT_PLAN",
  "EXCEED_MAX_PAYMENT_AMOUNT",
  "NOT_FOUND_TERMINAL_ID",
  "INVALID_AUTHORIZE_AUTH",
  "INVALID_CARD_LOST_OR_STOLEN",
  "RESTRICTED_TRANSFER_ACCOUNT",
  "INVALID_CARD_NUMBER",
  "INVALID_UNREGISTERED_SUBMALL",
  "NOT_REGISTERED_BUSINESS",
  "EXCEED_MAX_ONE_DAY_WITHDRAW_AMOUNT",
  "EXCEED_MAX_ONE_TIME_WITHDRAW_AMOUNT",
  "CARD_PROCESSING_ERROR",
  "EXCEED_MAX_AMOUNT",
  "INVALID_ACCOUNT_INFO_RE_REGISTER",
  "NOT_AVAILABLE_PAYMENT",
  "UNAPPROVED_ORDER_ID",
  "UNAUTHORIZED_KEY",
  "REJECT_ACCOUNT_PAYMENT",
  "REJECT_CARD_PAYMENT",
  "REJECT_CARD_COMPANY",
  "FORBIDDEN_REQUEST",
  "REJECT_TOSSPAY_INVALID_ACCOUNT",
  "EXCEED_MAX_AUTH_COUNT",
  "EXCEED_MAX_ONE_DAY_AMOUNT",
  "NOT_AVAILABLE_BANK",
  "INVALID_PASSWORD",
  "INCORRECT_BASIC_AUTH_FORMAT",
  "FDS_ERROR",
  "NOT_FOUND_PAYMENT",
  "NOT_FOUND_PAYMENT_SESSION",
  "FAILED_PAYMENT_INTERNAL_SYSTEM_PROCESSING",
  "FAILED_INTERNAL_SYSTEM_PROCESSING",
  "UNKNOWN_PAYMENT_ERROR",
]
const tossCorePaymentFailErrors = <const>[
  "ALREADY_CANCELED_PAYMENT",
  "INVALID_REFUND_ACCOUNT_INFO",
  "EXCEED_CANCEL_AMOUNT_DISCOUNT_AMOUNT",
  "INVALID_REQUEST",
  "INVALID_REFUND_ACCOUNT_NUMBER",
  "INVALID_BANK",
  "NOT_MATCHES_REFUNDABLE_AMOUNT",
  "PROVIDER_ERROR",
  "REFUND_REJECTED",
  "ALREADY_REFUND_PAYMENT",
  "UNAUTHORIZED_KEY",
  "NOT_CANCELABLE_AMOUNT",
  "FORBIDDEN_CONSECUTIVE_REQUEST",
  "FORBIDDEN_REQUEST",
  "NOT_CANCELABLE_PAYMENT",
  "EXCEED_MAX_REFUND_DUE",
  "NOT_ALLOWED_PARTIAL_REFUND_WAITING_DEPOSIT",
  "NOT_ALLOWED_PARTIAL_REFUND",
  "NOT_AVAILABLE_BANK",
  "INCORRECT_BASIC_AUTH_FORMAT",
  "NOT_CANCELABLE_PAYMENT_FOR_DORMANT_USER",
  "NOT_FOUND_PAYMENT",
  "FAILED_INTERNAL_SYSTEM_PROCESSING",
  "FAILED_REFUND_PROCESS",
  "FAILED_METHOD_HANDLING_CANCEL",
  "FAILED_PARTIAL_REFUND",
  "COMMON_ERROR",
  "FAILED_PAYMENT_INTERNAL_SYSTEM_PROCESSING",
]
type TossSdkFailUrlErrorType = typeof tossSdkFailUrlErrors[number]
type TossCorePaymentSuccessErrorType = typeof tossCorePaymentSuccessErrors[number]
type TossCorePaymentFailErrorType = typeof tossCorePaymentFailErrors[number]

const tossPaymentErrors = [...tossSdkFailUrlErrors, ...tossCorePaymentSuccessErrors, ...tossCorePaymentFailErrors]
type TossPaymentsErrorType = typeof tossPaymentErrors[number]
export const isTossPaymentError = (code: string): code is TossPaymentsErrorType => {
  return !!tossPaymentErrors.find((_code) => _code === code)
}

export const getTossRequestPaymentFailErrorMsg = (code: TossPaymentsErrorType) => {
  // TODO: case 추가
  switch (code) {
    case "PAY_PROCESS_CANCELED":
      return t("toss.requestPaymentFail.payProcessCanceled.error")
    case "PAY_PROCESS_ABORTED":
      return t("toss.requestPaymentFail.payProcessAborted.error")
    case "REJECT_CARD_COMPANY":
      return t("toss.requestPaymentFail.rejectCardCompany.error")
    default:
      return ""
  }
}
