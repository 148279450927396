import { SizeType } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React from "react"

import "./Loading.scss"

type Props = {
  className?: string
  size?: Extract<SizeType, "xs" | "small" | "medium">
}

export default function Loading({ className, size = "medium" }: Props) {
  return <div className={cn("loading", `loading-${size}`, className)} />
}
