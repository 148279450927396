import { ServiceType, useElementVisible } from "@ovision-gis-frontend/shared"
import {
  Button,
  CloseOutlined,
  ContentCopyOutlined,
  IconButton,
  InputField,
  Toast,
} from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./ResultExportPopover.module.scss"

type Props = {
  className?: string
  isVisible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  children?: React.ReactNode
  service?: ServiceType
  exportUrl?: string
  downloadUrl?: string
}

function ResultExportPopover(props: Props) {
  const hidePopover = () => props.setIsVisible(false)
  const popoverRef = useElementVisible<HTMLDivElement>(hidePopover)
  const { t } = useTranslation()

  const onCopyButtonClick = () => {
    if (!props.exportUrl) {
      Toast({ message: t("toast.linkCopied.error"), type: "error" })
      return
    }
    window.navigator.clipboard.writeText(props.exportUrl).then(
      () => {
        Toast({ message: t("toast.linkCopied.success"), type: "success" })
      },
      () => {
        Toast({ message: t("toast.linkCopied.error"), type: "error" })
      },
    )
  }

  const onDownloadButtonClick = () => {
    if (props.downloadUrl) {
      window.location.href = props.downloadUrl + "&response-content-disposition=attachment"
      Toast({ message: t("toast.fileDownloaded.success"), type: "success" })
    } else {
      Toast({ message: t("toast.fileDownloaded.error"), type: "error" })
    }
  }

  return (
    <div ref={popoverRef}>
      {props.children}
      {props.isVisible && (
        <div className={cn(styles.resultExportPopover, props.className)}>
          <IconButton
            wrapperClassName={styles.closeBtn}
            size={"xs"}
            type={"square"}
            icon={<CloseOutlined />}
            onClick={hidePopover}
          />
          <div className={styles.content}>
            <div className={styles.titleContainer}>
              <span className={styles.title}>{t("download.title")}</span>
            </div>
            <div className={styles.link}>
              <InputField placeholder={"URL"} readOnly={true} value={props.exportUrl} />
              <IconButton
                wrapperClassName={styles.copyBtn}
                size={"xs"}
                type={"square"}
                disabled={!props.exportUrl}
                icon={<ContentCopyOutlined />}
                onClick={onCopyButtonClick}
              />
            </div>
          </div>
          <div className={styles.footer}>
            <Button
              className={styles.downloadBtn}
              size={"large"}
              disabled={!props.downloadUrl}
              onClick={onDownloadButtonClick}
            >
              {t("button.fileDownload", { file: props.service === "IMAGE_IMPROVEMENT" ? "GeoTIFF" : "GeoJSON" })}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
export default ResultExportPopover
