import { MoreHorizontalOutlined, useElementVisible } from "@ovision-gis-frontend/shared"
import { IconButton } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React, { useState } from "react"

import styles from "./ContextMenuPopover.module.scss"

type Props = {
  containerClassName?: string
  positionClassName?: string
  button?: {
    className?: string
    type?: "square" | "moreSquare"
  }
  className?: string
  isVisible?: boolean
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>
  contents: PopoverContent[]
  children?: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLDivElement>
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>
}

export type PopoverContent = {
  className?: string
  text: string
  danger?: boolean
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLSpanElement>
}

function ContextMenuPopover(props: Props) {
  const [isContextPopoverVisible, setIsContextPopoverVisible] = useState<boolean>(false)
  const hidePopover = () => props.setIsVisible?.(false)
  const popoverRef = useElementVisible<HTMLDivElement>(hidePopover)

  const onContentClick = (
    event: React.MouseEvent<HTMLSpanElement>,
    onClick?: React.MouseEventHandler<HTMLSpanElement>,
    disabled?: boolean,
  ) => {
    if (disabled) return
    onClick?.(event)
    hidePopover()
    setIsContextPopoverVisible(false)
    event.stopPropagation()
  }
  const onMoreButtonClick: React.MouseEventHandler<HTMLElement> = (event) => {
    props.setIsVisible?.(!props.isVisible)
    setIsContextPopoverVisible(true)
    event.stopPropagation()
  }

  return (
    <div
      className={props.containerClassName}
      ref={popoverRef}
      onClick={props.onClick}
      onMouseLeave={() => {
        props.onMouseLeave
        setIsContextPopoverVisible(false)
      }}
    >
      {props.children ? (
        props.children
      ) : (
        <IconButton
          wrapperClassName={props.button?.className}
          size={"small"}
          type={props.button?.type || "moreSquare"}
          icon={<MoreHorizontalOutlined />}
          onClick={onMoreButtonClick}
        />
      )}
      {(props.isVisible || isContextPopoverVisible) && (
        <div className={props.positionClassName}>
          <div className={cn(styles.popover, props.className)}>
            {props.contents.map((content, index) => (
              <li
                className={cn(content.className, content.danger && styles.danger, content.disabled && styles.disabled)}
                key={content.text + index}
                onClick={(e) => onContentClick(e, content.onClick, content.disabled)}
              >
                {content.text}
              </li>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
export default ContextMenuPopover
