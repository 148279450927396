import {
  getDateLabel,
  isCloudTypeValid,
  isError,
  isImageSourceValid,
  isInitialProject,
  isProviderValid,
  PATH_ANALYSIS,
  PATH_BACK,
  ProjectStatusType,
  PutProjectRequestType,
  putProjects,
  getAiPack,
  getProjectTypeLabel,
  getProvider,
  getServiceLabel,
  getValidProjectName,
  InferenceJobInfo,
  initInferenceJobInfo,
} from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import {
  ArrowBackOutlined,
  Button,
  Chip,
  IconButton,
  InfoOutlined,
  InputField,
  Toast,
  Tooltip,
} from "@SIAnalytics/ovision-design-system"
import { InputFieldBasicStateType } from "@SIAnalytics/ovision-design-system/build/src/component/data-entry/text-input/input-field/InputField"
import cn from "classnames"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { getCloudImg, getCloudName } from "../../common/cloudUtil"
import Panel from "../service-layout/Panel"
import PanelTip from "../service-layout/PanelTip"
import PanelTitle from "../service-layout/PanelTitle"
import FileItem from "./FileItem"
import { useProjectOutletContext } from "./ProjectContext"
import styles from "./ProjectInfo.module.scss"
import ProjectInfoMap from "./ProjectInfoMap"
import AnalysisImages from "./result/AnalysisImages"
import SourceItem from "./SourceItem"

function ProjectInfo() {
  const { selectedProject, setSelectedProject, isProjectEditing, setIsProjectEditing, view } = useProjectOutletContext()
  const [isPanelExpanded, setIsPanelExpanded] = useState<boolean>(true)
  const [editingName, setEditingName] = useState<string>(selectedProject.name)
  const [isEditingNameValid, setEditingNameValid] = useState<InputFieldBasicStateType>("default")
  const [selectedProjectJob, setSelectedProjectJob] = useState<InferenceJobInfo>(initInferenceJobInfo)
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    if (isInitialProject(selectedProject)) {
      setIsProjectEditing(false)
      navigate(PATH_ANALYSIS)
    }
  }, [selectedProject, isProjectEditing])

  const isImagesPanelVisible = !isInitialProject(selectedProject) && selectedProject.type === "REALTIME"

  const onSaveClick = async () => {
    if (isInitialProject(selectedProject) || !isProjectEditing) return
    if (!editingName) {
      setEditingNameValid("error")
      return
    }

    const data: PutProjectRequestType = {
      id: selectedProject.id,
      name: editingName,
      status: selectedProject.status,
    }

    try {
      const _res = await putProjects(data)
      if (!isError(_res) && _res === 200) {
        Toast({ message: t("toast.analysis.projectEdited.success"), type: "success" })
        setSelectedProject({ ...selectedProject, name: editingName })
      }
    } catch (e) {
      Toast({ message: t("toast.analysis.projectEdited.error"), type: "error" })
      captureException(e)
    } finally {
      setIsProjectEditing(false)
    }
  }

  const getCloudInfo = (type: string) => {
    return isCloudTypeValid(type) ? { img: getCloudImg(type), name: getCloudName(type).title } : { img: "", name: "" }
  }

  const getSourcePath = () => {
    const fullPath = selectedProject.source.paths
    if (!fullPath || fullPath.length === 0) return { path: "", name: "-" }
    else return { path: fullPath.slice(0, -1).join(" / "), name: fullPath.at(-1) || "-" }
  }

  const renderStatus = (status: ProjectStatusType) => {
    switch (status) {
      case "PROCESSING":
        return <Chip size={"large"} color={"green"} label={t("projectInfo.status.inProgress.label") ?? ""} />
      case "PAUSED":
        return <Chip size={"large"} color={"yellow"} label={t("projectInfo.status.paused.label") ?? ""} />
      case "CLOSED":
        return <Chip className={styles.grey} size={"large"} label={t("projectInfo.status.closed.label") ?? ""} />
      default:
        return <></>
    }
  }

  return (
    <div className={styles.projectInfo}>
      <div className={cn(styles.panelContainer, !isPanelExpanded && styles.closed)}>
        <Panel className={styles.panel}>
          <div className={styles.topContainer}>
            <div className={styles.titleContainer}>
              <IconButton
                wrapperClassName={styles.iconBtn}
                size={"small"}
                type={"square"}
                icon={<ArrowBackOutlined />}
                onClick={() => {
                  navigate(PATH_BACK)
                  setIsProjectEditing(false)
                }}
              />
              {isProjectEditing ? (
                <InputField
                  placeholder={selectedProject.name}
                  state={isEditingNameValid}
                  value={editingName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setEditingNameValid("default")
                    setEditingName(getValidProjectName(e.target.value))
                  }}
                  onClearButtonClick={() => setEditingName("")}
                />
              ) : (
                <Tooltip size={"large"} title={selectedProject.name} placement={"bottom"} point={true}>
                  <PanelTitle className={styles.title} label={selectedProject.name || "-"} />
                </Tooltip>
              )}
            </div>

            <div className={styles.content}>
              <div className={styles.infoContainer}>
                <span className={styles.info}>{t("projectInfo.projectType.label")}</span>
                <span className={styles.value}>{getProjectTypeLabel(selectedProject.type)}</span>
              </div>
              {selectedProject.type === "REALTIME" && (
                <div className={styles.infoContainer}>
                  <span className={styles.info}>{t("projectInfo.status.label")}</span>
                  <span className={styles.value}>{renderStatus(selectedProject.status)}</span>
                </div>
              )}
              <div className={styles.infoContainer}>
                <span className={styles.info}>{t("projectInfo.createdDate.label")}</span>
                <span className={styles.value}>{getDateLabel(selectedProject.createdTime)}</span>
              </div>

              <div className={styles.divider} />
              <div className={styles.infoContainer}>
                <span className={styles.info}>{t("service.title")}</span>
                <span className={styles.value}>{getServiceLabel(selectedProject.aiPack.service) || "-"}</span>
              </div>
              <div className={styles.infoContainer}>
                <span className={styles.info}>{t("aiPack.title")}</span>
                <span className={styles.value}>{getAiPack(selectedProject.aiPack.type).label}</span>
                <section className={styles.infoBox}>
                  <InfoOutlined />
                  <p>{getAiPack(selectedProject.aiPack.type).desc}</p>
                </section>
              </div>

              <div className={styles.divider} />
              <div className={styles.infoContainer}>
                <span className={styles.info}>{t("projectInfo.source.label")}</span>
                {isCloudTypeValid(selectedProject.source.specificType) && selectedProject.type === "REALTIME" && (
                  <SourceItem
                    className={styles.sourceItem}
                    title={getSourcePath().name}
                    description={getCloudName(selectedProject.source.specificType).title}
                    img={getCloudImg(selectedProject.source.specificType)}
                  />
                )}
                {isCloudTypeValid(selectedProject.source.specificType) && selectedProject.type === "SINGLE" && (
                  <FileItem
                    files={[
                      {
                        id: selectedProject.source.id ?? "-",
                        desc: getSourcePath().path,
                        contents: [{ name: getSourcePath().name, contentType: "Image" }],
                      },
                    ]}
                  />
                )}
                {isProviderValid(selectedProject.source.specificType) && (
                  <SourceItem
                    className={styles.sourceItem}
                    title={getProvider(selectedProject.source.specificType).label}
                    description={getProvider(selectedProject.source.specificType).desc}
                    img={getProvider(selectedProject.source.specificType).img}
                  />
                )}
                {isImageSourceValid(selectedProject.source.specificType) && (
                  <FileItem
                    files={[
                      {
                        id: selectedProject.source.id ?? "-",
                        desc: t("analysis.source.myImages.label") ?? "",
                        contents: [{ name: getSourcePath().name, contentType: "Image" }],
                      },
                    ]}
                  />
                )}
              </div>

              {isCloudTypeValid(selectedProject.destination.type) && (
                <>
                  <div className={styles.divider} />
                  <div className={styles.infoContainer}>
                    <span className={styles.info}>{t("projectInfo.exportLocation.label")}</span>
                    <SourceItem
                      className={styles.sourceItem}
                      title={selectedProject.destination.path}
                      description={getCloudInfo(selectedProject.destination.type).name}
                      img={getCloudInfo(selectedProject.destination.type).img}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={styles.bottomContainer}>
            {isProjectEditing && (
              <Button className={styles.saveBtn} size={"xl"} type={"cta"} onClick={onSaveClick}>
                {t("button.save")}
              </Button>
            )}
          </div>
        </Panel>
        <PanelTip isPanelExpanded={isPanelExpanded} onClick={() => setIsPanelExpanded((prev) => !prev)} />
      </div>
      <ProjectInfoMap
        isProjectEditing={isProjectEditing}
        selectedProject={selectedProject}
        selectedProjectJob={selectedProjectJob}
        setIsProjectEditing={setIsProjectEditing}
        setSelectedProject={setSelectedProject}
        setSelectedProjectJob={setSelectedProjectJob}
        view={view}
      />
      {isImagesPanelVisible && (
        <AnalysisImages
          selectedProject={selectedProject}
          selectedProjectJob={selectedProjectJob}
          onAnalysisJobClick={(job) => setSelectedProjectJob(job)}
        />
      )}
    </div>
  )
}

export default ProjectInfo
