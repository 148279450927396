import { InputField, InputFieldBaseProps, SearchOutlined } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React from "react"

import styles from "./Search.module.scss"

export default function Search(props: InputFieldBaseProps) {
  const { className, ...rest } = props

  return (
    <InputField
      className={cn(styles.search, className)}
      prefix={<SearchOutlined className={styles.searchIcon} />}
      {...rest}
    />
  )
}
