import React from "react"

import { IconBase, IconProps } from "../../IconBase"

export function LocationOnOutlined(props: IconProps) {
  return (
    <IconBase viewBox={"0 0 24 24"} {...props}>
      <path d="M11.9998 11.9997C12.504 11.9997 12.9358 11.82 13.2951 11.4607C13.6538 11.102 13.8332 10.6705 13.8332 10.1663C13.8332 9.66217 13.6538 9.23042 13.2951 8.87109C12.9358 8.51237 12.504 8.33301 11.9998 8.33301C11.4957 8.33301 11.0642 8.51237 10.7055 8.87109C10.3462 9.23042 10.1665 9.66217 10.1665 10.1663C10.1665 10.6705 10.3462 11.102 10.7055 11.4607C11.0642 11.82 11.4957 11.9997 11.9998 11.9997ZM11.9998 18.7372C13.8637 17.0261 15.2464 15.4714 16.1478 14.0732C17.0491 12.6756 17.4998 11.4344 17.4998 10.3497C17.4998 8.6844 16.9688 7.3207 15.9067 6.25859C14.8452 5.19709 13.5429 4.66634 11.9998 4.66634C10.4568 4.66634 9.1542 5.19709 8.09209 6.25859C7.03059 7.3207 6.49984 8.6844 6.49984 10.3497C6.49984 11.4344 6.95053 12.6756 7.85192 14.0732C8.75331 15.4714 10.1359 17.0261 11.9998 18.7372ZM11.9998 21.1663C9.54012 19.0733 7.70311 17.129 6.48884 15.3336C5.27395 13.5388 4.6665 11.8775 4.6665 10.3497C4.6665 8.05801 5.40381 6.23231 6.87842 4.87259C8.35242 3.51287 10.0596 2.83301 11.9998 2.83301C13.9401 2.83301 15.6473 3.51287 17.1213 4.87259C18.5959 6.23231 19.3332 8.05801 19.3332 10.3497C19.3332 11.8775 18.726 13.5388 17.5118 15.3336C16.2969 17.129 14.4596 19.0733 11.9998 21.1663Z" />
    </IconBase>
  )
}
