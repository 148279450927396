import { Nullable } from "../../common/common"
import { CurrencyType, isCurrencyValid } from "./orderDto"

export type Refund = {
  orderKey: string
  orderCredit: number
  orderPrice: number
  currency: CurrencyType
  refundable: boolean
  refundPrice: number
}

type RefundToNullable = Omit<Refund, "currency">
export type RefundDto = {
  currency: string | null
} & {
  [key in keyof RefundToNullable]: Nullable<RefundToNullable[key]>
}

export const refundFromDto = (dto: RefundDto): Refund => {
  const _currency = isCurrencyValid(dto.currency) ? dto.currency : ""
  return {
    orderKey: dto.orderKey ?? "",
    orderCredit: dto.orderCredit ?? 0,
    orderPrice: dto.orderPrice ?? 0,
    currency: _currency,
    refundable: dto.refundable ?? false,
    refundPrice: dto.refundPrice ?? 0,
  }
}

export const isInitialRefund = (refund: Refund) => {
  return refund.orderKey === ""
}

export const initRefund = (): Refund => {
  return {
    orderKey: "",
    orderCredit: 0,
    orderPrice: 0,
    currency: "",
    refundable: false,
    refundPrice: 0,
  }
}
