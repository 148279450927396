import {
  AccessTimeFilled,
  decodeThumbnail,
  DeleteFilled,
  getDateLabel,
  getFormattedGsd,
  InferenceJobInfo,
  Loading,
  ReportFilled,
} from "@ovision-gis-frontend/shared"
import { IconButton, Thumbnail, Tooltip } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "../../my-images/ImageItem.module.scss"
import ContextMenuPopover from "../../service-layout/ContextMenuPopover"

type Props = {
  className?: string
  data: InferenceJobInfo
  active?: boolean
  selected?: boolean
  onAnalysisJobClick?: (job: InferenceJobInfo) => void
  onDeleteClick?: (id: string) => void
  onUnselectClick?: React.MouseEventHandler<HTMLElement>
}

// @NOTE: ImageItem 재구성
function AnalysisItem(props: Props) {
  const { t } = useTranslation()

  const getStatusLabel = () => {
    const _status = props.data.status
    if (_status === "IDLE") return t("analysis.jobs.status.waiting.idle.label")
    else if (_status === "SCENE_DOWNLOADING") return t("analysis.jobs.status.waiting.imageDownloading.label")
    else if (_status === "SCENE_DOWNLOADED") return t("analysis.jobs.status.waiting.imageDownloaded.label")
    else if (_status === "SCHEDULE_WAITING") return t("analysis.jobs.status.waiting.scheduleWaiting.label")
    else if (_status === "PROCESSING") return t("analysis.jobs.status.processing.processing.label")
    else if (_status === "POSTPROCESSING") return t("analysis.jobs.status.processing.postprocessing.label")
    else if (_status === "ERROR") return t("analysis.jobs.status.error.error.label")
    else if (_status === "SCENE_DOWNLOAD_ERROR") return t("analysis.jobs.status.error.imageDownloadError.label")
    else return ""
  }
  const renderStatus = () => {
    const _tooltipLabel = getStatusLabel()
    switch (props.data.status) {
      case "IDLE":
      case "SCENE_DOWNLOADING":
      case "SCENE_DOWNLOADED":
      case "SCHEDULE_WAITING":
        return (
          <Tooltip size={"small"} title={_tooltipLabel} placement={"bottomRight"} point={true}>
            <AccessTimeFilled />
          </Tooltip>
        )
      case "PROCESSING":
      case "POSTPROCESSING":
        return (
          <Tooltip size={"small"} title={_tooltipLabel} placement={"bottomRight"} point={true}>
            <Loading size={"xs"} />
          </Tooltip>
        )
      case "ERROR":
      case "SCENE_DOWNLOAD_ERROR":
        return (
          <Tooltip size={"small"} title={_tooltipLabel} placement={"bottomRight"} point={true}>
            <ReportFilled />
          </Tooltip>
        )
      case "COMPLETED":
      default:
        return <></>
    }
  }

  return (
    <div
      className={cn(styles.imageItem, props.active && styles.active, props.className)}
      onClick={() => props.onAnalysisJobClick?.(props.data)}
    >
      <div className={styles.highlight} />
      <Thumbnail
        className={cn(styles.thumbnail, !props.data.thumbnail.at(-1) && styles.empty)}
        alt={"THUMBNAIL"}
        src={decodeThumbnail(props.data.thumbnail.at(-1))}
      />
      <div className={styles.contents}>
        <div className={styles.titleContainer}>
          <Tooltip size={"large"} title={props.data.jobName} placement={"bottom"} point={true}>
            <span className={styles.title}>{props.data.jobName || "-"}</span>
          </Tooltip>
          <span className={styles.statusIcon}>
            {!props.selected ? (
              renderStatus()
            ) : (
              <IconButton size={"xs"} type={"square"} icon={<DeleteFilled />} onClick={props.onUnselectClick} />
            )}
          </span>
        </div>
        <span className={styles.region}>{`${props.data.regionName || "-"}`}</span>

        <span className={styles.satellite}>
          {(props.data.satellite.at(-1) || "-") + " • " + getFormattedGsd(props.data.gsdX.at(-1), props.data.gsdY.at(-1))}
        </span>
        <span className={styles.date}>{getDateLabel(props.data.createdTime, "TIL_SEC") || "-"}</span>
        <ContextMenuPopover
          className={styles.contextMenu}
          containerClassName={styles.contextPopoverContainer}
          positionClassName={styles.positionClassName}
          contents={[
            {
              text: t("popover.contextMenu.delete"),
              danger: true,
              onClick: () => props.onDeleteClick?.(props.data.id),
            },
          ]}
          button={{ className: styles.contextMenuBtn }}
        />
      </div>
    </div>
  )
}

export default AnalysisItem
