import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./ControlCard.module.scss"

type Props = {
  className?: string
  children?: React.ReactNode
}

function ControlCard(props: Props) {
  const { t } = useTranslation()
  return <div className={cn(styles.controlCard, props.className)}>{props.children}</div>
}
export default ControlCard
