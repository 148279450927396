import {
  PATH_END_USER_LICENSE_AGREEMENT,
  PATH_PRIVACY_POLICY,
  PATH_TERMS_AND_CONDITIONS,
} from "@ovision-gis-frontend/shared"
import React from "react"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"

import styles from "./LegalSideNavigationBar.module.scss"

function LegalSideNavigationBar() {
  const { t } = useTranslation()
  const activeClassName = styles.active

  return (
    <nav className={styles.sideBarNavigation}>
      <NavLink className={({ isActive }) => (isActive ? activeClassName : undefined)} to={PATH_TERMS_AND_CONDITIONS}>
        {t("termsAndConditions.label")}
      </NavLink>
      <NavLink className={({ isActive }) => (isActive ? activeClassName : undefined)} to={PATH_PRIVACY_POLICY}>
        {t("privacyPolicy.title")}
      </NavLink>
      <NavLink className={({ isActive }) => (isActive ? activeClassName : undefined)} to={PATH_END_USER_LICENSE_AGREEMENT}>
        {t("eula.abbreviation.title")}
      </NavLink>
    </nav>
  )
}
export default LegalSideNavigationBar
