import { BackwardOutlined, ForwardOutlined } from "@ovision-gis-frontend/shared"
import cn from "classnames"
import React from "react"

import styles from "./PanelTip.module.scss"

type Props = {
  className?: string
  isPanelExpanded: boolean
  onClick: React.MouseEventHandler<HTMLDivElement>
}

function PanelTip(props: Props) {
  return (
    <div className={cn(styles.panelTipContainer, props.className)} onClick={props.onClick}>
      <div className={styles.panelTip}>{props.isPanelExpanded ? <BackwardOutlined /> : <ForwardOutlined />}</div>
    </div>
  )
}
export default PanelTip
