import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./LegalContentsKorean.module.scss"

function TermsAndConditionsKorean() {
  const { t } = useTranslation()

  // TODO: i18nexus key 수정
  return (
    <section className={styles.legal}>
      <span className={styles.label}>{t("legal.title")}</span>
      <span className={styles.headingOne}>{t("serviceTerms")}</span>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("serviceTermsFirst")}</span>
        <span className={styles.desc}>{t("serviceTermsFirstDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("serviceTermsSecond")}</span>
        <span className={styles.desc}>{t("serviceTermsSecondDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("serviceTermsThird")}</span>
        <span className={styles.desc}>{t("serviceTermsThirdDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("serviceTermsFourth")}</span>
        <span className={styles.desc}>{t("serviceTermsFourthDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("serviceTermsFifth")}</span>
        <span className={styles.desc}>{t("serviceTermsFifthDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("serviceTermsSixth")}</span>
        <span className={styles.desc}>{t("serviceTermsSixthDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("serviceTermsSeventh")}</span>
        <span className={styles.desc}>{t("serviceTermsSeventhDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("serviceTermsEighth")}</span>
        <span className={styles.desc}>{t("serviceTermsEighthDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("serviceTermsNinth")}</span>
        <span className={styles.desc}>{t("serviceTermsNinthDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("serviceTermsTenth")}</span>
        <span className={styles.desc}>{t("serviceTermsTenthDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("serviceTermsEleventh")}</span>
        <span className={styles.desc}>{t("serviceTermsEleventhDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("serviceTermsTwelfth")}</span>
        <span className={styles.desc}>{t("serviceTermsTwelfthDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("serviceTermsThirteenth")}</span>
        <span className={styles.desc}>{t("serviceTermsThirteenthDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("serviceTermsFourteenth")}</span>
        <span className={styles.desc}>{t("serviceTermsFourteenthDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("serviceTermsFifteenth")}</span>
        <span className={styles.desc}>{t("serviceTermsFifteenthDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("serviceTermsSixteenth")}</span>
        <span className={styles.desc}>{t("serviceTermsSixteenthDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("serviceTermsSeventeenth")}</span>
        <span className={styles.desc}>{t("serviceTermsSeventeenthDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("serviceTermsEighteenth")}</span>
        <span className={styles.desc}>{t("serviceTermsEighteenthDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("serviceTermsNineteenth")}</span>
        <span className={styles.desc}>{t("serviceTermsNineteenthDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("serviceTermsTwentieth")}</span>
        <span className={styles.desc}>{t("serviceTermsTwentiethDesc")}</span>
      </div>
      <div className={styles.content}>
        <span className={styles.headingTwo}>{t("serviceTermsAdditional")}</span>
        <span className={styles.desc}>{t("serviceTermsAdditionalDesc")}</span>
      </div>
    </section>
  )
}
export default TermsAndConditionsKorean
