import React, { useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"

import styles from "./Legal.module.scss"
import LegalSideNavigationBar from "./LegalSideNavigationBar"

function Legal() {
  const location = useLocation()

  useEffect(() => {
    document.getElementById("legal")?.scrollIntoView({ behavior: "auto" })
  }, [location])

  return (
    <div className={styles.protectedLayout} id={"legal"}>
      <LegalSideNavigationBar />
      <Outlet />
    </div>
  )
}
export default Legal
