import { Table } from "antd"
import type { ColumnsType } from "antd/es/table"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./AiPackTable.module.scss"

type Props = {
  className?: string
}

type DataType = {
  aiPack1: string
  aiPack2: string
  tariff: string
  credit: string
}

function AiPackTable(props: Props) {
  const { t } = useTranslation()

  const onAiPackMainCell = (_: DataType, index?: number) => {
    if (index === 0) return { rowSpan: 3 }
    if (index === 3) return { rowSpan: 3 }
    if (index === 6) return { rowSpan: 2 }
    if (index === 8) return { rowSpan: 2 }
    else return { rowSpan: 0 }
  }
  const onTariffsCell = (_: DataType, index?: number) => {
    if (index === 0) return { rowSpan: 1 }
    if (index === 1) return { rowSpan: 1 }
    if (index === 2) return { rowSpan: 1 }
    if (index === 3) return { rowSpan: 3 }
    if (index === 6) return { rowSpan: 2 }
    if (index === 8) return { rowSpan: 2 }
    else return { rowSpan: 0 }
  }
  const onCreditsCell = (_: DataType, index?: number) => {
    if (index === 0) return { rowSpan: 1 }
    if (index === 1) return { rowSpan: 1 }
    if (index === 2) return { rowSpan: 1 }
    if (index === 3) return { rowSpan: 3 }
    if (index === 6) return { rowSpan: 2 }
    if (index === 8) return { rowSpan: 2 }
    else return { rowSpan: 0 }
  }

  const columns: ColumnsType<DataType> = [
    {
      title: t("aiPack.title"),
      colSpan: 2,
      dataIndex: "aiPack1",
      width: "25%",
      align: "left",
      onCell: onAiPackMainCell,
    },
    {
      title: "AI Packs sub",
      colSpan: 0,
      dataIndex: "aiPack2",
      width: "37.5%",
    },
    {
      title: t("pricing.table.tariffs"),
      dataIndex: "tariff",
      width: "18.75%",
      onCell: onTariffsCell,
      className: "tariffs",
    },
    {
      title: t("pricing.table.credits"),
      dataIndex: "credit",
      width: "18.75%",
      onCell: onCreditsCell,
    },
  ]

  const data: DataType[] = [
    {
      aiPack1: t("aiPack.superEnhancement.title"),
      aiPack2: t("aiPack.superEnhancement.mediumHighResolution.title"),
      tariff: t("aiPack.superEnhancement.mediumHighResolution.tariff"),
      credit: t("aiPack.superEnhancement.mediumHighResolution.credit"),
    },
    {
      aiPack1: t("aiPack.superEnhancement.title"),
      aiPack2: t("aiPack.superEnhancement.vhResolution.title"),
      tariff: t("aiPack.superEnhancement.vhResolution.tariff"),
      credit: t("aiPack.superEnhancement.vhResolution.credit"),
    },
    {
      aiPack1: t("aiPack.superEnhancement.title"),
      aiPack2: t("aiPack.superEnhancement.vvhResolution.title"),
      tariff: t("aiPack.superEnhancement.vvhResolution.tariff"),
      credit: t("aiPack.superEnhancement.vvhResolution.credit"),
    },
    {
      aiPack1: t("service.objectDetection.title"),
      aiPack2: t("aiPack.aircraftDetection.title"),
      tariff: t("service.objectDetection.tariff"),
      credit: t("service.objectDetection.credit"),
    },
    {
      aiPack1: t("service.objectDetection.title"),
      aiPack2: t("aiPack.vesselDetection.title"),
      tariff: t("service.objectDetection.tariff"),
      credit: t("service.objectDetection.credit"),
    },
    {
      aiPack1: t("service.objectDetection.title"),
      aiPack2: t("aiPack.vehicleDetection.title"),
      tariff: t("service.objectDetection.tariff"),
      credit: t("service.objectDetection.credit"),
    },
    {
      aiPack1: t("service.infrastructure.title"),
      aiPack2: t("aiPack.buildingSegmentation.title"),
      tariff: t("service.infrastructure.tariff"),
      credit: t("service.infrastructure.credit"),
    },
    {
      aiPack1: t("service.infrastructure.title"),
      aiPack2: t("aiPack.roadSegmentation.title"),
      tariff: t("service.infrastructure.tariff"),
      credit: t("service.infrastructure.credit"),
    },
    {
      aiPack1: t("service.changeDetection.title"),
      aiPack2: t("aiPack.buildingDamageAssessment.title"),
      tariff: t("service.changeDetection.tariff"),
      credit: t("service.changeDetection.credit"),
    },
  ]

  return (
    <Table
      className={cn(styles.aiPackTable, props.className)}
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey={(record) => record.aiPack2}
    />
  )
}
export default AiPackTable
