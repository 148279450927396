import React from "react"

import { IconBase, IconProps } from "../../IconBase"

export function ForwardOutlined(props: IconProps) {
  return (
    <IconBase viewBox={"0 0 24 24"} {...props}>
      <path id="Vector" d="M6.11499 20.23L7.88499 22L17.885 12L7.88499 2L6.11499 3.77L14.345 12L6.11499 20.23Z" />
    </IconBase>
  )
}
