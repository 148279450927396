import React from "react"

import { IconBase, IconProps } from "../../IconBase"

export function MoreHorizontalOutlined(props: IconProps) {
  return (
    <IconBase viewBox={"0 0 16 16"}>
      <path d="M3.99984 6.66681C3.2665 6.66681 2.6665 7.26681 2.6665 8.00014C2.6665 8.73348 3.2665 9.33348 3.99984 9.33348C4.73317 9.33348 5.33317 8.73348 5.33317 8.00014C5.33317 7.26681 4.73317 6.66681 3.99984 6.66681ZM11.9998 6.66681C11.2665 6.66681 10.6665 7.26681 10.6665 8.00014C10.6665 8.73348 11.2665 9.33348 11.9998 9.33348C12.7332 9.33348 13.3332 8.73348 13.3332 8.00014C13.3332 7.26681 12.7332 6.66681 11.9998 6.66681ZM7.99984 6.66681C7.2665 6.66681 6.6665 7.26681 6.6665 8.00014C6.6665 8.73348 7.2665 9.33348 7.99984 9.33348C8.73317 9.33348 9.33317 8.73348 9.33317 8.00014C9.33317 7.26681 8.73317 6.66681 7.99984 6.66681Z" />
    </IconBase>
  )
}
