import { Dropdown } from "@SIAnalytics/ovision-design-system"
import { ItemOption } from "@SIAnalytics/ovision-design-system/build/src/component/data-entry/context-menu/ContextMenu"
import cn from "classnames"
import React from "react"

import styles from "./SourceItem.module.scss"

type DropdownType = {
  className?: string
  itemList: ItemOption[]
  value?: string
  placeholder?: string
  onChange?: (value: string | string[]) => void
}
type Props = {
  className?: string
  rightToolClassName?: string
  img: string
  title: string
  description?: string | null
  rightTool?: React.ReactNode
  dropdown?: DropdownType | boolean
  active?: boolean
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
  onRightToolClick?: React.MouseEventHandler<HTMLDivElement>
}

function SourceItem(props: Props) {
  return (
    <div
      className={cn(styles.sourceItem, props.active && styles.active, props.disabled && styles.disabled, props.className)}
      onClick={props.disabled ? undefined : props.onClick}
    >
      <div className={styles.left}>
        <img className={styles.thumbnail} alt={""} src={props.img} />
        <div className={styles.contents}>
          <span className={styles.title}>{props.title}</span>
          <span className={styles.description}>{props.description}</span>
        </div>
      </div>
      {!props.dropdown && props.rightTool && (
        <div className={cn(styles.right, props.rightToolClassName)} onClick={props.onRightToolClick}>
          {props.rightTool}
        </div>
      )}
      {props.dropdown && typeof props.dropdown !== "boolean" && (
        <Dropdown
          selectClassName={cn(styles.dropdown, props.dropdown.className)}
          size={"xs"}
          type={"single"}
          itemList={props.dropdown.itemList ?? []}
          placeholder={props.dropdown.placeholder}
          value={props.dropdown.value}
          onChange={props.dropdown.onChange}
        />
      )}
    </div>
  )
}

export default SourceItem
