import { NavigateNextOutlined, TextButton } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { PATH_MY_ACCOUNT } from "../../../index"
import { MyAccount } from "../../hooks/data/myAccountDto"
import Avatar from "../avatar/Avatar"
import styles from "./ProfileMenu.module.scss"

type Props = {
  className?: string
  user: MyAccount
  enableNavigate?: boolean
  enableDarkMode?: boolean
  onLogoutBtnClick: React.MouseEventHandler<HTMLElement>
}

function ProfileMenu(props: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className={cn(styles.profileMenu, props.className)}>
      <div
        className={cn(styles.top, props.enableNavigate && styles.hover)}
        onClick={props.enableNavigate ? () => navigate(PATH_MY_ACCOUNT) : undefined}
      >
        <div className={styles.profileContainer}>
          <Avatar
            className={styles.profile}
            alt={"profile"}
            firstName={props.user.account.profile.givenName}
            profileImage={props.user.account.profile.picture}
          />
        </div>
        <div className={styles.content}>
          <span
            className={styles.name}
          >{`${props.user.account.profile.givenName} ${props.user.account.profile.familyName}`}</span>
          <span className={styles.email}>{props.user.account.profile.email}</span>
        </div>
        <NavigateNextOutlined className={cn(styles.nextIcon, props.enableNavigate && styles.visible)} />
      </div>
      <div className={styles.divider} />
      <div className={styles.bottom}>
        {props.enableDarkMode && (
          <TextButton className={styles.item} type={"secondary"} disabled={true}>
            {t("button.darkMode")}
          </TextButton>
        )}
        <TextButton className={styles.item} type={"secondary"} onClick={props.onLogoutBtnClick}>
          {t("button.signOut")}
        </TextButton>
      </div>
    </div>
  )
}
export default ProfileMenu
