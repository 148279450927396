import React from "react"

import { IconBase, IconProps } from "../IconBase"

export function OvisionLight(props: IconProps) {
  return (
    <IconBase height={"28"} viewBox={"0 0 162 28"} width={"162"} {...props}>
      <path
        d={
          "M21.6298 15.2732C22.8046 15.2709 23.957 15.5958 24.9576 16.2115C24.9913 16.087 25.0302 15.9678 25.0639 15.8408C25.7128 13.2705 25.5429 10.5616 24.5777 8.09256C23.6126 5.62349 21.9004 3.51748 19.6804 2.06863C17.4603 0.619792 14.8432 -0.099541 12.1945 0.0110899C9.54578 0.121721 6.99776 1.05679 4.90624 2.68572C2.81473 4.31465 1.28416 6.5561 0.528265 9.09706C-0.227626 11.638 -0.171091 14.3516 0.689975 16.8589C1.55104 19.3661 3.17364 21.5419 5.33119 23.0823C7.48873 24.6227 10.0735 25.4509 12.7245 25.4511C13.9035 25.4517 15.0768 25.2877 16.2104 24.9638C15.618 24 15.2934 22.8955 15.2701 21.7644C15.2468 20.6333 15.5256 19.5165 16.0778 18.529C16.63 17.5416 17.4356 16.7193 18.4116 16.147C19.3875 15.5747 20.4984 15.2731 21.6298 15.2732Z"
        }
        fill={"url(#paint0_linear_4968_417656)"}
      />
      <path
        d={
          "M24.9581 16.2112C24.3808 18.2325 23.3128 20.0798 21.8491 21.5887C20.3854 23.0976 18.5714 24.2213 16.5686 24.8599L16.2109 24.9636C16.7138 25.7835 17.396 26.4788 18.2063 26.9971C19.0166 27.5155 19.9339 27.8433 20.8891 27.9561C21.8444 28.0688 22.8127 27.9635 23.7214 27.6481C24.6301 27.3327 25.4555 26.8154 26.1354 26.135C26.8154 25.4546 27.3323 24.629 27.6471 23.7201C27.962 22.8112 28.0667 21.8428 27.9534 20.8876C27.8401 19.9324 27.5117 19.0153 26.9929 18.2053C26.4741 17.3954 25.7784 16.7136 24.9581 16.2112Z"
        }
        fill={"url(#paint1_linear_4968_417656)"}
      />
      <path
        d={
          "M24.9594 16.2112C23.7406 15.4647 22.3059 15.1504 20.8866 15.3187C19.4673 15.4871 18.1461 16.1284 17.1357 17.1394C16.1254 18.1503 15.4849 19.4719 15.3173 20.8913C15.1498 22.3107 15.465 23.7452 16.2122 24.9636L16.5699 24.8599C18.5727 24.2213 20.3867 23.0976 21.8503 21.5887C23.314 20.0798 24.382 18.2325 24.9594 16.2112Z"
        }
        fill={"url(#paint2_linear_4968_417656)"}
      />
      <path
        d={
          "M24.9594 16.2112C23.7406 15.4647 22.3059 15.1504 20.8866 15.3187C19.4673 15.4871 18.1461 16.1284 17.1357 17.1394C16.1254 18.1503 15.4849 19.4719 15.3173 20.8913C15.1498 22.3107 15.465 23.7452 16.2122 24.9636L16.5699 24.8599C18.5727 24.2213 20.3867 23.0976 21.8503 21.5887C23.314 20.0798 24.382 18.2325 24.9594 16.2112Z"
        }
        fill={"white"}
      />
      <path
        d={
          "M49.9258 13.9297C49.9355 18.5 47.1426 21.1953 43.4414 21.1953C39.7207 21.1953 36.9473 18.4902 36.957 13.9297C36.9473 9.35938 39.7207 6.66406 43.4414 6.66406C47.1426 6.66406 49.9355 9.35938 49.9258 13.9297ZM39.5156 13.9297C39.5059 17.1621 41.1367 18.9102 43.4414 18.9102C45.7559 18.9102 47.377 17.1621 47.3672 13.9297C47.377 10.6973 45.7559 8.94922 43.4414 8.94922C41.1367 8.94922 39.5059 10.6973 39.5156 13.9297ZM53.3047 6.85938L56.9766 18.0117H57.1328L60.8242 6.85938H63.6172L58.6172 21H55.4922L50.5117 6.85938H53.3047ZM67.8359 6.85938V21H65.2969V6.85938H67.8359ZM78.168 10.7461C78.041 9.52539 77.0156 8.82227 75.4922 8.83203C73.8809 8.82227 72.9238 9.60352 72.9141 10.668C72.9043 11.8594 74.1641 12.3477 75.3359 12.6211L76.6641 12.9727C78.7734 13.4609 80.8047 14.5742 80.8047 17.0352C80.8047 19.5156 78.8418 21.2148 75.4531 21.2148C72.1621 21.2148 70.0723 19.6328 69.9648 16.8398H72.4648C72.582 18.3145 73.8223 19.0371 75.4336 19.0469C77.1035 19.0371 78.2559 18.2266 78.2656 17.0156C78.2559 15.9023 77.2402 15.4336 75.707 15.043L74.1055 14.6328C71.7812 14.0273 70.3359 12.8555 70.3359 10.8242C70.3359 8.32422 72.5527 6.66406 75.5312 6.66406C78.5293 6.66406 80.5703 8.35352 80.6094 10.7461H78.168ZM85.4531 6.85938V21H82.9141V6.85938H85.4531ZM100.707 13.9297C100.717 18.5 97.9238 21.1953 94.2227 21.1953C90.502 21.1953 87.7285 18.4902 87.7383 13.9297C87.7285 9.35938 90.502 6.66406 94.2227 6.66406C97.9238 6.66406 100.717 9.35938 100.707 13.9297ZM90.2969 13.9297C90.2871 17.1621 91.918 18.9102 94.2227 18.9102C96.5371 18.9102 98.1582 17.1621 98.1484 13.9297C98.1582 10.6973 96.5371 8.94922 94.2227 8.94922C91.918 8.94922 90.2871 10.6973 90.2969 13.9297ZM114.594 6.85938V21H112.328L105.629 11.332H105.512V21H102.973V6.85938H105.277L111.938 16.5273H112.074V6.85938H114.594Z"
        }
        fill={"#222222"}
      />
      <path
        d={
          "M125.344 20V8.6875H131.969V9.6875H126.453V13.8438H131.625V14.8281H126.453V19H132.078V20H125.344ZM133.719 17.625C133.711 15.8984 135.109 15.25 136.844 15.1094C137.414 15.0781 138.781 14.9844 139.188 14.9688V14.1719C139.195 13.0312 138.461 12.3359 137.125 12.3438C136.016 12.3359 135.266 12.8516 135.062 13.625H133.953C134.133 12.2812 135.422 11.3984 137.188 11.3906C138.453 11.3984 140.25 11.9375 140.25 14.2656V20H139.188V18.6875H139.125C138.781 19.4141 137.938 20.1953 136.516 20.1875C134.969 20.1953 133.711 19.2578 133.719 17.625ZM134.781 17.6719C134.781 18.6719 135.586 19.2344 136.672 19.2344C138.227 19.2344 139.195 18.1172 139.188 16.7656V15.8516C138.766 15.8906 137.422 15.9922 137 16.0156C135.5 16.1172 134.781 16.6953 134.781 17.6719ZM142.547 20V11.5156H143.578V12.8281H143.656C143.992 11.9609 144.906 11.3906 145.953 11.3906C146.125 11.3906 146.367 11.3984 146.5 11.4062V12.4844C146.414 12.4609 146.148 12.4141 145.875 12.4219C144.57 12.4141 143.617 13.3203 143.625 14.5469V20H142.547ZM151.844 11.5156V12.4219H150.062V17.7344C150.055 18.8047 150.656 19.1016 151.297 19.1094L152 19.0781V20.0781C151.828 20.0938 151.562 20.125 151.203 20.125C150.102 20.125 148.984 19.4062 148.984 18V12.4219H147.719V11.5156H148.984V9.48438H150.062V11.5156H151.844ZM155.016 14.7344V20H153.938V8.6875H155.016V12.8594H155.094C155.5 11.9688 156.32 11.4062 157.609 11.4062C159.336 11.4062 160.508 12.4766 160.5 14.5156V20H159.438V14.5938C159.438 13.2109 158.617 12.3672 157.328 12.375C155.984 12.3672 155.008 13.2656 155.016 14.7344Z"
        }
        fill={"#222222"}
      />
      <defs>
        <linearGradient
          id={"paint0_linear_4968_417656"}
          gradientUnits={"userSpaceOnUse"}
          x1={"0.00155101"}
          x2={"25.4474"}
          y1={"12.7281"}
          y2={"12.7281"}
        >
          <stop stopColor={"#1E63DC"} />
          <stop offset={"1"} stopColor={"#5C02CC"} />
        </linearGradient>
        <linearGradient
          id={"paint1_linear_4968_417656"}
          gradientUnits={"userSpaceOnUse"}
          x1={"14.1142"}
          x2={"27.9931"}
          y1={"22.1022"}
          y2={"22.1022"}
        >
          <stop stopColor={"#00C2FF"} />
          <stop offset={"1"} stopColor={"#02D5B8"} />
        </linearGradient>
        <linearGradient
          id={"paint2_linear_4968_417656"}
          gradientUnits={"userSpaceOnUse"}
          x1={"15.2714"}
          x2={"24.9594"}
          y1={"20.1196"}
          y2={"20.1196"}
        >
          <stop stopColor={"#1E63DC"} />
          <stop offset={"1"} stopColor={"#5C02CC"} />
        </linearGradient>
      </defs>
    </IconBase>
  )
}
