import React from "react"

import { IconBase, IconProps } from "../../IconBase"

export function FilterOutlined(props: IconProps) {
  return (
    <IconBase viewBox={"0 0 20 20"} {...props}>
      <path d="M8.33333 15H11.6667V13.3333H8.33333V15ZM2.5 5V6.66667H17.5V5H2.5ZM5 10.8333H15V9.16667H5V10.8333Z" />
    </IconBase>
  )
}
