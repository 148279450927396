import { Avatar as OdsAvatar, AvatarProps } from "@SIAnalytics/ovision-design-system"
import React from "react"

import { koReg } from "../../common/regularExpressions"

type Props = { firstName: string; profileImage: string } & AvatarProps

function Avatar(props: Props) {
  return (
    <OdsAvatar src={props.profileImage} {...props}>
      {getAvatarText(props.firstName)}
    </OdsAvatar>
  )
}

const getAvatarText = (firstName: string) => {
  if (koReg.test(firstName)) return firstName.slice(0, 2)
  else return firstName.charAt(0)
}

export default Avatar
