import { DOMAIN_NAME } from "@ovision-gis-frontend/shared"
import React from "react"
import { Trans, useTranslation } from "react-i18next"

import styles from "./LegalContentsEnglish.module.scss"

function PrivacyPolicyEnglish() {
  const { t } = useTranslation()

  return (
    <section className={styles.legal}>
      <p className={styles.label}>{t("legal.title")}</p>
      <p className={styles.title}>{t("privacyPolicy.title")}</p>

      <dl>
        <dd>
          <Trans i18nKey={"privacyPolicy.desc"}>
            desc...
            <a href={"https://" + DOMAIN_NAME} rel={"noopener noreferrer"} target={"_blank"}>
              domain...
            </a>
            desc...
            {{ domain: DOMAIN_NAME }}
          </Trans>
        </dd>
        <div className={styles.content}>
          <Trans i18nKey={"privacyPolicy.first.content"}>
            <dt>1. The Information SIA Collects</dt>
            <dd>
              <strong>1.1.</strong> desc...
            </dd>
            <dd>
              <strong>1.2.</strong> desc...
            </dd>
            <dd>
              <strong>1.3.</strong> desc...
            </dd>
            <dd>
              <strong>1.4.</strong> desc...
            </dd>
            <dd>
              <strong>1.5.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"privacyPolicy.second.content"}>
            <dt>2. The Way SIA Uses Information</dt>
            <dd>
              <strong>2.1.</strong> desc...
            </dd>
            <dd>
              <strong>2.2.</strong> desc...
            </dd>
            <dd>
              <strong>2.3.</strong> desc...
            </dd>
            <dd>
              <strong>2.4.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"privacyPolicy.third.content"}>
            <dt>3. When SIA Discloses Information</dt>
            <dd>
              <strong>3.1.</strong> desc...
            </dd>
            <dd>
              <strong>3.2.</strong> desc...
            </dd>
            <dd>
              <strong>3.3.</strong> desc...
            </dd>
            <dd>
              <strong>3.4.</strong> desc...
            </dd>
            <dd>
              <strong>3.5.</strong> desc...
            </dd>
            <dd>
              <strong>3.6.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"privacyPolicy.fourth.content"}>
            <dt>4. User Rights</dt>
            <dd>
              <strong>4.1.</strong> desc...
            </dd>
            <dd>
              <strong>4.2.</strong> desc...
            </dd>
            <dd>
              <strong>4.3.</strong> desc...
            </dd>
            <dd>
              <strong>4.4.</strong> desc...
            </dd>
            <dd>
              <strong>4.5.</strong> desc... <span>email</span>
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"privacyPolicy.fifth.content"}>
            <dt>5. Our Commitment to Data Security</dt>
            <dd>
              <strong>5.1.</strong> desc...
            </dd>
            <dd>
              <strong>5.2.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"privacyPolicy.sixth.content"}>
            <dt>6. Protection of personal information of children</dt>
            <dd>
              <strong>6.1.</strong> desc...
            </dd>
            <dd>
              <strong>6.2.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"privacyPolicy.seventh.content"}>
            <dt>7. Special Note to International Users</dt>
            <dd>
              <strong>7.1.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"privacyPolicy.eighth.content"}>
            <dt>8. In the Event of Merger, Sale, or Bankruptcy</dt>
            <dd>
              <strong>8.1.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"privacyPolicy.ninth.content"}>
            <dt>9. Changes and Updates to this Privacy Policy</dt>
            <dd>
              <strong>9.1.</strong> desc...
            </dd>
            <dd>
              <strong>9.2.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"privacyPolicy.tenth.content"}>
            <dt>10. Storage Duration</dt>
            <dd>
              <strong>10.1.</strong> desc...
            </dd>
            <dd>
              <strong>10.2.</strong> desc...
            </dd>
          </Trans>
        </div>
        <div className={styles.content}>
          <Trans i18nKey={"privacyPolicy.eleventh.content"}>
            <dt>11. Hyperlinks</dt>
            <dd>
              <strong>11.1.</strong> desc... <span>email</span>
            </dd>
          </Trans>
        </div>
      </dl>
    </section>
  )
}
export default PrivacyPolicyEnglish
