import { DOMAIN_NAME, Order, PATH_PRICING_PAYMENT_AUTHORIZE, PATH_PRICING_PAYMENT_FAIL } from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import { Button, Toast } from "@SIAnalytics/ovision-design-system"
import { loadPaymentWidget, PaymentWidgetInstance } from "@tosspayments/payment-widget-sdk"
import { PaymentRequestParameters } from "@tosspayments/payment-widget__types/types/types/widget"
import cn from "classnames"
import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"

import styles from "./Payment.module.scss"
import { getTossWidgetErrorMsg, isTossError, isTossWidgetErrorValid } from "./TossUtil"

type PayPalRequestParameters = Pick<
  PaymentRequestParameters,
  "orderId" | "orderName" | "successUrl" | "failUrl" | "customerEmail" | "customerName" | "products" | "paymentMethodOptions"
>

type Props = {
  className?: string
  order: Order
}

const tossPaymentWidget = "toss-payment-widget"
const tossAgreement = "toss-agreement"
const clientKey = process.env.REACT_APP_TOSSPAYMENTS_API_KEY ?? ""

function PaymentWidget(props: Props) {
  const { customerKey, amount, currency, country } = props.order
  const { t } = useTranslation()

  const paymentWidgetRef = useRef<PaymentWidgetInstance | null>(null)
  const paymentMethodsWidgetRef = useRef<ReturnType<PaymentWidgetInstance["renderPaymentMethods"]> | null>(null)

  useEffect(() => {
    ;(async () => {
      // ------  결제위젯 초기화 ------
      const paymentWidget = await loadPaymentWidget(clientKey, customerKey) // 회원 결제

      // ------  결제위젯 렌더링 ------
      // https://docs.tosspayments.com/reference/widget-sdk#renderpaymentmethods선택자-결제-금액-옵션
      const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
        "#" + tossPaymentWidget,
        { value: amount, currency: currency || "USD", country },
        { variantKey: "DEFAULT" },
      )

      // ------  이용약관 렌더링 ------
      // https://docs.tosspayments.com/reference/widget-sdk#renderagreement선택자
      paymentWidget.renderAgreement("#" + tossAgreement, { variantKey: "AGREEMENT" })

      paymentWidgetRef.current = paymentWidget
      paymentMethodsWidgetRef.current = paymentMethodsWidget
    })()
  }, [])
  useEffect(() => {
    const paymentMethodsWidget = paymentMethodsWidgetRef.current
    if (!paymentMethodsWidget) return

    // ------ 금액 업데이트 ------
    // https://docs.tosspayments.com/reference/widget-sdk#updateamount결제-금액
    paymentMethodsWidget.updateAmount(amount)
  }, [amount])

  const requestPayment = () => {
    const paymentWidget = paymentWidgetRef.current
    if (!paymentWidget) {
      Toast({ message: t("toast.toss.widget.error"), type: "error" })
      return
    }

    const requestPaymentAsync = async () => {
      const _order = props.order
      const _requestParameters: PayPalRequestParameters = {
        orderId: _order.orderKey,
        orderName: _order.displayName,
        successUrl: `https://${DOMAIN_NAME}` + PATH_PRICING_PAYMENT_AUTHORIZE,
        failUrl: `https://${DOMAIN_NAME}` + PATH_PRICING_PAYMENT_FAIL,
        customerEmail: _order.customerEmail,
        customerName: _order.customerName,
        products: _order.products,
        paymentMethodOptions: _order.paymentMethodOptions,
      }
      try {
        await paymentWidget.requestPayment(_requestParameters)
      } catch (e) {
        if (isTossError(e) && isTossWidgetErrorValid(e.code)) {
          Toast({ message: getTossWidgetErrorMsg(e.code), type: "error" })
        } else {
          captureException(e)
          Toast({ message: t("toast.toss.widget.error"), type: "error" })
        }
      }
    }
    void requestPaymentAsync()
  }

  return (
    <div className={cn("paymentWidget", props.className)}>
      <div id={tossPaymentWidget} />
      <div id={tossAgreement} />
      <Button className={styles.payBtn} size={"xl"} type={"cta"} onClick={requestPayment}>
        {t("button.pay", { price: amount.toLocaleString() })}
      </Button>
    </div>
  )
}

export default PaymentWidget
