import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import HttpBackend from "i18next-http-backend"
import { initReactI18next } from "react-i18next"

const apiKey = process.env.REACT_APP_LANG_API_KEY
const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`

if (!localStorage.getItem("i18nextLng")) localStorage.setItem("i18nextLng", "en")

i18next
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",

    ns: ["marketing", "service", "aipack", "console", "common"],
    defaultNS: "service",
    fallbackNS: ["marketing", "aipack", "console", "common"],

    supportedLngs: ["en", "ko"],

    backend: {
      loadPath: loadPath,
    },
  })
