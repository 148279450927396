import { useElementVisible } from "@ovision-gis-frontend/shared"
import { TextButton } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./FilterPopover.module.scss"

type Props = {
  className?: string
  isVisible: boolean
  children?: React.ReactNode
  placement?: "left" | "right"
  trigger?: React.ReactNode
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  onReset?: React.MouseEventHandler<HTMLElement>
}

function FilterPopover(props: Props) {
  const hidePopover = () => props.setIsVisible(false)
  const popoverRef = useElementVisible<HTMLDivElement>(hidePopover)
  const { t } = useTranslation()

  const resetFilter = (e: React.MouseEvent<HTMLElement>) => {
    props.onReset?.(e)
  }

  return (
    <div ref={popoverRef}>
      {props.trigger}
      {props.isVisible && (
        <div
          className={cn(
            styles.filterPopover,
            props.placement === "right" ? styles.rightArrow : styles.leftArrow,
            props.className,
          )}
        >
          {props.children}
          <div className={styles.bottom}>
            <TextButton className={styles.resetBtn} size={"small"} type={"secondary"} onClick={resetFilter}>
              {t("button.reset")}
            </TextButton>
          </div>
        </div>
      )}
    </div>
  )
}

export default FilterPopover

export function Divider() {
  return <hr className={styles.divider} />
}
