import { LoaderData, useMember } from "@ovision-gis-frontend/shared"
import React from "react"
import { Outlet, useLoaderData } from "react-router-dom"

import FootNavigationBar from "./FootNavigationBar"
import GlobalNavigationBar from "./GlobalNavigationBar"
import styles from "./Home.module.scss"

export const HomeLoader = async () => {
  return useMember()
}

function Home() {
  const { user } = useLoaderData() as LoaderData<typeof HomeLoader>

  return (
    <main>
      <GlobalNavigationBar user={user} />
      <div className={styles.scrollable}>
        <Outlet />
        <FootNavigationBar />
      </div>
    </main>
  )
}
export default Home
