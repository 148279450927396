import {
  CreditCardOutlined,
  LoaderData,
  PATH_HOME,
  PATH_PRICING,
  useMember,
  useToken,
  initMyAccount,
  MyAccount,
  getMyAccount,
} from "@ovision-gis-frontend/shared"
import { captureException } from "@sentry/react"
import { Button } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Navigate, useLoaderData, useLocation, useNavigate } from "react-router-dom"

import { SettingsMenu } from "../../settings/Settings"
import styles from "./MyCredit.module.scss"
import { MyCreditTable } from "./MyCreditTable"

export const CreditLoader = async () => {
  return useMember()
}

export type MyCreditTab = "ALL" | "RECHARGE" | "USAGE"
export const isMyCreditTabValid = (tab?: string): tab is MyCreditTab => {
  return tab === "ALL" || tab === "RECHARGE" || tab === "USAGE"
}

type Props = {
  className?: string
  isSettingsMenu?: boolean
  setMenu?: React.Dispatch<React.SetStateAction<SettingsMenu>>
  user?: MyAccount
}

function MyCredit(props: Props) {
  const loaderData = useLoaderData() as LoaderData<typeof CreditLoader> | undefined
  const [user, setUser] = useState<MyAccount>(loaderData?.user ? loaderData?.user : props.user ?? initMyAccount())
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const location = useLocation()

  const { token } = useToken()
  if (!token) return <Navigate replace={true} to={PATH_HOME} />

  const searchParams = new URLSearchParams(location.search)
  const tab = searchParams.get("tab")?.toUpperCase()
  const myCreditTab: MyCreditTab = isMyCreditTabValid(tab) ? tab : "ALL"

  const handleRefunded = () => {
    const getMemberAsync = async () => {
      try {
        const user = await getMyAccount()
        setUser(user)
      } catch (e) {
        captureException(e)
      }
    }
    void getMemberAsync()
  }
  const orderCredit = () => {
    navigate(PATH_PRICING)
  }

  return (
    <section className={cn(styles.myCredit, props.className)}>
      <div className={cn(styles.titleContainer, props.isSettingsMenu && styles.isSettingsMenu)}>
        <p className={styles.title}>{t("myCredit.title")}</p>
      </div>
      <div className={styles.horizontal}>
        <div className={styles.userCreditContainer}>
          <span className={styles.userCredits}>{user.creditInfo.remainCredit || 0}</span>
          <span className={styles.label}>{t("credits.label")}</span>
        </div>
        <Button className={styles.creditOrderBtn} icon={<CreditCardOutlined />} onClick={orderCredit}>
          {t("button.creditOrder")}
        </Button>
      </div>

      <MyCreditTable defaultActiveKey={myCreditTab} onRefunded={handleRefunded} />
    </section>
  )
}

export default MyCredit
