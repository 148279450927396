import { ArrowBackOutlined, IconButton } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React from "react"

import styles from "./SettingsHeader.module.scss"

type Props = {
  className?: string
  title: string
  backButton?: boolean
  onBackButtonClick?: React.MouseEventHandler<HTMLElement>
}

function SettingsHeader(props: Props) {
  return (
    <div className={cn(styles.header, props.className)}>
      {props.backButton && (
        <IconButton size={"small"} type={"square"} icon={<ArrowBackOutlined />} onClick={props.onBackButtonClick} />
      )}
      <span>{props.title}</span>
    </div>
  )
}

export default SettingsHeader
