import { PATH_CREDIT, PATH_MY_ACCOUNT } from "@ovision-gis-frontend/shared"
import React from "react"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"

import styles from "./SideNavigationBar.module.scss"

type Props = {}

function SideNavigationBar(props: Props) {
  const { t } = useTranslation()
  const activeClassName = styles.active

  return (
    <nav className={styles.sideBarNavigation}>
      <NavLink className={({ isActive }) => (isActive ? activeClassName : undefined)} to={PATH_MY_ACCOUNT}>
        {t("myAccount.title")}
      </NavLink>
      <NavLink className={({ isActive }) => (isActive ? activeClassName : undefined)} to={PATH_CREDIT}>
        {t("myCredit.title")}
      </NavLink>
    </nav>
  )
}
export default SideNavigationBar
