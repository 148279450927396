import { Chip } from "@SIAnalytics/ovision-design-system"
import cn from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./AiPackTitle.module.scss"

type Props = {
  id?: string
  className?: string
  index: string
  title: string
  desc?: string
  comingSoon?: boolean
}

export default function AiPackTitle(props: Props) {
  const { t } = useTranslation()

  return (
    <div className={cn(styles.aiPackTitle, props.className)} id={props.id}>
      <p className={styles.index}>{props.index}</p>
      <span className={styles.title}>
        {props.title}
        {props.comingSoon && (
          <Chip className={styles.chip} size={"large"} color={"yellow"} label={t("comingSoon") ?? "Coming Soon"} />
        )}
      </span>
      {props.desc && <p className={styles.desc}>{props.desc}</p>}
    </div>
  )
}
