import { Nullable } from "../../common/common"
import { isServiceTypeValid, ServiceType } from "./serviceDto"

export type AiPackType =
  | "AircraftDetection"
  | "VesselDetection"
  | "VehicleDetection"
  | "BuildingSegmentation"
  | "RoadSegmentation"
  | "LandCoverSegmentation"
  | "SuperEnhancement"
  | ""
export const isAiPackValid = (aiPack: string): aiPack is AiPackType => {
  return (
    aiPack.includes("AircraftDetection") ||
    aiPack.includes("VesselDetection") ||
    aiPack.includes("VehicleDetection") ||
    aiPack.includes("BuildingSegmentation") ||
    aiPack.includes("RoadSegmentation") ||
    aiPack.includes("LandCoverSegmentation") ||
    aiPack.includes("SuperEnhancement")
  )
}

export type AiPackInquiryRequestType = {
  countryCode: string
  contactNumber: string
  businessEmail: string
  dataAmount: string
  dataSize: string
  satellite: string
}

export type AiPackInfo = {
  id: string
  name: string
  displayName: string // @NOTE: front handling field
  version: string
  task: string
  type: AiPackType // @NOTE: front handling field
  service: ServiceType
  creditFactor: number
  creditFactorMapByGsd: object
}
export type AiPackInfos = {
  totalCount: number
  aiPackInfoList: AiPackInfo[]
}

type AiPackInfoToNullable = Omit<AiPackInfo, "id" | "displayName" | "type" | "creditFactorMapByGsd">
export type AiPackInfoDTO = Pick<AiPackInfo, "id"> & {
  creditFactorMapByGsd: string | null
} & {
  [key in keyof AiPackInfoToNullable]: Nullable<AiPackInfoToNullable[key]>
}
export type AiPackInfosDTO = Omit<AiPackInfos, "aiPackInfoList"> & {
  aiPackInfoList: AiPackInfoDTO[]
}

export const aiPackInfoFromDto = (dto: AiPackInfoDTO): AiPackInfo => {
  const _displayName = dto.name?.split(" AI Pack")[0] ?? ""
  const _displayNameTrimmed = _displayName.replaceAll(" ", "")
  const _type = isAiPackValid(_displayNameTrimmed) ? _displayNameTrimmed : ""
  const _service = isServiceTypeValid(dto.service) ? dto.service : ""

  return {
    id: dto.id,
    name: dto.name ?? "",
    displayName: _displayName,
    version: dto.version ?? "",
    task: dto.task ?? "",
    type: _type,
    service: _service,
    creditFactor: dto.creditFactor ?? 0,
    creditFactorMapByGsd: dto.creditFactorMapByGsd ? JSON.parse(dto.creditFactorMapByGsd) : {},
  }
}
export const aiPackInfosFromDto = (dto: AiPackInfosDTO): AiPackInfos => {
  const _aiPackInfoList = dto.aiPackInfoList.map((aiPack) => aiPackInfoFromDto(aiPack))

  return {
    totalCount: dto.totalCount,
    aiPackInfoList: _aiPackInfoList,
  }
}

export const isInitialAiPackInfo = (aiPackInfo: AiPackInfo) => {
  return aiPackInfo.id === ""
}

export const initAiPackInfo = (): AiPackInfo => {
  return {
    id: "",
    name: "",
    displayName: "",
    type: "",
    service: "",
    version: "",
    creditFactor: 0,
    task: "",
    creditFactorMapByGsd: {},
  }
}
export const initAiPackInfos = (): AiPackInfos => {
  return { totalCount: 0, aiPackInfoList: [] }
}
