import { CloudType, SyncStorageStatus } from "@ovision-gis-frontend/shared"
import { t } from "i18next"

import AmazonS3Img from "../asset/AmazonS3.png"
import AzureBlobStorageImg from "../asset/AzureBlobStorage.png"
import GoogleCloudStorageImg from "../asset/GoogleCloudStorage.png"
import GoogleCloudStorageDisabledImg from "../asset/GoogleCloudStorageDisabledImg.png"
import SiaCloudImg from "../asset/SIACloud.png"

export const getCloudName = (cloud: CloudType) => {
  let title = ""
  let desc = ""
  if (cloud === "SIA_CLOUD") {
    title = t("siaCloud.title")
    desc = t("siaCloud.desc")
  } else if (cloud === "GCP") {
    title = t("googleCloudStorage.title")
    desc = t("googleCloudStorage.desc")
  } else if (cloud === "AWS") {
    title = t("amazonS3.title")
    desc = t("amazonS3.desc")
  } else if (cloud === "AZURE") {
    title = t("azureBlobStorage.title")
    desc = t("azureBlobStorage.desc")
  }
  return { title, desc }
}
export const getCloudImg = (cloud: CloudType, disabled: boolean = false) => {
  let img = ""
  if (cloud === "SIA_CLOUD") img = SiaCloudImg
  else if (cloud === "GCP") img = disabled ? GoogleCloudStorageDisabledImg : GoogleCloudStorageImg
  else if (cloud === "AWS") img = AmazonS3Img
  else if (cloud === "AZURE") img = AzureBlobStorageImg
  return img
}
export const getCloudStatus = (status: SyncStorageStatus) => {
  if (status === "PENDING") return t("myConnections.status.pending.label")
  else if (status === "SUCCESS") return t("myConnections.status.success.label")
  else if (status === "NOT_FOUND") return t("myConnections.status.notFound.label")
  else if (status === "EXPIRED_CREDENTIALS") return t("myConnections.status.expiredCredentials.label")
  else if (status === "INVALID_CREDENTIALS") return t("myConnections.status.invalidCredentials.label")
  else if (status === "PERMISSION_ERROR") return t("myConnections.status.permissionError.label")
  else if (status === "FAILED") return t("myConnections.status.failed.label")
  else return ""
}
