import cn from "classnames"
import React from "react"

import styles from "./PanelTitle.module.scss"

type Props = {
  className?: string
  label: string
}
function PanelTitle(props: Props) {
  return <span className={cn(styles.title, props.className)}>{props.label}</span>
}
export default PanelTitle
