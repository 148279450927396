import React from "react"

import { IconBase, IconProps } from "../../IconBase"

export function SatelliteOutlined(props: IconProps) {
  return (
    <IconBase viewBox={"0 0 20 20"}>
      <path
        id={"Vector"}
        d={
          "M17.9187 12.0843H19.5854C19.5854 16.2259 16.2271 19.5843 12.0854 19.5843V17.9176C15.3104 17.9176 17.9187 15.3093 17.9187 12.0843ZM12.0854 14.5843V16.2509C14.3854 16.2509 16.2521 14.3843 16.2521 12.0843H14.5854C14.5854 13.4676 13.4687 14.5843 12.0854 14.5843ZM15.6354 0.909277L18.5854 3.85928C19.2354 4.50928 19.2354 5.56761 18.5854 6.21761L15.9354 8.86761C15.2854 9.51761 14.2271 9.51761 13.5771 8.86761L12.5437 7.83428L11.9521 8.41761L12.9854 9.45094C13.6354 10.1009 13.6354 11.1593 12.9854 11.8093L11.8104 12.9843C11.1604 13.6343 10.1021 13.6343 9.45205 12.9843L8.41872 11.9509L7.82705 12.5426L8.86038 13.5759C9.51038 14.2259 9.51038 15.2843 8.86038 15.9343L6.21038 18.5843C5.56038 19.2343 4.50205 19.2343 3.85205 18.5843L0.902051 15.6343C0.252051 14.9843 0.252051 13.9259 0.902051 13.2759L3.55205 10.6259C4.20205 9.97594 5.26038 9.97594 5.91038 10.6259L6.94372 11.6593L7.53538 11.0676L6.50205 10.0426C5.85205 9.39261 5.85205 8.33428 6.50205 7.68428L7.68538 6.50094C8.33538 5.85094 9.39372 5.85094 10.0437 6.50094L11.0771 7.53428L11.6687 6.94261L10.6271 5.91761C9.97705 5.26761 9.97705 4.20928 10.6271 3.55928L13.2771 0.909277C13.9354 0.250944 14.9854 0.250944 15.6354 0.909277ZM2.96872 13.5759L2.08538 14.4593L5.03538 17.4093L5.91872 16.5259L2.96872 13.5759ZM4.73538 11.8093L3.85205 12.6926L6.80205 15.6426L7.68538 14.7593L4.73538 11.8093ZM8.86038 7.68428L7.68538 8.85928L10.6354 11.8093L11.8104 10.6343L8.86038 7.68428ZM12.6937 3.85094L11.8104 4.73428L14.7604 7.68428L15.6437 6.80094L12.6937 3.85094ZM14.4604 2.08428L13.5771 2.96761L16.5271 5.91761L17.4104 5.03428L14.4604 2.08428Z"
        }
      />
    </IconBase>
  )
}
