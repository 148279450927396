export const ACCESS_TOKEN_KEY = "accessToken"
export const REFRESH_TOKEN_KEY = "refreshToken"

export const getToken = (): string => {
  return localStorage[ACCESS_TOKEN_KEY]
}

export function useToken() {
  const token = localStorage[ACCESS_TOKEN_KEY]
  return { token }
}
